import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//Styles
import withStyles from "@material-ui/core/styles/withStyles";
import about from "assets/img/about.svg";

import { withTranslation } from 'react-i18next';

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

class ActiveEmployees_tooltip extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            tooltipOpenID : ''
        }
	}

	handleTooltipClick(_e,tooltipID){
		this.setState({tooltipOpenID:tooltipID});
	}

	handleTooltipClose(_e,tooltipID){
		if (this.state.tooltipOpenID===tooltipID)
			this.setState({tooltipOpenID:""});
	};

	render(){
	const { tooltipid, t } = this.props;
	return (
		<ClickAwayListener onClickAway={(e) => this.handleTooltipClose(e,tooltipid)}>
			<TextOnlyTooltip 
			key={this.state.tooltipOpenID}
            placement="top" 
			disableFocusListener
			disableHoverListener
			disableTouchListener
			title={t('Employees that have started the modules of the sustainability package')}
			open={tooltipid === this.state.tooltipOpenID}
			onClick={(e) => this.handleTooltipClick(e, tooltipid)}
			>
				<span style={{cursor:"pointer",fontWeight:"500"}}>
				<b> {t('Active Employees')}</b>
				<img
				src={about}
				style={{width:"15px",marginRight:"7px"}}
				alt="What is this?"
				/>
				</span>
			</TextOnlyTooltip>
		</ClickAwayListener>
    );
    }
};

export default withTranslation()(ActiveEmployees_tooltip);
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

// Forms
import TextField from "@material-ui/core/TextField";


const styles = (theme) => ({
	'root' : {
		'& .MuiOutlinedInput-notchedOutline' : {
			borderColor : 'grey',
			borderRadius : '20px',
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline' : {
			borderColor : 'grey',
		},
	},
});

class TextArea extends React.Component {

	render = () => {
		const {children, ...other} = this.props;

		return (
			<TextField
				multiline
				rows="4"
				fullWidth
				variant="outlined"
				{...other}
			/>
		);
	};
}

export default withStyles(styles)(TextArea);
import React from "react";

const LifeCycleContext = React.createContext({});
LifeCycleContext.displayName = 'LifeCycleContext';

class LifeCycleProvider extends React.Component {
	state = {
		lifecycles : {}, 
		onActivitiesChange:this.handleActivitiesChange.bind(this),
		getPropertyByLifeCycleID:this.getPropertyByLifeCycleID.bind(this)
	};

	getPropertyByLifeCycleID(id,property){
		if (this.state.lifecycles.hasOwnProperty(id)){
			return this.state.lifecycles[id][property];
		}
		return [];
	}

	handleActivitiesChange(lifecycleID,activitiesArr,impactsArr){
		this.setState(({lifecycles}) => {
			const lc = {
				lifecycles : {
					...lifecycles,
					[lifecycleID] : {
						activities:activitiesArr,
						impacts:impactsArr,
					}
				}
			};

			return lc;
		});
	};
	
	render() {
		const { children } = this.props;
		
		return (
			<LifeCycleContext.Provider value={this.state}>
				{children}
			</LifeCycleContext.Provider>
		);
	}
}

export {LifeCycleProvider, LifeCycleContext};
import React from "react";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";

// Style components
import Grid from "@material-ui/core/Grid";

//form inputs
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@material-ui/core/MenuItem';

//Assets
import ideaIcon from "../../assets/img/Icons-15.png";

//PropTypes
import PropTypes from "prop-types";

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	row:{
		borderBottom:'1px dotted white',
		padding:'10px'
	},
	dropListIcon: {
		height: "60px",
		margin: "10px",
		"@media only screen and (max-width: 960px)": {
				height: "40px",
		  margin: "5px",
		}
	},
};

const Row = withTranslation()(withStyles(styles)(
	({classes, questionIndex, questionLabel, answers, value, onChange, t}) => {
	return (
		<Grid item xs={12} sm={6} md={4}>
			<div>
				<img alt="" src={ideaIcon} className={classes.dropListIcon} />
				<span>{questionLabel}</span>
			</div>
			<DropDown
				value={value}
				color={"white"}
				onChange={(event) => onChange(event, questionIndex)}
			>
				<MenuItem value={-1}>
					<em>None</em>
				</MenuItem>
				{answers.map(
					(text,index) => <MenuItem key={index} value={index}>{text}</MenuItem>
				)}
			</DropDown>
		</Grid>
	);
})); 

class DragAndDropBecomesSelect extends React.Component {
		
	render(){

		const {questions, dropListOne, dropListTwo, onChange,t} = this.props;
		const answers = [dropListOne.title, dropListTwo.title];
		const renderRows = questions.map(({text : questionLabel},index) => {
			const exists = ({text}) => text === questionLabel;
			const value = [dropListOne.answers, dropListTwo.answers].map((elem) => elem.some(exists)).indexOf(true);
		return <Row 
			key={index} 
			questionIndex={index} 
			questionLabel={questionLabel} 
			answers={answers} 
			value={value}
			onChange={onChange}
			t={t}
		/>});
		return <Grid container spacing={2}>{renderRows}</Grid>;
	}
}

DragAndDropBecomesSelect.propTypes = {
	// lists: PropTypes.arrayOf(
	// 	PropTypes.arrayOf(
	// 		PropTypes.shape({
	// 			text: PropTypes.string.isRequired,
	// 			originalIndex: PropTypes.number.isRequired,
	// 			dragListIndex: PropTypes.number.isRequired,
	// 			dropListIndex: PropTypes.number.isRequired
	// 		})
	// 	)
	// ),
	// dragLists: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		title: PropTypes.string,
	// 		containerColor: PropTypes.string.isRequired,
	// 	})
	// ),
	// answers: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
};

export default withTranslation()(withStyles(styles)(DragAndDropBecomesSelect));
import React from "react";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import { standardRadious, ourBlue, grayCape40 } from 'assets/jss/incept-sustainability-variables.jsx';
import clsx from 'clsx';

// Forms
import Radio from '@material-ui/core/Radio';

const styles = {
	root: {},
	radioButton: {
		color: ourBlue,
		'&:hover': {
			backgroundColor: 'transparent',
		},
		width: "100%",
	},
	radioIconRoot: {
		width: '100%',
	},
	cardOption: {
		padding: '10px',
		border: `2px solid none`,
		borderRadius: standardRadious,
		'$radioButton:hover &': {
			boxShadow: 'inset 1px 1px 10px 8px '+grayCape40,
		},
		'$radioButton:active &': {
			boxShadow: 'inset 1px 1px 10px 18px '+grayCape40,
		},
	},
	radioIconImage: {
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		backgroundPosition: "center",
		width: "100%",
		paddingBottom: "100%",
		maxWidth: "150px",
		margin: '0 auto',
	},
	radioIconLabel: {
		color: '#FFFFFF',
		fontSize: '18px',
	},
	checkedRadioIcon: {
		'& $cardOption': {
			backgroundColor: grayCape40,
		}
	}
};

class ImageRadio extends React.Component {

	render() {
		const { classes, imageURL, label, ...rest } = this.props;

		const icon = (checked) => {
			const rootClass = checked ? clsx(classes.radioIconRoot, classes.checkedRadioIcon) : classes.radioIconRoot;
			return (
				<div className={rootClass}>
					<div className={classes.cardOption}>
						<div className={classes.radioIconImage} style={{ backgroundImage: `url('${imageURL}')` }}>
						</div>
						<div className={classes.radioIconLabel}>{label}</div>
					</div>
				</div>
			)
		};

		return (
			<Radio color="default" disableRipple className={classes.radioButton} icon={icon()} checkedIcon={icon(true)} {...rest} />
		);
	}
}

export default withStyles(styles)(ImageRadio);
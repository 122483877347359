import React from "react";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";

// Style components
import Grid from "@material-ui/core/Grid";

//form inputs
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@material-ui/core/MenuItem';

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	questionLabel: {
		height: "45px",
		width: "45px",
		borderRadius: "50%",
		backgroundColor: "#ff6f69",
		textAlign: "center",
		padding: "8px 8px 8px 8px",
		fontWeight: "bold",
		margin: "2px",
		fontSize: "17px",
	},
	gridContainer:{
		width:'100%',
		maxWidth:'500px'
	}
};

const Row = withStyles(styles)(
	({classes, questionIndex, questionLabel, answers, onChange, t}) => {
	return (
	<Grid container spacing={2}>
		<Grid item>
			<div className={classes.questionLabel}>{questionLabel}</div>
		</Grid>
		<Grid item xs>
			<DropDown
				value={answers.findIndex(({pairedWith}) => pairedWith === questionIndex)}
				onChange={(event) => onChange(event, questionIndex,questionLabel)}
				color={"white"}
			>
				<MenuItem value={-1}>
					<em>{t('None')}</em>
				</MenuItem>
				{answers.map(
					({text},index) => <MenuItem key={index} value={index}>{text}</MenuItem>
				)}
			</DropDown>
		</Grid>
	</Grid>
	);
}); 

class Select extends React.Component {
		
		render(){

				const {questions, answers, onChange,t} = this.props;
				const renderQuestions = questions.map((question,index) => <Row key={index} questionIndex={index} questionLabel={question} 
				answers={answers} onChange={onChange} t={t}/>);
				return <React.Fragment>{renderQuestions}</React.Fragment>;
		}
}

export default withTranslation()(withStyles(styles)(Select));
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

//form inputs
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@material-ui/core/MenuItem';

const languageMap = {
	en: { label: "English", dir: "ltr", active: true },
	da: { label: "Danish", dir: "ltr", active: true },
	//es: { label: "Spanish", dir: "ltr", active: false },
	//dk: { label: "Danish", dir: "ltr", active: false },
};

const LanguageSelect = (props) => {
	const selected = localStorage.getItem("i18nextLng") || "en";
	const { t } = useTranslation();
	const { complanf } = props;
	var newLangMap = {};
	for (let key in languageMap) {
		if (complanf.includes(languageMap[key].label))
			newLangMap[key] = languageMap[key];
	}

	const changeLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
	}

	return (
		<DropDown
			color="magenta"
			value={selected}
			onChange={changeLanguage}
		>
			<MenuItem value="">
				<em>{t('Select language')}</em>
			</MenuItem>
			{Object.keys(newLangMap)?.map(item => (
				<MenuItem value={item} key={item}>
					{newLangMap[item].label}
				</MenuItem>       
			))}
		</DropDown>
	);
};

export default LanguageSelect;

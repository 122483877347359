import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// Layouts to be used by Routers
import Admin from "layouts/Admin.jsx";
import GuidesNav from "layouts/GuidesNav.jsx";
import Guides from "layouts/Guides.js";
import Login from "layouts/Login.jsx";
import UploadFile from "layouts/UploadFile.jsx";

//Importing css assets
//import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/css/material-dashboard-react.css?v=1.7.0";

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import BrowserDetection2 from 'components/Feedback/BrowserDetection2.js';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import jwt from "jwt-decode";

//Internationalization - This is needed for translate all components
import 'i18n';
//import ErrorBoundary from 'components/Log/ErrorBoundary.jsx';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import {urllog } from 'assets/jss/incept-sustainability-variables.jsx';

//For logging errors
const customJSON = log => ({
    msg: log.message,
    level: log.level.label,
    stacktrace: log.stacktrace
});
const token = localStorage.getItem("token");
const authstr = "Bearer " + token;
remote.apply(log, 
    { format: customJSON, 
      headers: { Authorization: authstr },
      token: token,
      url: urllog });
log.enableAll();

//Handling errors
window.addEventListener('error', function (event) {
	if (event.error.hasBeenCaught !== undefined){
	  return false
	}
	event.error.hasBeenCaught = true;
	//log.error(error);
	// ... your useful code here
});
window.onerror = function(message, url, lineNumber) {  
	// make ajax call to api to save error on server
	log.error(message);
	return true;
};  

//Remove console logs from production 
/*if (process.env.NODE_ENV !== "development")
    console.log = () => {};*/

const outerTheme = createTheme ({
  typography: {
    fontFamily: [
      'gotham-font-book',
    ].join(','),
  },
  palette: {
    primary: {
      main: "#009df0",
    },
    secondary: {
      main: "#ff8855",
    },
  },
});

const hist = createBrowserHistory();
var isexpired = false;

if (localStorage.getItem("token")) {
	const decodedtoken = jwt(localStorage.getItem("token"));
	try {
		if (Date.now() >= decodedtoken.exp * 1000) {
			//Logout
			localStorage.removeItem('token');
			//localStorage.removeItem('userName');
			isexpired = true;
		}
	} catch (err) {
		console.log("Error");
	}
}

ReactDOM.render(
	<ThemeProvider theme={outerTheme}>
		<Router history={hist}>	  
			<Route>
				<BrowserDetection2>
					<Switch>
							<Route path="/admin" component={Admin} isexpired={isexpired} />
							<Route path="/guidesNav" component={GuidesNav} isexpired={isexpired}  />
							<Route path="/guides" component={Guides} isexpired={isexpired} />
							<Route path="/Login/:invalidkey" component={Login} isexpired={isexpired}  />
							<Route path="/Login" component={Login} isexpired={isexpired}  />
							<Route path="/UploadFile" component={UploadFile} isexpired={isexpired}  />
							<Redirect from="/" to="/Login" />
					</Switch>
				</BrowserDetection2>
			</Route>	  
		</Router>
	</ThemeProvider>,
	document.getElementById("root")
);



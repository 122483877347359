/*!
* Description: this file contains the component for
*  the section "example"
* Components: GuideExampleInteractive
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@material-ui/core/Container';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { ourBlue60,standardRadious,ourBlue } from 'assets/jss/incept-sustainability-variables.jsx';
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Collapse from "@material-ui/core/Collapse";
//Translations
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";
import arrowdown from 'assets/img/down-arrow.png';
import checkmark from 'assets/img/checkmark.png';

const styles = {
	root: {},
	cardExample: {
		borderRadius: "2rem",
		marginBottom: "10px"
	},
	cardBodyText: {
		marginTop: "20px"
	},
	iconNavBack: {
		fontSize: '30px',

	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "5px 20px",
		"@media only screen and (max-width: 960px)": {
			margin: "10px 0"
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
    blockContainer: {
        height: "100%",
		padding: "10px 0 0 0",
        cursor: "pointer"
    },
    gridItem: {
		textAlign: "center",
		paddingRight: '10px',
		paddingBottom: '50px',
	},
    subcontainer: {
        width:'100%',
		borderRadius: standardRadious,
		padding: "0.5rem 1rem",
		height: "100%",
        color: "white",
        textAlign: "center"
    },
	pressedButton: {
		border: '1px solid '+ourBlue,
        backgroundColor: ourBlue,
    },
	nonpressed: {
		border: '1px solid '+ourBlue60,
		backgroundColor: ourBlue60,
		"&:hover ": {
			backgroundColor: ourBlue
		}
	},
    subcontainer0: {
        height: "100%"
    },
    answercontainer: {
		padding: "0"
	},
	exampleArea: {
		marginTop: "30px",
	},
	iconDownArrow: {
		cursor: "pointer",
		width: "50px"
	},
	iconDownArrowDiv: {
		paddingTop: "30px",
		textAlign: "center",
		cursor: "pointer",
	}

    
};

const ContentContainer = withTranslation()(withStyles(styles)(
	({ classes, onClick, content, showboxContent, t }) => (
		<React.Fragment>
			<Container className={classes.answercontainer}>
				<Collapse in={showboxContent} className={(showboxContent) ? classes.exampleArea:""}>{ReactHtmlParser(ReactHtmlParser(content))}</Collapse>
			</Container>
		</React.Fragment>
	)
));

class GuideExampleInteractive extends React.Component {

	constructor(props){
		super(props);
        const { boxes } = props;
		const { options } = boxes;
		this.state = {
			isdefaultView: (this.props.videoContent!="") ? true:false,
			defaultView: (this.props.defaultView==="Video content") ? false:true,
			loading: true,
            contentExample: options,
            contentBox: 0,
			defaultFirst: (this.props.defaultFirst) ? true: false,
			showboxContent: (this.props.defaultFirst) ? true:false
		}
	}

	componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps);
		/*if (this.state.loading) {
			this.setState({isdefaultView:(nextProps.videoContent.length!==0) ? true:false,
				defaultView: (nextProps.defaultView==="Video content") ? false:true, loading: false});
		}*/
    }

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

    handleExampleContent(e,n) {
		e.preventDefault();
		if (!this.state.defaultFirst && (this.state.contentBox===n || !this.state.showboxContent))
			this.setState({ showboxContent: !this.state.showboxContent });
		this.setState({ contentBox: n });
	}

	render() {
		const { classes, title,textContent,videoContent, t,goNextSection,saveresloc,
			isContent,isLastSection,showcard,openSection,datasav,smoothtrans } = this.props;
		let nmbuttons = this.state.contentExample.length;
		let gridnm = 3;

		if ((nmbuttons % 3) === 0) {
			gridnm = 4;
		}
		else if ((nmbuttons % 2) === 0) {
			gridnm = 6;
		}

		const grid_buttons = this.state.contentExample.map((item, index) => (
			<GridItem xs={12} sm={6} md={gridnm} spaceLeft={0} className={classes.gridItem}>
				<div className={classes.blockContainer}>
					<div className={classes.subcontainer0} onClick={(e) => this.handleExampleContent(e,index)}>
						<div className={`${(this.state.contentBox===index && this.state.showboxContent) ? classes.pressedButton:classes.nonpressed} ${classes.subcontainer}`}>
						{ReactHtmlParser(ReactHtmlParser(item.button))}</div>
					</div>
				</div>
			</GridItem>
		));

		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					<Card className={classes.cardExample}>
						<CardBody style={(!showcard) ? {padding: "0 30px",margin: "1rem 0 0.2rem 0"}:{}}>
							<GridContainer justify="center" nonunset={true}>
								<GridItem xs={10} sm={10} md={10} 
								lg={10} spaceLeft={0}>
                                {!showcard &&<GuideCardTitle>{(isContent) ? "":t('Example')+":"} {title}
                            </GuideCardTitle>}
							</GridItem>
							<GridItem xs={2} sm={2} md={2} 
							lg={2} spaceLeft={0} style={{cursor:"pointer"}} onClick={openSection}>
								<div className={`${classes.iconDownArrowDiv}`} style={{paddingTop:"7px",textAlign:"right"}}>
									<img
										src={checkmark}
										style={{width:"30px",marginRight:"20px", marginTop: showcard ? "-27px":"-5px",visibility: (typeof  datasav !== "undefined") ? "visible":"hidden"}}
										alt="Completed section"
										title="Completed section"		
									/>
									<img
										src={arrowdown}
										style={showcard ? {transform:"rotate(-180deg)", marginTop:"-22px"}:{}}
										alt="Go to next section"
										title="Go to next section"									
										className={classes.iconDownArrow}
									/>
								</div>
							</GridItem>
						</GridContainer>

						
							{showcard && 
							<GridContainer justify="center" nonunset={true}>
								<GridItem sm={(this.state.isdefaultView) ? 6:12} md={(this.state.isdefaultView) ? 8:12} 
								lg={(this.state.isdefaultView) ? 8:12} spaceLeft={0}>
									<GuideCardTitle>{(isContent) ? "":t('Example')+":"} {title}</GuideCardTitle>
								</GridItem>
								<GridItem sm={(this.state.isdefaultView) ? 6:0} md={(this.state.isdefaultView) ? 4:0} 
								lg={(this.state.isdefaultView) ? 4:0} spaceLeft={0}>
									{this.state.isdefaultView && <Container className={classes.buttonContainer}>
										<ActionButton 
											onClick={(e) => this.handleSubmit(e)} 
											type="submit" 
											size="auto"
											centered={true}
											nomargin={true}
										>
											{(this.state.defaultView) && t('See video instead')}
											{(!this.state.defaultView) && t('See text instead')}
										</ActionButton>
									</Container>}
								</GridItem>
							</GridContainer>}
							<Collapse in={showcard} addEndListener={() => {
								if (showcard) {
									saveresloc();
									setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
								}
							}} >
							<div className={classes.cardBodyText}>
							{(this.state.defaultView) && <div>
								<div>{textContent}</div>
                            <GridContainer container>
                                {grid_buttons}
								<ContentContainer
                                    content={(this.state.contentExample.length>0) ? this.state.contentExample[this.state.contentBox].content:""}
									showboxContent={this.state.showboxContent}
                                />
							</GridContainer></div>}
							{(!this.state.defaultView) && <div className={classes.cardBodyText}>
								{videoContent}</div>}	
								</div>
						{!isLastSection && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
							<img
								src={arrowdown}
								alt="Go to next section"
								title="Go to next section"
								
								className={classes.iconDownArrow}
							/>
						</div>}
						</Collapse>
						</CardBody>
					</Card>
				</Container>
			</div>
		);
	}
}

GuideExampleInteractive.defaultProps = {
	children: (<p>Here comes the content for this Example</p>),
	title: 'Example',
};


export default withTranslation()(withStyles(styles)(GuideExampleInteractive));
/*!
* Description: this file contains the component for
*  a section that has only content: ex. "Introduction"
* Components: GuideStandardCard
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@material-ui/core/Container';
import withStyles from "@material-ui/core/styles/withStyles";
import Collapse from "@material-ui/core/Collapse";
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
import WrapperFeedback from "components/Feedback/WrapperFeedback.js";
import {  ourGreen } from "assets/jss/incept-sustainability-variables";
import arrowdown from 'assets/img/down-arrow.png';
import checkmark from 'assets/img/checkmark.png';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CarouselSection from "components/Exercise/CarouselSection.js";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

//Translations
import { withTranslation } from 'react-i18next';
import { complement } from "polished";

const styles = {
	root: {},
	cardColor: {
		backgroundColor: ourGreen,
		color: "white",
	},
	cardNoColor: {
		color: "#000000",
	},
	notopPadding: {
		padding: "0px 20px"
	},
	cardExample: {
		borderRadius: "2rem",
		marginBottom: "10px"
	},
	cardHeader: {
		borderBottom: 'none'
	},
	cardBodyText: {
		/*columnCount: 2,
		columnGap: '40px',
		textAlign: 'justify',
		"&:img": {
			width: "80%",
		}*/
		/*marginTop: "20px"*/
	},
	iconNavBack: {
		fontSize: '30px',
	},
	customDivider: {
		marginTop: '20px'
	},
	titlecolor: {
		color: "white"	
	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "3px 20px 20px 0",
		paddingRight: "0",
		"@media only screen and (max-width: 960px)": {
			margin: "3px 20px 20px 0",
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
	iconDownArrow: {
		cursor: "pointer",
		width: "50px"
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
		cursor: "pointer",
	},
	heightFix: {
		height: "80vh",
		"@media only screen and (max-width: 960px)": {
			height: "auto"
		},
	}
};

class GuideStandardCard extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			isdefaultView: (this.props.videoContent!="") ? true:false,
			defaultView: (this.props.defaultView==="Video content") ? false:true,
			loading: true
		}
	}

	componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps);
		if (this.state.loading) {
			this.setState({isdefaultView:(nextProps.videoContent.length!==0) ? true:false,
				defaultView: (nextProps.defaultView==="Video content") ? false:true, loading: false});
		}
    }

	decodeHtml(html) {
		var txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

	render() {
		const { classes, title, t,feedback,saveresloc,
			isexercise, isitrich,textContent,videoContent,goNextSection,isLastSection,showcard,openSection,
			readCard,datasav,smoothtrans,type,images } = this.props;
		const classrich = (isitrich) ? classes.cardNoColor:classes.cardColor;
		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					<Card className={classes.cardExample+" "+classrich+((!this.state.defaultView && showcard) ? classes.heightFix:"")}>
						<CardBody isexercise={isexercise} className={(isitrich) ? "":classes.notopPadding} 
						style={(!showcard) ? {padding: "0 30px"}:{}}> 
						
							<GridContainer justify="center" nonunset={true} style={(isitrich && !showcard) ? {margin: "1rem 0 0.2rem 0"}:{}}>
								<GridItem xs={(isitrich) ? 10:12} sm={(isitrich) ? 10:12} md={10} 
								lg={10} spaceLeft={0}>
									{!showcard && <GuideCardTitle color={(isitrich) ? "":"white"}>{title}</GuideCardTitle>}
								</GridItem>
								{(isitrich) && <GridItem xs={2} sm={2} md={2} 
								lg={2} spaceLeft={0} style={{cursor:"pointer"}}>
									<div className={`${classes.iconDownArrowDiv}`}
									 style={(title=="") ? {marginBottom: "12.8px",paddingTop:"7px",textAlign:"right"}:{paddingTop:"7px",textAlign:"right"}}>										
										<img
											src={checkmark}
											style={{width:"30px",marginRight:"20px", marginTop: showcard ? "-27px":"-5px",visibility: (typeof  datasav !== "undefined") ? "visible":"hidden"}}
											alt="Completed section"
											title="Completed section"		
										/>
										<img
											src={arrowdown}
											style={showcard ? {transform:"rotate(-180deg)", marginTop:"-22px"}:{}}
											alt="Go to next section"
											title="Go to next section"	
											onClick={openSection}								
											className={classes.iconDownArrow}
										/>
									</div>
								</GridItem>}
							</GridContainer>

						{showcard && 
							<GridContainer justify="center" nonunset={true} style={(isitrich && !showcard) ? {margin: "1rem 0 0.2rem 0"}:{}}>
								<GridItem sm={(this.state.isdefaultView) ? 6:12} md={(this.state.isdefaultView) ? 8:12} 
								lg={(this.state.isdefaultView) ? 8:12} spaceLeft={0}>
									<GuideCardTitle color={(isitrich) ? "":"white"}>{title}</GuideCardTitle>
								</GridItem>
								<GridItem sm={(this.state.isdefaultView) ? 6:0} md={(this.state.isdefaultView) ? 4:0} 
								lg={(this.state.isdefaultView) ? 4:0} spaceLeft={0}>
									{showcard && this.state.isdefaultView && <Container className={classes.buttonContainer}>
										<ActionButton 
											onClick={(e) => this.handleSubmit(e)} 
											type="submit" 
											size="auto"
											centered={true}
											nomargin={true}
										>
											{(this.state.defaultView) && t('See video instead')}
											{(!this.state.defaultView) && t('See text instead')}
										</ActionButton>
									</Container>}
								</GridItem>
							</GridContainer>}
							<Collapse in={(isitrich) ? showcard:true}
							addEndListener={() => {
							// use the css transitionend event to mark the finish of a transition
								if (showcard) {
									saveresloc();
									setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
								}
							}} >
							{(this.state.defaultView) && <div className={classes.cardBodyText+" word-break"}>
								{textContent}</div>}
							{(!this.state.defaultView) && <div className={classes.cardBodyText+" word-break"}>
								{videoContent}</div>}		
							{(this.state.defaultView && type==="content_carousel") && 
								<CarouselSection
								imagesProps={images}
								/>
							}

							{feedback && <WrapperFeedback {...feedback} />}
							{ (isitrich && !isLastSection) && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
								<img
									src={arrowdown}
									alt="Go to next section"
									title="Go to next section"									
									className={classes.iconDownArrow}
								/>
							</div>}
							</Collapse>
						</CardBody>						
					</Card>
				</Container>
				
			</div>
		);
	}
}

GuideStandardCard.defaultProps = {
	children: (<p>Here comes the content for this Standard Card</p>),
	title: 'Standard Card',
};


export default withTranslation()(withStyles(styles)(GuideStandardCard));
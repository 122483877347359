/*!
* Description: this file contains the component for
*  the pagination
* Components: Pagination
*/
import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PaginationArrows from "components/Pagination/PaginationArrows.js";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import { ourBlue,ourOrange } from "assets/jss/incept-sustainability-variables.jsx";
import { CircularProgressbar } from 'react-circular-progressbar';
import Tooltip from 'react-power-tooltip';
import checked from 'assets/img/checked.png';

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	rootbar: {
		width: '100%',
		maxWidth: '1760px', //This is the max amount contained in the #root
		position: 'fixed',
		bottom: 0,
		backgroundColor: "white",
		fontWeight: "600",
		color: "white",
		height: "60px",
		zIndex: 1000,
		justifyContent: "center",
		alignItems: "center",
		display: "flex"
	},
	rootbar2: {
		maxWidth: '1500px',
		position: 'sticky',
		width: '100%',
		display: "flex",
	},
	root: {
		width: "100%",
		/*position: "relative"*/
	},
	/*label: {
	  fontSize: "16px",
	  fontWeight: "400",
	  opacity: "0.6",
	  width: '100%',
	  bottom: 0,
	  fontSize: '30px',
	  backgroundColor: "#ffe682",
	  color: "white",
	  borderRadius: "30px",
	  zIndex: 1000,
	  
	},
	labelafter: {
	  background:"#ffe682",
	  content: "",
	  position: "absolute",
	  top: "6px",
	  left: "195px",
	  height: "0px",
	  width: "0px",
	  borderRadius: "80px 10px",
	  border: "44px solid #545D60",
	  transform: "rotate(45deg)",
	},*/
	selected: {
		opacity: "1",
		fontSize: "16px",
		backgroundColor: "#14262c"
	},


	arrowDiv: {
		alignItems: "center",
		margin: "0 20px",
		/*"@media only screen and (max-width: 960px)": {
		  width: "18.5vh",
		},
		"@media only screen and (max-width: 660px)": {
		  width: "10vh",
		},
		"@media only screen and (min-height: 580px) and (min-width: 1024px)": {
		  marginTop: "10px",
		},
		"@media only screen and (min-height: 780px) and (min-width: 1024px)": {
		  marginTop: "0px",
		},*/
	},
	mobarrow: {
		minWidth: "140px",
		maxWidth: "168px"
	},
	navigationArrow: {
		display: "flex",
		position: "relative",
		backgroundColor: ourBlue,
		margin: "0 10px",
		//maxWidth: "70.5px",
		maxHeight: "45px",
		maxWidth: "45px",
		borderRadius: "50%",
		alignItems: "center",
		justifyContent: "center",
		/*"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
		  width: "3.5vh",
		  height: "3.5vh",
		},
		"@media only screen and (max-width: 960px)": {
		  width: "3.5vh",
		  height: "3.5vh",
		  marginTop: "-0.2vh",
		  marginLeft: "0.4vh",
		  marginRight: "0.9vh",
		},
		"@media only screen and (max-width: 660px)": {
		  width: "3.5vh",
		  height: "3.5vh",
		  marginLeft: "0.4vh",
		  marginRight: "0.9vh",
		  marginTop: "0.2vh",
		},
		"@media only screen and (max-height: 800px) (max-width: 660px)": {
		  marginTop: "1.7vh",
		},
		"@media only screen and (min-height: 568px) and (max-height: 568px)": {
		  marginTop: "0.7vh",
		  width: "4.5vh",
		  height: "4.5vh",
		},*/
	},
	backArrow: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		//alignItems: "center"

		/*"@media only screen and (max-width: 960px)": {
		  marginLeft: "-0.88vh",
		  marginTop: "-0.85vh",
		},
		"@media only screen and (max-width: 660px)": {
		  marginLeft: "-0.8vh",
		  marginTop: "-0.63vh",
		},
		"@media only screen and (min-height: 568px) and (max-height: 568px)": {
		  marginLeft: "-0.8vh",
		  marginTop: "-0.2vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
		  marginRight: "-8px",
		},*/
	},
	forthArrow: {
		display: "flex",
		justifyContent: "center",
		/*height: "100%",*/
		width: "100%",

		/*"@media only screen and (max-width: 960px)": {
		  marginLeft: "-0.55vh",
		  marginTop: "-0.82vh",
		},
		"@media only screen and (max-width: 660px)": {
		  marginLeft: "-0.5vh",
		  marginTop: "-0.63vh",
		},
		"@media only screen and (min-height: 568px) and (max-height: 568px)": {
		  marginTop: "-0.2vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
		  marginLeft: "-2px",
		  marginTop: "-4px",
		},*/
	},
	notMobile: {
		"@media only screen and (max-width: 960px) ": {
			display:"none"
		}
	},
	hide: {
		display: "none",
	},

	mobilePagination: {
		display: "flex",
		position: "fixed",
		bottom: "-2px",
		width: "100%",
		backgroundColor: "white",
		height: "50px",
		zIndex: "3",
		justifyContent: "center",
	},
	mobilePaginationInnerDiv: {
		display: "flex",
		margin: "10px",
	},

	mobileArrow1: {
		justifyContent: "center",
		marginTop: "-0.17vh",
		position: "absolute",
		right: "-10px",
	},
	mobileArrow2: {
		justifyContent: "center",
		marginTop: "-0.17vh",
		position: "absolute",
		left: "-12px",
	},
	mobnav: {
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		marginTop: "5px"
	}
};

/*
* Description: component for the pagination
*/
class Pagination extends React.Component {

	constructor(props) {
		super(props);

		const current = parseInt(props.currentPage);

		this.state = {
			currentPage: current,
			selectedArrows: Array(this.props.numPages).fill(false),
			show: false
		};
	}

	changeProgress(currentp) {
		let newSelct = Array(this.props.numPages).fill(false);
		newSelct.fill(true, 0, currentp);
		this.setState({ selectedArrows: newSelct });
	}

	componentDidMount() {
		this.changeProgress(this.props.currentPage);
	}

	componentWillReceiveProps(nextProps) {
		this.changeProgress(nextProps.currentPage);
	}

	componentDidUpdate(prevProps) {

		const [current, prevCurrent] = [parseInt(this.props.currentPage), parseInt(prevProps.currentPage)];

		if (current !== prevCurrent) {
			this.setState({
				currentPage: parseInt(this.props.currentPage)
			})
		}
	}

	showTooltip = bool => {
		this.setState({ show: bool })
	}

	handleChange = (e, newPage) => {
		this.changeProgress(newPage);
	};

	render() {
		const { classes, basePath, t, steps, onAfterFinish, showRecapModal, totalexercises, totalexercises_ans, exercises } = this.props;
		var bgcolor = "";
		var n_completed = (100*totalexercises_ans)/totalexercises;

		const pages = [...Array(this.props.numPages)].map((value, index) => {
			if (index === 1)
				bgcolor = "backgroundColor: '#14262c'";
			else
				bgcolor = "";
			const arrowname = (this.state.selectedArrows[index]) ? "labelpaginationselprev" : "";

			return (
				<BottomNavigationAction
					classes={{
						wrapper: (index === 0 ? "labelpaginationfirst " + arrowname : "labelpagination " + arrowname),
						selected: "labelpaginationsel"
					}}
					style={{ color: "white", bgcolor }} label={index + 1} value={index + 1} key={index + 1} showLabel={true}
					component={Link} to={`${basePath}/${index + 1}`} />
			);
		});

		const titles_guide = steps.map((value, index) => {
			return (
				<span key={index}>{(index!== steps.length-1) && (index+1)+" - "} {value.title} {(value.n_exercises!==0 && exercises[index]===value.n_exercises) 
					&& <img alt="" height="20" src={checked} />}</span>
			);
		});


		return (
			<div className={classes.rootbar}>
				<div className={classes.rootbar2} >
					{/*For desktop version*/}
					<Hidden smDown>
						<div
							style={{width:"auto",height:"56px",position:"relative",display:"flex",marginTop:"10px"}}
							className={classes.notMobile}
						>
						{/*<div
							style={{width:"auto",height:"56px",position:"relative"}}
							className={classes.notMobile}
							onMouseOver={() => this.showTooltip(true)}
							onMouseLeave={() => this.showTooltip(false)}
						>
							<CircularProgressbar
									percentage={n_completed}
									value={n_completed}
									text={`${Math.floor(n_completed)}%`}
									styles={{
										// Customize the root svg element
										root: { height: "55px", width: "auto", cursor: "pointer" },
										path: {stroke: ourOrange},
										text: {fill:"rgba(0, 0, 0, 0.87)"}
									}}
									/>
							<Tooltip
								show={this.state.show}
								arrowAlign="start"
								position="top right"
								lineSeparated
								color="black"
								textBoxWidth="300px"
								static>
								{titles_guide}
								</Tooltip>*/}
							<label style={{color:"black",flex:"1",fontSize:"0.9em",marginLeft:"10px"}}>{t("Feedback activities")}</label>
							<h3 style={{color:ourOrange,flex:"1", margin:"0 15px"}}>{totalexercises_ans+"/"+totalexercises}</h3>
						</div>
						<BottomNavigation
							value={this.state.currentPage}
							onChange={this.handleChange}
							className={classes.root}
						>
							{pages}
						</BottomNavigation>
						<div className={classes.arrowDiv} style={{ display: "inline-flex" }}>
							<div className={classes.navigationArrow} >
								<div className={classes.backArrow}>
									<PaginationArrows
										numPages={pages.length}
										currentPage={this.state.currentPage}
										type="back"
										basePath={basePath}
									/>
								</div>
							</div>
							<div className={classes.navigationArrow}>
								<div className={classes.forthArrow}>
									<PaginationArrows
										numPages={pages.length}
										currentPage={this.state.currentPage}
										type="forth"
										basePath={basePath}
										onClick={(this.state.currentPage === pages.length) ? showRecapModal : function () { }}
									/>
								</div>
							</div>
						</div>
					</Hidden>
					{/*For mobile version*/}
					<Hidden mdUp>
						<div className={classes.mobnav}>
							<div className={classes.navigationArrow}>
								<div className={classes.backArrow}>
									<PaginationArrows
										numPages={pages.length}
										currentPage={this.state.currentPage}
										type="back"
										basePath={basePath}
									/>
								</div>
							</div>

							<div className={classes.mobarrow}
								style={{ color: "white" }}>
								<span className={"labelpagination labelpaginationselprev"}>
									<span className={"labelpaginationsel"}>
										{this.state.currentPage} / {this.props.numPages}
									</span>
								</span>
							</div>

							<div className={classes.navigationArrow} style={{ margin: "0 20px" }}>
								<div className={classes.forthArrow}>
									<PaginationArrows
										numPages={pages.length}
										currentPage={this.state.currentPage}
										type="forth"
										basePath={basePath}
										onClick={(this.state.currentPage === pages.length) ? showRecapModal : function () { }}
									/>
								</div>
							</div>
						</div>
					</Hidden>
				</div>
			</div>
		);
	}
}

Pagination.defaultProps = {
	onChange: () => { }
};

export default withTranslation()(withStyles(styles)(Pagination));
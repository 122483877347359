import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import gobackimg from "assets/img/return.png";

function Header({ ...props }) {
	var goback = false;
	function gobackpage(props) {
		props.history.goBack();
	}
	const { classes, color } = props;
	const appBarClasses = classNames({
		[" " + classes[color]]: color
	});
	props.routes.map(prop => {
		if (window.location.href.indexOf(prop.layout + prop.path) !== -1 || window.location.href.includes("custom_table")) {
			if (prop.subpage) {
				goback = true;
			}
		}
		return "";
	});
	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<div className={classes.flex}>
					{/* Here we create navbar brand, based on route name */}
					{goback ? <Button onClick={(e) => gobackpage(props)} style={{ backgroundColor: "transparent", boxShadow: "none", padding: "0" }}>
						<img alt="" src={gobackimg} width="30" /></Button> : ""}
					{/* <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button> */}
				</div>
				<Hidden mdUp implementation="css">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(headerStyle)(Header);

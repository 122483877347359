/*!
* Description: this file contains the component for
*  the type of exercise ExtraSDG, ex. guide 3, step 3, 
*  					"  Exercise: The 6th SDGs at DFDS" (only for DFDS)
* Components: ExtraSDG
*/
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Styles
import withStyles from "@material-ui/core/styles/withStyles";

// Style components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

//Translations
import { withTranslation } from 'react-i18next';


const styles = {
    carouselPer: {
		"& .carousel": {
			margin: "0 auto"
        },
        "& .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover": {
            background: "rgba(0,0,0,0.9)",
        }
    },
	root: {
		textAlign: "center"
	}
	
};

/*
* this component renders the exercise carousel
*/
class CarouselSection extends React.Component {
	
	constructor(props){
		super(props);
		this.state = {
            
        }
	}

	render(){
		const {classes,imagesProps, t} = this.props;
        //const { images } = imagesProps;

        const imagesList = imagesProps.map((image, index) => {
            return (
                <div>
                    <img class="responsive" src={image.img} />
                    <p className="legend">{image.legend}</p>
                </div>
            );
        });

		return (
			<div className={classes.root}>
				<Carousel 
				showArrows={true} 
				className={classes.carouselPer} 
				width={"80%"}
				>
					{imagesList}
				</Carousel>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(CarouselSection));
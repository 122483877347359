/*
*  Description: this file contains a constant variable that defines
*   the code for the sidebar menu
*
*/
/**
 * Alexa documentation: smDown: If true, screens this size and up will be hidden.
 * 
 * smDown: If true, screens this size and down will be hidden.
 * https://material-ui.com/components/hidden/
 */

/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import { useLocation } from 'react-router-dom';
// core components

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

//Used for Translations
import LanguageSelect from "components/LanguageSelect/LanguageSelect.js";
import { withTranslation } from 'react-i18next';

const Sidebar = ({ ...props }) => {
	// verifies if routeName is the one active (in browser input)
	function activeRoute(routeName) {
		return window.location.href.indexOf(routeName) > -1 ? true : false;
	}
	const { classes, color, logo, languages, logo2, routes, role,renderRedirect, logoutprofile, t } = props;
	
	/*logout*/
	/*function renderRedirect() {
			if (redirect) {
					return <Redirect to='/login' />
			}
	}
	function logoutprofile() {
		localStorage.clear();
		redirect = true;
	}*/
	
	var links = (
		<List className={classes.list}>
			{routes.map((prop, key) => {

				if(prop.invisible) return null;
									
				if(prop.roleD!=role && prop.roleD!="Both" && role!="Both") return null;
				
				var listItemClasses = classNames({
						[" " + classes[color]]: activeRoute(prop.layout + prop.path)
					});
				const whiteFontClasses = classNames({
					[" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
				});
				const location = useLocation();
				return (
					<NavLink
						to={prop.name!="Logout" ? prop.layout + prop.path:location.pathname}
						className={classes.item}
						onClick={prop.name=="Logout" ? logoutprofile: function(){return;}}
						activeClassName="active"
						key={key}
					>
						
						<ListItem style={{display: "flex"}} button className={classes.itemLink + listItemClasses}>
							{typeof prop.icon === "string" ? (
								// <Icon
								//   className={classNames(classes.itemIcon, whiteFontClasses, {
								//     [classes.itemIconRTL]: props.rtlActive
								//   })}
								// >
								//   {prop.icon}
								// </Icon>
								<img className={classes.itemIcon} src={prop.icon} alt="nav item icon" />
							) : (
								<prop.icon
									className={classNames(classes.itemIcon, whiteFontClasses, {
										[classes.itemIconRTL]: props.rtlActive
									})}
								/>
							)}
							<ListItemText
								primary={
									// i18next-extract-disable-next-line
									t(props.rtlActive ? prop.rtlName : prop.name)
								}
								className={classNames(classes.itemText, whiteFontClasses, {
									[classes.itemTextRTL]: props.rtlActive
								})}
								disableTypography={true}
							/>
						</ListItem>
					</NavLink>
				);
			})}
		</List>
	);

	//Logo section
	var brand = (
		<div className={classes.logo}>
			<a
				
				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive
				})}
				target="_blank"
				style={{textAlign:"center"}}
			>
				<div className={classes.logoImage}>
					<img src={logo} className={classes.img} width="120"/>
				</div>
			</a>
		</div>
	);
	//brand 2
	var brand2 = (
		<div className={classes.logoIncept}>
			<a
				href="https://www.inceptsustainability.com/"
				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive
				})}
				style={{textAlign:"center"}}
				target="_blank"
			>
				<div className={classes.logoImage}>
					<img src={logo2} alt="logo" className={classes.imgBottom} />
				</div>
			</a>
		</div>
	);

	//background
	var backgroundStyle = (
		<div
			className={classes.background}
			style={{ backgroundColor: "white" }}
		/>
	)
	
	return (
		<div>
			{/*For mobile*/}
			<Hidden mdUp implementation="css"> 
				<Drawer
					variant="temporary"
					anchor={props.rtlActive ? "left" : "right"}
					open={props.open}
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive
						})
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
					</div>
					{(languages.length>1) && 
					<div style={{padding:"0px 35px"}}>
						<LanguageSelect complanf={languages} />
					</div>
					}
					{brand2}
					{/*backgroundStyle*/}
				</Drawer>
			</Hidden>
			{/*For desktop version*/}
			<Hidden smDown implementation="css"> 
				<Drawer
					anchor={props.rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive
						})
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
					</div>
					{(languages.length>1) && 
					<div style={{padding:"0px 35px"}}>
						<LanguageSelect complanf={languages} />
					</div>
					}
					{brand2}
					{/*backgroundStyle*/}
				</Drawer>
			</Hidden>
		</div>
	);
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
	logo: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool
};

export default withTranslation()(withStyles(sidebarStyle)(Sidebar));

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import GuidesPage from "views/Guides/GuidesPage.jsx";
import AnalyticsPage from "views/Analytics/AnalyticsPage.jsx";
import AnalyticsTable from "views/Analytics/AnalyticsTable.jsx";
import AnalyticsReadMore from "views/Analytics/AnalyticsPage2.jsx";
import AnalyticsReadMore2 from "views/Analytics/AnalyticsPage3.jsx";
import AnalyticsReadMore3 from "views/Analytics/AnalyticsPage4.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import HelpPage from "views/Help/HelpPage.jsx";
//custom icon images
import Icon1 from "assets/img/icon1.png"
import Icon2 from "assets/img/icon2.png"
import Icon3 from "assets/img/icon3.png"
import Icon4 from "assets/img/logout.png"
import Icon5 from "assets/img/graph-01.png"

import i18n from "i18next";
// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    trans: i18n.t("Dashboard"),
    icon: Icon1,
    component: DashboardPage,
    roleD: "Both",
    layout: "/admin",
  },
  {
    path: "/analytics",
    name: "Analytics",
    trans: i18n.t("Analytics"),
    icon: Icon5,
    component: AnalyticsPage,
    roleD: "Manager",
    layout: "/admin"
  },
  {
    path: "/read_more",
    name: "Analytics",
    trans: i18n.t("Analytics"),
    icon: "content_paste",
    component: AnalyticsReadMore,
    subpage: true,
    roleD: "Manager",
    layout: "/admin",
    invisible: true
  },
  {
    path: "/read_more2",
    name: "Analytics",
    trans: i18n.t("Analytics"),
    icon: "content_paste",
    component: AnalyticsReadMore2,
    subpage: true,
    roleD: "Manager",
    layout: "/admin",
    invisible: true
  },
  {
    path: "/read_more3",
    name: "Analytics",
    trans: i18n.t("Analytics"),
    icon: "content_paste",
    component: AnalyticsReadMore3,
    subpage: true,
    roleD: "Manager",
    layout: "/admin",
    invisible: true
  },
  {
    path: "/custom_table/:exerciseid",
    name: "Analytics",
    trans: i18n.t("Analytics"),
    icon: "content_paste",
    component: AnalyticsTable,
    subpage: true,
    roleD: "Manager",
    layout: "/admin",
    invisible: true
  },
  {
    path: "/guides",
    name: "Modules",
    trans: i18n.t("Modules"),
    icon: Icon2,
    component: GuidesPage,
    roleD: "Both",
    layout: "/admin"
  },
  {
    path: "/help",
    name: "Contact",
    trans: i18n.t("Contact"),
    icon: Icon3,
    component: HelpPage,
    roleD: "Both",
    layout: "/admin"
  },
  {
    path: "/user",
    name: "User Profile",
    icon: Icon3,
    component: UserProfile,
    roleD: "None",
    layout: "/admin"
  },
  {
    path: "/logout",
    name: "Logout",
    trans: i18n.t("Logout"),
    icon: Icon4,
    component: HelpPage,
    roleD: "Both",
    layout: "/admin"
  }
  
];

export default dashboardRoutes;

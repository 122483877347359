
const guidesStyle = {
  '@global': {
    'img.responsive': {
      maxWidth:'100%',
      display:'block',
      margin:'0 auto',
      cursor: 'pointer'
    },
    '.guide-subheading' : {
      textTransform:'capitalize',
      fontSize:'22px'
    },
    '.twocolumns' : {
        columnCount: 2,
        columnGap: '40px',
        /*textAlign: 'justify',*/
        "&:img": {
            width: "80%",
        },
        "@media only screen and (max-width: 960px)": {
          columnCount: "auto",
        }
    },
    ".paddingExercise": {
      padding: "30px 0",
      "@media only screen and (max-width: 960px)": {
        padding: "30px 0",
      }
    },
    a: {
      color: "#009df0"
    }
  }
};

export default guidesStyle;
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from '@material-ui/core/Button';
import { ourBlue } from 'assets/jss/incept-sustainability-variables.jsx';




const styles = (theme) => ({
	root: ({size, centered,nomargin}) => ({
		width:(size === 'large'?'250px': 
				(size === 'auto'? 'auto' : 
					'120px')
				),
		maxWidth:'100%',
		borderRadius: "20px",
		color: "white",
		cursor: "pointer",
		fontSize:size === 'large'?"18px":"13px",
		fontWeight:"700",
		padding:size === 'large'?"5px 25px" :"2px 25px",
		margin:centered?((nomargin) ? "0":"0 auto"):"16px 0",
		textTransform:size === 'large'?'uppercase':"none",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "1rem",
		},

	}),
	contained : ({lighten}) => ({
		opacity: lighten?"0.4":"1",
		backgroundColor: ourBlue,
		color: "white",
		'&:hover, &:focus' : {
			color:"white",
			backgroundColor: ourBlue,
			opacity: "0.6"
		}
	})
});

class ActionButton extends React.Component {

	render(){
		const {children, classes,  centered, lighten, ...other} = this.props;

		return (
			<Button
				variant="contained"
				className={classes.root}
				classes={classes}
				{...other}>
				{children}
			</Button>
		);
	}
}

export default withStyles(styles)(ActionButton);
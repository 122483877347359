/*!
* Description: this file contains the main base component for
*  the rendering the page view of analytics "Engagement"
*
* Components: AnalyticsReadMore
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import pattern from "assets/img/pattern01.png";

import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import ProgressBar from 'react-bootstrap/ProgressBar';
import illustrationCard from "assets/img/illustration4.png";
import { n_comp_pck, ourYellow, isAuthenticated } from 'assets/jss/incept-sustainability-variables.jsx';
import style from "assets/jss/material-dashboard-react/views/page3Style.jsx";
import axios from 'axios';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ResponsiveLine from "components/Nivo/Line.jsx";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

import jwt from 'jwt-decode';

//Translate
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";
import DatePicker from "react-datepicker";
import loadinggif from "assets/img/loading.gif";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import "react-datepicker/dist/react-datepicker.css";

const analyticsPage2Style = (theme) => ({
	'@global': {
		'.ct-readmore-1': {
			'& .ct-line': {
				stroke: "#515251"
			},
			'& .ct-point': {
				stroke: "#515251"
			},
			'& .ct-grid': {
				stroke: 'rgba(0, 0, 0, 0.2)'
			},
			'& .ct-label': {
				color: 'rgba(0, 0, 0, 0.7)'
			},
			'& .ct-labelpoint': {
				color: "white",
				fontSize: "14px"
			}
		}
	},
	typo: {
		paddingLeft: "25%",
		marginBottom: "40px",
		position: "relative"
	},
	titlepackage: {
		fontFamily: "Raleway,Helvetica,Arial,sans-serif",
		fontWeight: "300",
		lineHeight: "1.5em"
	},
	lineunder: {
		display: "block",
		border: "1px solid #116628",
		marginLeft: "-60px",
		width: "50%",
		marginBottom: "40px",
		color: "rgba(0, 0, 0, 0.87)"
	},
	buttoncolor: {
		backgroundColor: "#0095a9",
		borderColor: "#0095a9"
	},
	containerother: {
		paddingRight: "25px",
		paddingLeft: "25px",
		marginRight: "auto",
		marginLeft: "auto",
		marginTop: "3rem"
	},
	moduleCard: {
		//height: "300px",
		borderRadius: "2.2rem",
		marginTop: "0"
	},
	moduleCardBody: {
		paddingTop: "1.5rem",
		paddingRight: "2rem",
		paddingBottom: "2rem",
		paddingLeft: "2rem",
	},
	cardHeaderEngagement: {
		backgroundColor: "rgb(0, 151, 167)",
		padding: "0.75rem 1.25rem 1.25rem 1.25rem",
		color: "white"
	},
	cardTitleWhite: {
		fontWeight: "bold",
		margin: "0"
	},
	paddingTopAnalytics: {
		padding: '20px 0'
	},
	backgroundPatternImage: {
		width: "25%",
		position: "absolute",
		right: "0",
		top: "0",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	graphText: {
		position: "absolute",
		bottom: "0",
		marginBottom: "2rem",
		marginRight: "2rem",
		fontSize: "1rem",
		fontWeight: "500",
		[theme.breakpoints.down("sm")]: {
			position: "relative",
			marginRight: "0"
		},
	},
	title: {
		fontSize: "60px",
		"@media only screen and (max-width: 960px)": {
			fontSize: "40px"
		}
	},
	nograph: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		justifyContent: "center",
		fontStyle:"italic"
	},
	spaceright: {
		marginRight: "10px"
	},
	spaceright2: {
		marginRight: "20px"
	}
});

/*const engageBoxStyle = {
	title: {
		backgroundColor: '#006978',
		fontSize: '30px',
		fontWeight: 'bold',
		padding: '20px 20px',
		marginBottom: '10px',
		color: '#fff'
	},
	description: {
		backgroundColor: '#fff',
		padding: '20px 20px'
	}
};*/

const PieandBars = withStyles(style)(
	(props) => {
		const { classes, module, maintitle, data, 
			hasdata, styles, description, title1,hasactiveE,title2, totalusers, empty } = props;
		const items_bars = [];
		const items_pies = [];
		var i = 0;
		data.forEach(arrayItem => {
			if (i < 3) {
				items_pies.push(
					<GridItem xs={12} sm={12} md={12} lg={4} key={i}>
						<div className={classes.circularProgressBar}>
							<CircularProgressbarWithChildren
								value={arrayItem.val}
								circleRatio={0.6}
								strokeWidth={15}
								styles={styles}
							>
								<div className={classes.circularProgressBarProcent}>{arrayItem.val}%</div>
								<div className={classes.circularProgressBarText}>{arrayItem.label}</div>
							</CircularProgressbarWithChildren>
						</div>
					</GridItem>
				);
			}
			else {
				items_bars.push(
					<div className={classes.progressInfo} key={i}>
						<ProgressBar now={arrayItem.val} max={100} className={classes.progressBar} />
						<p style={{ marginBottom: "0.8rem", fontWeight: "500" }}><b>{arrayItem.val}%</b> {arrayItem.label}</p>
					</div>
				);
			}
			i++;
		});

		return (
			<CompanyCard 
			maintitle={maintitle} 
			module={module} 
			hasdata={hasdata} 
			title1={title1} 
			title2={title2} 
			hasactiveE={hasactiveE} 
			description={description} >
				{(module!=="Total Learnings") && <p>Total Users: {totalusers}</p>}
				{(module!=="Total Learnings") && <p>Users that submitted empty: {empty}</p>}
				<GridContainer spacing={0}>
					{items_pies}
				</GridContainer>
				<div>
					<GridItem xs={12} sm={12} md={10} lg={7} xl={12}>
						{items_bars}
					</GridItem>
				</div>

			</CompanyCard>
		);
	}
);

//For standard card
const CompanyCard = withTranslation()(withStyles(style)(
	(props) => {
		const { classes, module, maintitle, title1, 
			hasactiveE, title2, description, hasdata, children, t } = props;
		//console.log("for graph: " + description + ", hasdata: " + hasdata);
		if (hasdata) {
			return (
				<GridItem xs={12} sm={12}>
					<Card className={classes.card}>
						<CardBody style={{ padding: "2rem 2rem", position: "relative" }}>
							<h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "0" }}>{module}</h3>
							<h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: "0" }}>{maintitle}</h3>
							<div className={classes.illustrationContainer}>
								<img alt="" src={illustrationCard} width="120px" className={classes.illustration} />
							</div>
							<p style={{ fontSize: "1rem", fontWeight: "500", width: "70%" }}>
								{title1}
								{(hasactiveE) ? 
									<ActiveEmployees_tooltip
									tooltipid={"tool1"}>
									</ActiveEmployees_tooltip>:
									""}
								{(hasactiveE) ? title2:""}
							</p>
							<p style={{ fontSize: "0.8rem", fontWeight: "500", marginTop: "30px", marginBottom: "2rem" }} >{description}</p>
							{children}
						</CardBody>
					</Card>
				</GridItem>
			);
		}
		else {
			return (
				<GridItem xs={12} sm={12}>
					<Card className={classes.card}>
						<CardBody style={{ padding: "2rem 2rem", position: "relative" }}>
							<h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "0" }}>{module}</h3>
							<h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: "0" }}>{maintitle}</h3>
							<h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "0" }}>{description}</h3>
							<i>{t('No data available yet')}</i>
						</CardBody>
					</Card>
				</GridItem>
			);
		}

	}
));

/*const EngageBox = withStyles(engageBoxStyle)(
	(props) => {
		const { classes, title, description, chart } = props;
		return (
			<div className={classes.root}>
				<div>
					<div style={{ marginBottom: "20px" }}>
						{description}
					</div>
					{chart}
				</div>
			</div>
		);
	}
);*/

/*
* Component: AnalyticsReadMore
* Description: renders the cards for the analytics see more 1
*/
class AnalyticsReadMore extends React.Component {

	constructor(props) {
		super(props);
		var redlog = false;
		var decodedtoken = "";
		var d = new Date();
		const prior = new Date().setDate(d.getDate() - 30);
		if (!isAuthenticated()) {
			redlog = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem("token"));
		this.state = {
			userid: (decodedtoken!=="") ? decodedtoken.data.user.id:"",
			lineData: [],
			load: 0,
			module1_facts: [],
			first_1graph: 0,
			loadingfirst: true,
			redirectlogin: redlog,
			fromdate: new Date(prior),
			todate: new Date(),
			loading1: false,
			disable1: false,
			alllearnings: []
		};
		console.log("2");
		console.log(this.state.fromdate);
	}



	//get data from backend
	async getncomplpack() {
		//readMore1.data.series = [[9, 9, 9, 9, 9, 27, 50,21]]
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem('token');
		
		const authstr = 'Bearer ' + token;
		const useLang = i18n.language;

		let insdata = {
			headers: { Authorization: authstr },
			params: {
				fromdate: this.state.fromdate,
				todate: this.state.todate
			},
		  }

		axios.get(n_comp_pck + "engagement?wpml_language="+ useLang, insdata)
			.then(response => {
				console.log(response.data);
				let tickdays = "1";
				if (response.data.days.length>0) {
					var first_array =response.data.days[0].x.split('-');
					var firstday = new Date(first_array[2], first_array[1] - 1, first_array[0]); 
					var last_array =response.data.days[response.data.days.length-1].x.split('-');
					var lastday = new Date(last_array[2], last_array[1] - 1, last_array[0]); 
					const diffInMs   = lastday - firstday;
					const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
					if (diffInDays>30)
						tickdays = "11";
					else if (diffInDays>5)
						tickdays = "5";
				}				
				this.setState({
					module1_facts: response.data.learnednew,
					alllearnings: response.data.alllearnings,
					lineData: [
						{
							"id": "participants",
							data: response.data.days,
							nDays: tickdays,
						}
					]
				});
				//if (parseInt(response.data.maxval) > 0)
				this.setState({ load: 1 });
				this.setState({ loadingfirst: false,loading1:false,disable1:false });
			})
			.catch(err => {
				console.log(err);
			})
	}
	componentDidMount() {
		this.getncomplpack();
		i18next.on('languageChanged', () => this.getncomplpack());
	}

	setStartDate(date,where) {
		if (where===0) {
			if (date>this.state.todate) {
				alert("'From' date cannot be after 'to' date");
				return;
			}
			this.setState({fromdate: date});
		}
		else {
			if (date>this.state.todate) {
				alert("'To' date cannot be before 'from' date");
				return;
			}
			this.setState({fromdate: date});
		}
	}

	handleSubmit(e) {
		this.setState({loading1:true,disable1:true});
		this.getncomplpack();
	}

	//render component
	render() {
		const { classes, t } = this.props;
		const styles = buildStyles({
			rotation: 0.7,
			strokeLinecap: "butt",
			trailColor: "#fff4cd",
			pathColor: ourYellow,
		  });
		if (this.state.load) {
			const learnings_modules = this.state.alllearnings.map((module, index) => (
					<PieandBars
						data={module.learnings}
						hasdata={(module.learnings.length>0 ? true:false)}
						module={"Learnings in "+module.modulename}
						empty={module.empty}
						maintitle=""
						title1={t("Percentage of Employees that learned something new in module")+": "+module.modulename}
						hasactiveE={false}
						totalusers={module.totalusers}
						title2={t("Percentage of Employees that learned something new in module")+": "+module.modulename}
						description=""
						styles={styles}
					></PieandBars>
			));
			return (
				<div className={classes.containerother}>
					<div className={classes.paddingTopAnalytics}>
						{/* background image pattern */}
						<img
							className={classes.backgroundPatternImage}
							src={pattern}
							alt="background patterns"
						/>
						<GridContainer>
							{/*<GridItem xs={3} sm={3} md={3} lg={2} style={{marginTop:"50px"}}>
								<img
									style={{ maxWidth: "100%" }}
									src={illustration}
									alt="illustration engagement"
								/>
			</GridItem>*/}
							<GridItem spaceRight={0} xs={9} sm={9} md={9} lg={10} style={{ position: "relative" }}>
								<div style={{ marginTop:"50px",marginBottom: "5%"}}>
									<p className={classes.title} style={{ fontWeight: "bold" }}>{t('Engagement')}</p>
									<p style={{ fontWeight: "bold", marginTop:"30px" }}>{t('Completion overview')}</p>
								</div>
							</GridItem>

							<GridItem xs={12} sm={12} md={11} lg={11}>
								<Card className={classes.moduleCard}>
									<CardBody>
										<div style={{marginBottom:"30px"}}>
											<p>
												{t('Select the time period you wish to see completion rate')}:
											</p>
											<GridContainer>
													<p className={classes.spaceright}>{t("From")+":"}</p>
													<DatePicker
														dateFormat="dd-MM-yyyy"
														selected={this.state.fromdate}
														onChange={(date) => this.setStartDate(date,0)}
														className={classes.spaceright2}
													/>
													<p className={classes.spaceright}>{t("To")+":"}</p>
													<DatePicker
														dateFormat="dd-MM-yyyy"
														selected={this.state.todate}
														onChange={(date) => this.setStartDate(date,1)}
														className={classes.spaceright2}
													/>
													<ActionButton
														type="submit"
														style={{marginTop:0}}
														onClick={(e) => this.handleSubmit(e)}
														disabled={this.state.disable1}
													>
														{t('Filter')}
													</ActionButton>
													<div id="loadinggd2" style={this.state.loading1 ? {display: "block"}:{display:"none" }}>
														<img
															className="responsive"
															src={loadinggif}
															width="25"
															alt=""
															style={{ marginBottom: "20px" }}
														/>
													</div>
											</GridContainer>
										</div>		
										<GridContainer>
											
											<GridItem xs={12} sm={12} md={8} lg={8}>
												<div style={{ height: "350px", margin: "auto" }}>
													{this.state.lineData[0].data.length>0 && 
													ResponsiveLine(this.state.lineData,this.state.lineData[0].nDays,t)}
													{this.state.lineData[0].data.length===0 &&
														<div className={classes.nograph}>
															{t("Nothing to show during this time period")}</div>
													}
												</div>
											</GridItem>
											<GridItem xs={12} sm={12} md={4} lg={4}>
												<p className={classes.graphText}>{t('To increase participation and completion rate notifications are sent out')}.</p>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>

							{learnings_modules}

							<div>
								<PieandBars
									data={this.state.module1_facts}
									hasdata={(this.state.module1_facts.length>0 ? true:false)}
									module={t("Total Learnings")}
									maintitle=""
									title1={t("Percentage of Employees that learned something new in each module")}
									hasactiveE={false}
									title2={t("that learned something new in each module")}
									description=""
									styles={styles}
								></PieandBars>
							</div>

							<GridItem xs={12} sm={12} md={1} lg={1} />
						</GridContainer>
					</div>
				</div>
			);
		}
		else {
			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
					<div className={classes.containerother} >
						<div className={classes.paddingTopAnalytics}>
							{t('Nothing to show yet')}.
        </div>
					</div>
				</LoadingOverlay>
			);
		}
	}
}

AnalyticsReadMore.propTypes = {
	classes: PropTypes.object
};

export default withTranslation()(withStyles(analyticsPage2Style)(AnalyticsReadMore));

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from 'assets/languages/en/translation.json';
import translationDK from 'assets/languages/da/translation.json';


// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN
  },
  /*es:{
    translation: translationES
  },*/
  da:{
    translation: translationDK
  }
  // dk: {
  //   translation: translationDK
  // }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: ["en", "da"],
    returnEmptyString: false, //This is needed to avoid empty translations for keys with empty strings
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
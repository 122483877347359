/**
 * Description: this file contains the main base component for
 *  the rendering the dashboard
 *
 * Components: Dashboard
 */
import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';
// react plugin for creating charts
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import classNames from "classnames";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Typography from "@material-ui/core/Typography";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import pattern from "assets/img/pattern01.png";
import modalPattern from "assets/img/modal_pattern-02-01.png";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import icon4 from "assets/img/icon4.png";
import icon5 from "assets/img/icon5.png";
import icon7 from "assets/img/icon7.png";

// import illustration1 from "assets/img/illustration1.png";
// react-boostrap
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FormGroup,  Input } from "reactstrap";

import { save_data, n_comp_pck , ourDarkGreen, 
	ourYellow, get_company_user_info,reminder_email,ourGreen,isAuthenticated} from "assets/jss/incept-sustainability-variables.jsx";


import video1 from "assets/videos/video_dashboard.mp4";
import videobackgr from "assets/img/videobackgr.PNG";
import { Link } from "react-router-dom";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";

import axios from "axios";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import jwt from "jwt-decode";
import { IconButton } from "@material-ui/core";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton";

//Used for first time login
import {default as CustomModal}  from "components/Modal/Modal.jsx";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";

//Translations
import i18n from "i18next";
import { withTranslation, Trans } from 'react-i18next';
import i18next from "i18next";
import { ourOrange } from "assets/jss/incept-sustainability-variables";
import log from 'loglevel';

const styles = theme => ({
	fullHeight: {
	  ...theme.mixins.toolbar,
	},
  });

function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
		>
		{value === index && (
			<Box sx={{ p: 3 }}>
			<Typography>{children}</Typography>
			</Box>
		)}
		</div>
	);
}

TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function createData(name, value, visible) {
	if (!visible) {
		name="";
		value="";
	}
	return { name, value};
}

const ModuleCard = withStyles(dashboardStyle)(
	(props) => {
		const [value, setValue] = useState(0);
		const { classes, t, moduleDept, moduleName } = props;
		let rows = [];

		const handleChange = (event, newValue) => {
			setValue(newValue);
		};

		const departmentsTabs = moduleDept.map((department, index) => (
			<Tab label={department.name} {...a11yProps(index)}/>
		));

		const departments = moduleDept.map((department, index) => {
			let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
			let dt = new Date(department.completebefore.replace(pattern,'$3-$2-$1'));
			let today = new Date();
			let isAfter = (today>dt);
			rows = [
				createData(t('Launched'), department.launched, (department.launched!=="")),
				createData(isAfter ? <span style={{color:"red"}}>{t('Overdue')}</span>:t('Due on'), department.completebefore,(department.completebefore!=="")),
				createData(t('Employees sign up'), department.nregistered,true),
				createData(t('Active Employees'), department.nactive+" ("+department.pactive+"%)",true),
				createData(t('Employees completed'), department.ncompleted+" ("+department.pcompleted+"%)",true),
			];
			return (
				<TabPanel value={value} index={index} className={classes.tableModule}>
					<TableContainer >
						<Table sx={{ minWidth: 650 }} aria-label="simple table" >
							<TableBody>
							{rows.map((row) => (
								(row.name!=="") && <TableRow
								key={row.name}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell align="right">
									<button disabled className={classes.smallButton}>{row.value}
									</button>
								</TableCell>
								</TableRow>
							))}
							</TableBody>
						</Table>
						{/*<div style={{width:"100%",textAlign:"center"}}>
						<button
							id="2"
							className={classes.button3}
							onClick={(e) => this.showHideModal(e.target.id)}
							style={{marginTop:"30px",width:"auto"}}
						>
							<strong style={{ margin: "0" }}>{t('Send a reminder')}</strong>
						</button>
							</div>*/}
					</TableContainer>
				</TabPanel>
			);
		});

		if (moduleDept.lenght<=0) {
			return (
				<span></span>
			)
		}

		return (
			
				<GridItem xs={12} sm={12} md={6} lg={4} style={{marginBottom: "15px"}}>
					<Card className={classes.moduleCard}>
						<CardBody className={classes.moduleCardBody} style={{paddingTop: "10px"}}>
							<h4 style={{ fontWeight: "400",display: "table-row"  }}>
								<strong>{t('Module')+': '+moduleName}</strong>
							</h4>
							<hr style={{marginBottom:"20px"}}></hr>
									<Tabs
										value={value}
										onChange={handleChange}
										variant="scrollable"
										scrollButtons="auto"
										allowScrollButtonsMobile
										className={classes.tabsMenu}
										aria-label="basic tabs example"
									>
										{departmentsTabs}
									</Tabs>
								{departments}
						</CardBody>
					</Card>
				</GridItem>
		);
	}
);	

const ReminderCard = withStyles(dashboardStyle)(
	(props) => {
		const [value, setValue] = useState(0);
		const { classes, t, sendReminder, latestActivity } = props;
		let rows = [];

		latestActivity.map((department, index) => (
			rows.push(createData(department.name, <span>Last submitted activity:<br></br>{department.lastcompleted}</span>,true))
		));

		return (
			<div>
				<h4>{t("Latest activities")}</h4>
				<div style={{maxHeight:"160px",overflow:"auto"}}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table" >
					<TableBody>
					{rows.map((row) => (
						(row.name!=="") && <TableRow
						key={row.name}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
						<TableCell component="th" scope="row">
							{row.name}
						</TableCell>
						<TableCell align="right">
							<span style={{fontSize:"13px",fontStyle:"italic"}}>{row.value}</span>
						</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
				</div>
				<div style={{width:"100%",textAlign:"center"}}>
				<button
					id="2"
					className={classes.button3}
					onClick={sendReminder}
					style={{marginTop:"20px",width:"auto"}}
				>
					<strong style={{ margin: "0" }}>{t('Send a reminder')}</strong>
				</button>
				</div>
			</div>
		)

	}
);
class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		var redlog = false;
		var decodedtoken = "";
		if (!isAuthenticated()) {
			redlog = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem("token"));
		//Check if has ever logged in at least once, and then set it to true
		const hasEverLoggedIn = JSON.parse(localStorage.getItem('hasEverLoggedIn')??'false');
		localStorage.setItem('hasEverLoggedIn',JSON.stringify(true));
		
		this.state = {
			value: 0,
			redirectLogin: redlog,
			manager: //Determines if it is a manager or a employee
				decodedtoken.role === "Manager" || decodedtoken.role === "Admin"
					? true
					: false,
			whatsnew: {
				"total_users":0,
				"started_modules":0,
				"ideas_submited":0,
				"users_ideas":0
			},
			package_completion: {
				"completed_package":0,
				"are_module1":0,
				"are_module2":0,
				"havent_started":0,
				"weeks_started":0
			},
			loadingfirst: true,
			company: (decodedtoken==="") ? "":decodedtoken.company,
			userid: (decodedtoken==="") ? "":decodedtoken.data.user.id,
			showVideoModal: false,
			showReminderModal: false,
			showIdeasModal: false,
			showFirstLoginModal:!hasEverLoggedIn,
			addNewidea: false,
			descriptionReminder: "",
			dataBackend: {
				ideas: "",
				ideasall: [],
			},
			allideas:"",
			seemore1: false,
			seemore2: false,
			showideabox: true,
			errorBoundary: false,
			errorMessage:"",
			modulesStats: [],
			latestActivity: [],
			valueDD: 0,
			labels: []
		};

		if (redlog)
			this.props.isExpired();

		this.handleHideFirstLoginModal = this.handleHideFirstLoginModal.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		if (this.state.descriptionReminder === "") {
			alert("Description cannot be empty");
			return;
		}

		document.getElementById("thankyoudiv").style.display = "none";
		document.getElementById("errordiv").style.display = "none";

		this.setState({ loading: true });
		const formdata = {
			text: this.state.descriptionReminder,
		};

		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;

		this.setState({ loaloadingfirstding: true }, () => {
			axios
				.post(reminder_email, formdata, { headers: { Authorization: authstr } })
				.then((res) => {
					this.setState({ loaloadingfirstding: false, descriptionReminder: "" });
					document.getElementById("thankyoudiv").style.display = "block";
				})
				.catch((err) => {
					let errormessage = "";
					if (err.response!==undefined) {
						errormessage = err.response.data.message;
						console.log(err.response.data.message);
					}
					else {
						errormessage += err;
						console.log(err);
					}
					//this.error_ocurred("Error API "+reminder_email+" "+errormessage);
					log.error("Error API "+reminder_email+" "+errormessage);
					this.setState({ loaloadingfirstding: false});
					document.getElementById("errordiv").style.display = "block";
				});
		});
	}


	handleHideFirstLoginModal(){
		try {
			this.setState({showFirstLoginModal:false});
		}
		catch(error) {
			throw new Error("Component: Dashboard - handleHideFirstLoginModal");
		}
	}

	handleChange(e) {
		try {
			const allideas = e.target.value;
			this.setState({ allideas: allideas });
		}
		catch(error) {
			throw new Error("Component: Dashboard - handleChange");
		}
	}
  
	saveideas() {
		if(this.state.allideas==="")
			return;

		if (!isAuthenticated()) {
			this.props.isExpired();
		}

		var arraytosend = [];
		var aux2 = { type: "Ideas", content: this.state.allideas, label: this.state.valueDD };
		arraytosend["cIdeas"] = aux2;
		var takstosave = [];
		takstosave.push("Ideas"); 


		//data to send
		const user_id = this.state.userid; //userid
		const insdata = {
			userid: user_id,
			tosave: JSON.stringify({ ...arraytosend }),
			guide: 0,
			step: "",
			postid: 0,
			task: 0,
			ntasks: takstosave,
			ifn: 0
		};

		const token = localStorage.getItem("token"); //token needed
		const authstr = "Bearer " + token;
		//post
		this.setState({ loading: true }, () => {
			axios
			.post(save_data+"0/answers", insdata, { headers: { Authorization: authstr } })
			.then((res) => {
				this.setState({ allideas: "" });
				this.getgeneralinfo();
			})
			.catch((err) => {
				//this.error_ocurred("Error API "+save_data+" "+err);
				let errorDes = "Error API "+(save_data+"0/answers ")+err;
				log.error(errorDes);
				console.log(err);
			});
		});
	}

	handleChange2(e) {
		try {
			if(this.state.allideas==="")
				return;
			this.saveideas();
			this.setState({showideabox:false,allideas: "" });
			/*document.getElementById("thankudiv3").style.display = "block";
			document.getElementById("ideabox3").style.display = "none";*/

			this.timeoutIdeas = setTimeout( () => {
				this.setState( prevState => ({
					showideabox:true
				}));
			}, 2000);
		}
		catch(error) {
			throw new Error("Component: Dashboard - saveideas");
		}
	}

	seemore(e,id) {
		try {
			e.preventDefault();
			if (id==="id1")
				this.setState({ seemore1: !this.state.seemore1});
			else 
				this.setState({ seemore2: !this.state.seemore2 });
		}
		catch(error) {
			throw new Error("Component: Dashboard - seemore");
		}
	}

	//For Manager view: get number of employees that completed the package (for analytics dashboard)
	async getncomplpack() {
		if (this.state.manager) {
			this.setState({ loadingfirst: true});
			if (!isAuthenticated()) {
				this.props.isExpired();
			}
			const token = localStorage.getItem("token");

			const authstr = "Bearer " + token;
			const useLang = i18n.language;
			let url = n_comp_pck + "dashboard?wpml_language="+ useLang;
			axios
				.get(url, { headers: { Authorization: authstr } })
				.then((response) => {
					console.log(response);
					var stateCopy = {};
					stateCopy["started_modules"] = response.data.nusers;
					stateCopy["ideas_submited"] = response.data.nideas;
					stateCopy["users_ideas"] = response.data.pideas;
					stateCopy["total_users"] = response.data.allusers;
					this.setState({ 
						whatsnew: stateCopy,
						modulesStats: response.data.infoModules,
						latestActivity: response.data.latestActivity,
						loadingfirst: false
					});
				})
				.catch((err) => {
					//this.error_ocurred("Error API "+n_comp_pck+" "+err);
					let errorDes = "Error API "+(save_data+ "dashboard ")+err;
					log.error(errorDes);
					this.setState({ error: err, loading: false, loadingfirst: false });
				});
		}
	}
	//For employee view:
	async getgeneralinfo() {
		this.setState({ loadingfirst: true});
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem('token');
		const authstr = 'Bearer ' + token;
		const useLang = i18n.language;	
		let url = get_company_user_info + "dashboard?wpml_language=" + useLang;
		axios.get(url, { headers: { Authorization: authstr } })
			.then(response => {
				var stateCopy = {};
				stateCopy["ideas"] = { "idea1": response.data.idea1, "idea2": response.data.idea2 };
				stateCopy["ideasall"] = response.data.ideasall;
				this.setState({ dataBackend: stateCopy,loadingfirst: false, labels: response.data.labels });
			})
			.catch(err => {
				//this.error_ocurred("Error API "+n_comp_pck+" "+err);
				let errorDes = "Error API "+(save_data+ "dashboard ")+err;
				log.error(errorDes);
				this.setState({ error: err, loadingfirst: false, errorMessage:  err });
			})
	}
	componentDidMount() {
		this.getncomplpack();
		this.getgeneralinfo();
		i18next.on('languageChanged', () => this.getncomplpack());
	}

	showHideModal = (id) => {
		try {
			if (id === 1) {
			this.setState({ showVideoModal: !this.state.showVideoModal });
			} 
			else if (id===3) {
			this.setState({ showIdeasModal: !this.state.showIdeasModal });
			}
			else if (id===4) {
			this.setState({ addNewidea: !this.state.addNewidea });
			}
			else {
			this.setState({ showReminderModal: !this.state.showReminderModal });
			}
		}
		catch(error) {
			throw new Error("Component: Dashboard - showHideModal");
		}
 	};

	error_ocurred = (msg = "") => {
		this.setState({errorBoundary:true,errorMessage:msg})
	}

	onChangeDD(event){
		const answerIndexSelected = parseInt(event.target.value); 
		this.setState({valueDD:answerIndexSelected});
	}

	render() {
		const { classes, t, databackend } = this.props;
		const { username } = databackend;

		if (this.state.errorBoundary) {
			throw new Error("Error: "+this.state.errorMessage);
		}
		// Analytics (if user is manager)
		if (this.state.manager) {
			const ModulesSections = this.state.modulesStats.map((module, index) => (
					<ModuleCard
					classes={classes}
					t={t}
					moduleName={module.name}
					moduleDept={module.departments}
					/>
			));

			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
					{this.state.errorMessage==="" &&
					<div className={classes.containerother}>
						<div className={classes.gridWrappper}>
							{/* background image pattern */}
							<img
								className={classes.backgroundPatternImage}
								src={pattern}
								alt="background patterns"
							/>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<Card className={classes.welcomeGrid}>
										<CardBody
											style={{ lineHeight: "3", padding: "1rem" }}
										>
											<h3 style={{ margin: "0 0 5px", fontWeight: "400" }}>
												<strong>{t('Welcome to the Analytics Report at company',{company: this.state.company, defaultValue:'Welcome to the Analytics Report at {{company}}'})}</strong>
											</h3>
											<h5>
												{/*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam nunc est.*/}
											</h5>
										</CardBody>
									</Card>
								</GridItem>
								<GridContainer space={true}>
									<GridItem xs={12} sm={12} md={12} lg={5}>
										<Card className={classes.moduleCard} /* style={{ height: "300px" }} */>
											<CardBody className={classes.moduleCardBody}>
												<h3 style={{ fontWeight: "400", color: ourDarkGreen, marginBottom: "0px", marginTop: "0px" }}>
													<strong>{t('What\'s new?')}</strong>
												</h3>
												<p style={{ fontWeight: "400" }}>
													{/*<strong>Since your last login...</strong>*/}
												</p>
												<GridContainer>
													<GridItem xs={3} sm={2} md={2} space={0}>
														<button disabled className={classes.smallButton}>
															{this.state.whatsnew["total_users"]}
														</button>
													</GridItem>
													<GridItem xs={9} sm={10} md={10} space={0}>
														<Typography component="p" variant="body1" className={classes.whatsnewtext}
														 style={{ fontWeight: "500", marginLeft: "30px" , marginBottom:"15px"}}>
															 {t('Employees have been registered to take the modules')}
														</Typography>
													</GridItem>
													<GridItem xs={3} sm={2} md={2} space={0}>
														<button disabled className={classes.smallButton}>
															{this.state.whatsnew["started_modules"]}
														</button>
													</GridItem>
													<GridItem xs={9} sm={10} md={10} space={0}>
														<Typography component="p" variant="body1" className={classes.whatsnewtext}
														 style={{ fontWeight: "500", marginLeft: "30px" , marginBottom:"15px"}}>
															 <b>{t('Active Employees')}</b>: {t('Employees that have started the modules of the sustainability package')}
														</Typography>
													</GridItem>
													<GridItem xs={3} sm={2} md={2} space={0}>
														<button disabled className={classes.smallButton}>
															{this.state.whatsnew["ideas_submited"]}
														</button>
													</GridItem>
													<GridItem xs={9} sm={10} md={10} space={0}>
														<Typography component="p" variant="body1" className={classes.whatsnewtext} 
														style={{ fontWeight: "500", marginLeft: "30px", marginBottom:"15px"}}>
															{t('Ideas have been submitted')}
														</Typography>
														{/*<Typography component="p" variant="body1" style={{ fontSize: "10px", marginLeft: "0.5rem" }}>*Lorem ipsum dolor sit amet, consectetur adipiscing elit.
																Donec sagittis hendrerit tellus at malesuada...</Typography>*/}
													</GridItem>
													<GridItem xs={3} sm={2} md={2} space={0}>
														<button disabled className={classes.smallButton}>
															{this.state.whatsnew["users_ideas"]+"%"}
														</button>
													</GridItem>
													<GridItem xs={9} sm={10} md={10} space={0}>
														<Typography component="p" variant="body1"  className={classes.whatsnewtext}
														style={{ fontWeight: "500", marginLeft: "30px" }}>
															{<Trans
																i18nKey="X num of Active Employees have submitted ideas"
																defaults="of <0></0> have submitted ideas"
																components={[<ActiveEmployees_tooltip tooltipid={"tool1"}></ActiveEmployees_tooltip>]}
															/>}
															{/*t('of all Employees have submitted ideas')*/}
														</Typography>
														<Typography component="p" variant="body1" 
														style={{ fontSize: "10px", marginLeft: "0.5rem" }}>
														</Typography>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
									</GridItem>
									<GridItem xs={12} sm={5} md={5} lg={4} /* style={{ marginTop: "0" }} */>
										<Card className={classes.moduleCard}>
											<CardBody className={classes.moduleCardBody}>
												<ReminderCard
												companyname={this.state.company}
												latestActivity={this.state.latestActivity}
												sendReminder={(e) => this.showHideModal(5)}
												t={t}
												/>
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
								



							<GridContainer nonunset={true} style={{marginBottom:"30px"}}>
							{ ModulesSections
								
							}
							</GridContainer>


								
							</GridContainer>
							<Modal
								id="1"
								show={this.state.showVideoModal}
								onHide={() => this.setState({ showVideoModal: false })}
								centered
								size="lg"
								className={classes.modalBox}
							>
								<Modal.Body>
									<IconButton onClick={() => this.setState({ showVideoModal: false })} className={classes.modalCancelButton}>
										<img
											src={icon7}
											alt="cancel icon"
											style={{ width: "40px", height: "40px" }}
										/>
									</IconButton>
									{/* <img
										className={classes.modalVideo}
										src={videobackgr}
										alt="intro video"
									/> */}
									<video
										src={this.props.databackend.videoM}
										width="100%"
										//height="240"
										controls
										className={classes.modalVideo}
									>
										<source src={this.props.databackend.videoM} type="video/mp4" />
									</video>
									<img
										className={classes.modalPattern}
										src={modalPattern}
										alt="background patterns"
									/>
								</Modal.Body>
							</Modal>
							<Modal
								id="2"
								show={this.state.showReminderModal}
								onHide={() => this.setState({ showReminderModal: false })}
								centered
								size="lg"
								className={classes.modalBox}
							>
								<Modal.Body>
									<IconButton onClick={() => this.setState({ showReminderModal: false })} className={classes.modalCancelButton}>
										<img
											src={icon7}
											alt="cancel icon"
											style={{ width: "40px", height: "40px" }}
										/>
									</IconButton>
									<div style={{ padding: "2rem" }}>
										<h5 style={{ fontWeight: "bold" }}>{t('Write a message to your team to remind them about completing the package')}.</h5>
										<Form>
											<FormGroup>
												<Input
													className={classes.inputreminder}
													type="textarea"
													name="text"
													id="textform"
													height="168px"
													rows="6"
													onChange={(e) =>
														this.setState({ descriptionReminder: e.target.value })
													}
												/>
											</FormGroup>
											<div style={{ float: "right" }}>
												<div
													id="thankyoudiv"
													style={{
														color: "green",
														marginBottom: "20px",
														display: "none"
													}}
												>
													{t('Reminder message', { defaultValue: 'Thank you. The reminder will be sent as soon as possible.' })}
												</div>
												<div
													id="errordiv"
													style={{
														color: "green",
														marginBottom: "20px",
														display: "none"
													}}
												>
													{t('There was an error', {
														defaultValue: 'There was an error, please try again or contact us at inceptsustainability@ramboll.com.'
													})}
												</div>
												
												<ActionButton
													size="auto"
													color="default"
													onClick={this.toggle}
													style={{ float: "right", fontWeight: "bold" }}
												>
													{t('Submit')}
												</ActionButton>
											</div>
										</Form>
									</div>
									<img
										className={classes.modalPattern}
										src={modalPattern}
										alt="background patterns"
									//style={{ zIndex: "1" }}
									/>
								</Modal.Body>
							</Modal>
						</div>
					</div>
					}
					{this.state.errorMessage!=="" &&
					<div className={classes.containerother}>
						<div className={classes.gridWrappper} style={{marginTop:"30px"}}>
						<h2>Well, this was unexpected. </h2>
						<p>
							Sorry, this is not working properly. We now know about
							it and are working to fix it.
						</p>
						<p>In the meantime, here is what it can be done:
							<ul>
								<li>Refresh the page (sometimes this helps).</li>
								<li>Make sure you have internet connection.</li>
								<li>Try again in 30 minutes.</li>
								<li>Email us at inceptsustainability@ramboll.com and tell us what happened.</li>
							</ul>
						</p>
						</div>
					</div>
					}
				</LoadingOverlay>
			);
		} //Employees view
		else {
			return (
				<LoadingOverlay
					active={this.props.loading}
					spinner={<BounceLoader />}
				>
				{this.state.errorMessage==="" &&
				<div className={classes.containerother}>
					<div className={classes.gridWrappper}>
						{/* background image pattern */}
						<img
							className={classes.backgroundPatternImage}
							src={pattern}
							alt="background patterns"
						/>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<Card className={classes.welcomeGrid}>
									<CardBody
										style={{ padding: "1rem" }}
									>
										<h3 style={{ margin: "0 0 5px", fontWeight: "400",marginLeft:"15px" }}>
                      						<strong>{t('Welcome username',{username:this.props.databackend.username, defaultValue:"Welcome, {{username}}!"})}</strong>
										</h3>
										<GridContainer>
											<GridItem xs={12} sm={12} md={8}>
												<p style={{ marginRight: "3rem" }}>
													{t('Click "Start" to go to the Modules')}
												</p>
											</GridItem>
											<GridItem xs={12} sm={12} md={4}>
												
												<Link to={"/admin/guides/"}>
													<ActionButton 
														className={classes.welcomeGridButton} 
														style={{ marginTop: "15px" }}
													>
														{t('Start')}
													</ActionButton>
												</Link>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={12} sm={12} md={5} space={0}>
								<GridItem xs={12} sm={12} md={12}>
									<Card className={classes.DFDSSection}>
										<CardBody>
										{ReactHtmlParser(this.props.databackend.message)}
										</CardBody>
									</Card>
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									<Card className={classes.bikeSection}>
										<div className={classes.illustrationWrapper}>
											<GridContainer>
												<GridItem xs={12} sm={12} md={7}>
														<p
															className={classes.bikeSectionText}
														>
															{this.props.databackend.tip.tip}
														</p>
												</GridItem>
												<GridItem xs={12} sm={12} md={5}>
													<div className={classes.imgtip}>
														<img alt="" src={this.props.databackend.tip.tipimg} className="responsive" 
														style={{marginBottom:"10px"}} />
													</div>
												</GridItem>
											</GridContainer>
										</div>
									</Card>
								</GridItem>
							</GridItem>
							<GridItem xs={12} sm={12} md={7} space={0} style={{ marginTop: "0" }}>
								<div className={classes.secondWrapper}>
									<GridItem xs={12} sm={12} md={5} style={{ marginTop: "0" }}>
										<Card className={classes.moduleCard}>
											<CardBody className={classes.moduleCardBody2+" "+classes.compmodules}>
												<h5 style={{ fontWeight: "400",display: "table-row"  }}>
													<strong>{t('Completed modules')}</strong>
												</h5>
												<div style={{verticalAlign: "middle",display: "table-cell"}}>
													<CircularProgressbarWithChildren
														value={this.props.databackend.completedm}
														strokeWidth={12}
														styles={buildStyles({
															strokeLinecap: "butt",
															pathColor: "#ffe682",
															trailColor: "#FFEEAD",
															pathTransition: "none",
														})}
														/*className={classes.compmod}*/
													>
														{
															<div
																className={classes.data}
																style={{
																	fontSize: "170%",
																}}
															>
																{this.props.databackend.completedm}%
															</div>
														}
													</CircularProgressbarWithChildren>
												</div>
											</CardBody>
										</Card>
									</GridItem>

									<GridItem xs={12} sm={12} md={7} style={{ marginTop: "0" }}>
										<Card className={classes.moduleCard}>
											<CardBody className={classes.moduleCardBody2}>
												<h5 style={{ fontWeight: "400",display: "table-row" }}>
													<strong>{t('Watch this introductory information')}</strong>
												</h5>
												<div style={{verticalAlign: "middle",display: "table-cell"}}>
													{/*<img
														id="1"
														className={classes.cardImg}
														style={{ width: "100%" }}
														src={videobackgr}
														alt="video background"
														onClick={(e) => this.showHideModal(1)}
													/>*/}
													<div onClick={(e) => this.showHideModal(1)}>
													{ReactHtmlParser(this.props.databackend.videoE)}
													</div> 
												</div>
											</CardBody>
										</Card>
									</GridItem>
								</div>
								<GridItem xs={12} sm={12} md={12} style={{ marginTop: "0" }}>
									<Card style={{ borderRadius: "2.2rem", marginTop: "0" }} >
										<CardBody className={classes.moduleCardBody}>
											<h5 style={{fontWeight: "400"}}>
													<strong>{t('My comments and ideas')}</strong>
												</h5>
											
											{this.state.dataBackend.ideas.idea1 !== "" ? (
												<div className={(this.state.seemore1 ? classes.commentsContainer:classNames(classes.ellipsistext, classes.commentsContainer))}>
													<img alt="" className={classes.commentsIcon} src={icon4} />
													<Typography
														component="p"
														variant="body1"
														style={{ textAlign: "justify" }}
														onClick={(e) => this.seemore(e,"id1")}
													>
														{this.state.dataBackend.ideas.idea1}
													</Typography>
												</div>
											) : (
												<div style={{fontStyle:"italic"}}>
													<p>{t('Your ideas and comments will be displayed here')}</p>
												</div>
											)}
											{this.state.dataBackend.ideas.idea2 !== "" ? (
												<div className={(this.state.seemore2 ? classes.commentsContainer:classNames(classes.ellipsistext, classes.commentsContainer))} style={{ display: "flex" }}>
													<img alt="" className={classes.commentsIcon} src={icon5} />
													<Typography
														component="p"
														variant="body1"
														style={{ textAlign: "justify" }}
														onClick={(e) => this.seemore(e,"id2")}
													>
														{" "}
														{this.state.dataBackend.ideas.idea2}
													</Typography>
												</div>
											) : (
												<div></div>
											)}
											<div className={classes.buttonSection}>
												<ActionButton 
													size="auto"
													onClick={(e) => this.showHideModal(4)}
													className={classes.button2}
												>
													{t('Add new comment or idea')}
												</ActionButton>
												<ActionButton
													size="auto" 
													onClick={(e) => this.showHideModal(3)}
													className={classes.button2}
												>
													{t('See all')}
												</ActionButton>
											</div>
										</CardBody>
									</Card>
								</GridItem>
							</GridItem>
						</GridContainer>
						<CustomModal
							show={false}
							onHide={this.handleHideFirstLoginModal}
						>
							<LoadingOverlay
								styles={{
									overlay:(base) => ({
										...base,
										backgroundColor:'transparent'
									})
								}}
								active={!username}
								spinner={<BounceLoader />}
							>
								<div style={{minHeight:'100px'}}>
									{username && <h1>{t('Welcome')}, {username}</h1>}
									{username && 
										<video
											src={video1}
											width="100%"
											controls
											className={classes.modalVideo}>
											<source src={video1} type="video/mp4" />
										</video>
									}
								</div>
							</LoadingOverlay>
						</CustomModal>
						<Modal
							id="1"
							show={this.state.showVideoModal}
							onHide={() => this.setState({ showVideoModal: false })}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
							<IconButton onClick={() => this.setState({ showVideoModal: false })} className={classes.modalCancelButton}>
								<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							{/* <img
								className={classes.modalVideo}
								src={videobackgr}
								alt="intro video"
							/> */}
							<div className={classes.modalVideo}>
							{ReactHtmlParser(this.props.databackend.videoE)}
							</div>
							{/*<video
								src={this.props.databackend.videoE}
								width="100%"
								//height="240"
								controls
								className={classes.modalVideo}
							>
								<source src={this.props.databackend.videoE} type="video/mp4" />
							</video>*/}
							<img
								className={classes.modalPattern}
								src={modalPattern}
								alt="background patterns"
							/>
							</Modal.Body>
						</Modal>
						<Modal
							id="3"
							show={this.state.showIdeasModal}
							onHide={() => this.setState({ showIdeasModal: false })}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
							<IconButton onClick={() => this.setState({ showIdeasModal: false })} 
							className={classes.modalCancelButton}>
								<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{margin:"30px"}}>
							<h5 style={{fontWeight: "400"}}>
									<strong>{t('My comments and ideas')}</strong>
									</h5>
								{(this.state.dataBackend.ideasall!==undefined) && this.state.dataBackend.ideasall.map((value, index) => {
									return ( 
										<div key={"ideasdashboard"+index} className={classes.commentsContainer}>
											<img alt="" className={classes.commentsIcon} src={icon4} />
											<Typography
												component="p"
												variant="body1"
												style={{ textAlign: "justify" }}
											>
												{value}
											</Typography>
											</div>
									)})
								}
							</div>
							</Modal.Body>
						</Modal>
						<Modal
							id="4"
							show={this.state.addNewidea}
							onHide={() => this.setState({ addNewidea: false })}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
							<IconButton onClick={() => this.setState({ addNewidea: false })} className={classes.modalCancelButton}>
								<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
								<h5 style={{ fontWeight: "bold" }}>{t('Add new comment or idea')}</h5>
								<div id="ideabox"
								className={this.state.showideabox ? classes.showElement:classes.hideElement}>
									<Form>
									<Form.Control 
									as="textarea" 
									onChange={(e) => this.handleChange(e)}
									rows="8" 
									value={this.state.allideas}
									className={classes.textarea1} />
									{(this.state.labels !== undefined && this.state.labels.length>0) && 
									<Grid container className={classes.categorygrid}>
										<Grid item xs={12} md={8} className={classes.rightlabel}>
											<p style={{marginBottom:"0"}}>{t("Please select the category")+":"}</p>
										</Grid>
										<Grid item xs={12} md={4}>
											<DropDown
												value={this.state.valueDD}
												color="black"
												paddingShort={true}
												onChange={(event) => this.onChangeDD(event)}
											>
												{this.state.labels.map(
													(item) => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
												)}
												<MenuItem value={0}>
													<em>Other</em>
												</MenuItem>
											</DropDown>
										</Grid>
									</Grid>}
									<p className={classes.footertext}>
									{t('no personal data',
										{
											defaultValue: 'Please keep your submission anonymous, do not write any personal data in your submission.'})}
									</p>
									<ActionButton 
										onClick={(e) => this.handleChange2(e)}
										style={{ float: "right" }}
										className={classes.submitButton}>
										{t('Submit')}
									</ActionButton>
									</Form>
								</div>
								<div style={{margin:"30px 0 60px 0", textAlign:"center", position: "relative",zIndex:"9999"}}
									className={"responsive "+(!this.state.showideabox ? classes.showElement:classes.hideElement)}>
									<h2 style={{color:ourGreen}}>{t('Thank you!')} </h2>
									<h3>{t('Your comment has been received')+"."}</h3>
								</div>
							</div>
							<img
								className={classes.modalPattern}
								src={modalPattern}
								alt="background patterns"
							/>
							</Modal.Body>
						</Modal>
					</div>
				</div>
				}
				{this.state.errorMessage!=="" &&
				<div className={classes.containerother}>
					<div className={classes.gridWrappper} style={{marginTop:"30px"}}>
					<h2>Well, this was unexpected. </h2>
					<p>
						Sorry, this is not working properly. We now know about
						it and are working to fix it.
					</p>
					<p>In the meantime, here is what it can be done:
						<ul>
							<li>Refresh the page (sometimes this helps).</li>
							<li>Make sure you have internet connection.</li>
							<li>Try again in 30 minutes.</li>
							<li>Email us at inceptsustainability@ramboll.com and tell us what happened.</li>
						</ul>
					</p>
					</div>
				</div>
				}
				</LoadingOverlay>
			);
		}
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(Dashboard));

/*!
 * Description: this file contains the main base component for
 *  the rendering of the modules
 * Components: GuideSectionManager, Guide
 */

import React, {useRef, useEffect} from "react";
import ReactHtmlParser from "react-html-parser";
import { Redirect } from "react-router-dom";
import ScrollToTop from "components/Scroll/ScrollToTop.js";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import Collapse from "@material-ui/core/Collapse";
// UI Core
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router";

// UI Lab
import {
	TimelineSeparator,

	TimelineContent,
	TimelineDot
} from '@material-ui/lab';
import TimelineItem from 'components/Timeline/TimelineItem';
import Timeline from 'components/Timeline/Timeline';

import GuideTopContainer from "components/GuidePage/GuideTopContainer.js";
import GuideStandardCard from "components/GuidePage/GuideStandardCard.js";
import WithoutOneStatic from "components/GuidePage/GuideWithoutOne.js";
import GuideVideoCard from "components/GuidePage/GuideVideoCard.js";
import GuideExercise from "components/GuidePage/GuideExercise.js";
import GuideExample from "components/GuidePage/GuideExample.js";
import GuideExampleInteractive from "components/GuidePage/GuideExampleInteractive.js";
import GuideExampleInteractive_b from "components/GuidePage/GuideExampleInteractive_b.js";
import GuideRecap from "components/GuidePage/GuideRecap.js";
import Pagination from "components/Pagination/Pagination.js";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import StepZero from "components/GuidePage/StepZero.jsx";

import bottomPattern from 'assets/img/web_pattern_-03.png';
import guideIcon from 'assets/img/information.png';
import lastGuideIcon from 'assets/img/elements-28.png';
import fireCracker from 'assets/img/fire-cracker.svg';
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import { save_data } from "assets/jss/incept-sustainability-variables.jsx";
import { get_jsons, get_answers,standardRadious,isAuthenticated,urllabel } from "assets/jss/incept-sustainability-variables.jsx";

import activity from 'assets/img/activity.png';
import goalicon from 'assets/img/target-4.png';
import activitynodataicon from 'assets/img/activitynodataicon.png';
import exampleicon from 'assets/img/exampleicon.png';
import endmodule from 'assets/img/endmodule.png';



import jwt from "jwt-decode";
//import { Button } from "@storybook/react/demo";

import { LifeCycleProvider } from "components/GuidePage/LifeCycleProvider.js";

import { default as CustomModal } from "components/Modal/Modal.jsx";
import ActionButton from "components/CustomButtons/ActionButton";

//Translations
import { withTranslation, Trans } from 'react-i18next';
import i18n from "i18next";




/*Definition of styles*/
const style = {
	sectionManager: {
		paddingBottom: "50px",
	},
	visibleexer: {
		display: "none",
	},
	topPattern: {
		position: "absolute",
		width: "305px",
		right: "0",
		top: "0",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	margAround: {
		padding: "20px 50px",
		"@media only screen and (max-width: 960px)": {
			padding: "0 20px 0 10px",
		}
	},
	dotimage: {
		maxWidth: "64px",
		borderWidth: "0",
		padding: "0",
    margin: "0",
    display:"block",
		"@media only screen and (max-width: 960px)": {
			maxWidth: "40px",
			marginBottom: "0",
		}
	},
	dotstep: {
		position: "relative",
  		textAlign: "center"
	},
	dotimage2: {
		maxWidth: "64px",
		"@media only screen and (max-width: 960px)": {
			maxWidth: "40px",
		}
	},
	dotcentered: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	},
	hidemobile: {
		"@media only screen and (max-width: 960px)": {
			display: "none",
		}
	},
	roottime: {
		"@media only screen and (max-width: 960px)": {
			padding: "0 10px",
		}
	},
	roottop: {
		marginTop:"35px",
		"@media only screen and (max-width: 960px)": {
      //marginTop: "30px",
    }
  },
  marginmobile: {
    margin: "20px 0",
    "@media only screen and (max-width: 960px)": {
      margin: "10px 0 0 0"
    }
  },
  marginmobile2: {
    margin: "20px 0",
    "@media only screen and (max-width: 960px)": {
      margin: "0 0 10px 0"
    }
  },
  centermobile: {
    "@media only screen and (max-width: 960px)": {
      textAlign:"center"
    }
  },
  goalcard: {
    marginTop: "0", 
	backgroundColor: "#125a40",
	color: "white",
	border: "2px solid white",
	borderRadius: standardRadious,
    marginBottom: "0",
    "@media only screen and (max-width: 960px)": {
      marginBottom: "40px"
    }
  },
  iconFinish: {
	padding: "3px",
	height: "100%",
	width: "100%",
	maxWidth: "45px",
	maxHeight: "45px"
  },
};

/*
 * Description: this component renders all the sections of a page
 * a section is a box that can contain for example an exercise, a video or other info
 */
class GuideSectionManager extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		var redlog = false;
		var decodedtoken = "";
		if (!isAuthenticated()) {
			redlog = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem("token"));
		this.state = {
			manager: //Determines if it is a manager or a employee
				decodedtoken.role === "Manager" || decodedtoken.role === "Admin"
					? true
					: false,
			loading: false,
			loading2: true,
			savedata: [],
			savedata_isupdated: [],
			prevdata: [], //needed for module 2,
			step: 0,
			postid: -1,
			task: 0,
			guide: 0,
			isdashactive:false,
			redirect: false,
			completedn: 0,
			completed: 0,
			nideas: 0,
			userid: (decodedtoken!=="") ? decodedtoken.data.user.id:"",
			showLeaveModal: 0, //0 means show nothing, 1 means show and final, and 2 show but is not final
			showCelebrationEndModuleModal: false,
			showErrorModal: 0,
			scroll: 0,
			setScroll: 0,
			contentModalLeave: "",
			n_completed: 0,
			collaparr: [],
			itemsRef:[]
		};

		//this.itemsRef = [];

		if (redlog)
			this.props.isExpired();

		this._renderSections = this._renderSections.bind(this);
		this._saveresults = this._saveresults.bind(this);
		//this.setState({savedata: this.props.filledResponse});
	}


	//#region Next listed functions are implemented to save the results of exercises

	//Update when changing step
	//CAREFUL: Using any setState inside this life-cycle method will cause an infite loop.
	componentDidUpdate(prevProps, prevState) {
		//To avoid infinite loop, we ignore passing from "loading:true" to "loading:false" only
		if (prevState.loading && !this.state.loading) {
			return;
		}

		/*useEffect(() => {
			this.itemsRef.current = this.itemsRef.current.slice(0, 20);
		 });*/

		//Now it won't save exercises when changing step, submit button will do that
		/*const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged)
			this._updateresults(false, false, 0);*/
		//this.refs.child.savecomponentresult();
	};

	progressBarHandler = () => {

		const totalScroll = document.documentElement.scrollTop;
		const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
		const scrollaux = `${totalScroll / windowHeight}`;
		if (this._isMounted) {
			this.setState({scroll: scrollaux})
		}
	}

	getanswers() {
		//get answers
		if (!isAuthenticated()) {
			if (this._isMounted) {
				this.props.isExpired();
			}
		}
		const token = localStorage.getItem("token"); //token needed
		const authstr = "Bearer " + token;
		axios
			.get(get_answers + this.props.guidnam+"/answers", {
				headers: { Authorization: authstr },
			})
			.then((response) => {
				console.log(response);
				this.setState({ savedata: response.data, loading2: false });
				this.props.answersCount(response.data);
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loading2: false });
				//this.setState({ loading2: false });
				/*this.setState({ error: err, loading: false });
				this.setState({ loading: false });*/
			});
	};
	componentDidMount() {
		//get answers
		this._isMounted = true;
		this.getanswers();
		//For progress scrolling 
		window.addEventListener("scroll", this.progressBarHandler);
	};
	componentWillUnmount() {
		//Scroll
		this._isMounted = false;
		return () => window.removeEventListener("scroll", this.progressBarHandler);		
	};

	/**
	 * Save results in state variable when making a change in exercise
	 */
	_saveresultslocal = (type, results, index, exercise_id="-1") => {
		//var aux = [];
		if (this.state.manager) //do not save anything is is manager/analytics view
			return;

		let saveKey = "";
		if (results == null || (exercise_id==null && type==="ContentCard")) return;
		var aux2 = { type: type, content: results, exercise_id: exercise_id, step: this.props.stepn };
		this.step = this.props.stepn;
		this.task = index;
		this.guide = this.props.guidnam;
		this.postid = this.props.step.postid;
		this.recapPostid = this.props.RecapProps.postid;
		if (type === "Ideas") {
			saveKey = "cIdeas";
			aux2 = { type: type, 
				content: results.idea, 
				label: results.label,
				exercise_id: exercise_id, 
				step: this.props.stepn };
			this.state.savedata[saveKey] = aux2;
			this._updateresults(false, true, 0);
			this.props.answersCount(this.state.savedata);
		}
		else if (type === 'learnings') {
			saveKey = "clearnings";
			this.state.savedata[saveKey] = aux2;
			this.state.savedata_isupdated[saveKey] = true;
		}
		else {
			saveKey = exercise_id;
			this.state.savedata[saveKey] = aux2;
			this.state.savedata_isupdated[saveKey] = true;
			this._updateresults(false, false, 0);
			this.props.answersCount(this.state.savedata);
		}		
	};

	/*aux function*/
	clone(obj) {
		if (null == obj || "object" != typeof obj) return obj;
		var copy = obj.constructor();
		for (var attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
		}
		return copy;
	}

	/**
	 * _updateresults
	 * * Save exercise answer
	 * Redirect: if it goes to /guidespage
	 * isidea: if what is saved is an idea
	 * final: if the function was called from the "FINAL" modal (0,1)
	 */
	_updateresults(redirect, isidea, final) {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		if (this.state.manager) { //do not save anything is is manager/analytics view
			//this.setState({ redirect: redirect, loading: false });
			this.setState({ loading: false });
			return;
		}

		var sendstep = this.step;
		if (isidea) {
			this.setState({ nideas: this.state.nideas + 1 });
			//indexidea = this.task;
		}

		var takstosave = [];
		var arraytosend = this.clone(this.state.savedata);

		for (var key in arraytosend) {
			//Check if exercise was updated and therefore, we will save it
			if (this.state.savedata_isupdated[key]) {
				if (arraytosend[key].type !== "learnings")
					takstosave.push(key); // => "1");
				else
					takstosave.push("Learnings"); // => "1");
				this.state.savedata_isupdated[key] = false;
			}
			else if (key === "cIdeas") {
				takstosave.push("Ideas");
			}
			//Change the format of array when is drag and drop, so it can be
			// saved in the backend
			if (arraytosend[key].type === "DragDropTwo_st") {
				let listToSaveLft = [];
				let listToSaveRght = [];
				for (var key2 in arraytosend[key].content) {
					arraytosend[key].content[key2].forEach(function (options) {
						if (options.dropListIndex === 0)
							listToSaveLft.push(options);
						else if (options.dropListIndex === 1)
							listToSaveRght.push(options);
					})
				}
				arraytosend[key].content2 = arraytosend[key].content;
				arraytosend[key].content = [...listToSaveLft, "", ...listToSaveRght];
				arraytosend[key].type = "DragDropTwo";
			}
			else if (arraytosend[key].type === "DragDropTwo" || arraytosend[key].type ==="DragDropTwo_ranking") {
				arraytosend[key].content2 = arraytosend[key].content;
			}
		}

		console.log("TESTING:");
		console.log(arraytosend);
		if (takstosave.length===0 && !redirect) {
			return;
		}

		//data to send
		const user_id = this.state.userid; //userid
		const insdata = {
			userid: user_id,
			tosave: JSON.stringify({ ...arraytosend }),
			guide: this.guide,
			step: sendstep,
			postid: this.postid,
			task: "",
			exercise_id: this.state.exercise_id,
			ntasks: takstosave,
			recappostid: this.recapPostid,
			leavingModule: final,
			ifn: (isidea) ? (this.task + this.state.nideas) : 0,
		};

		if (isidea) {
			delete this.state.savedata["cIdeas"];
		}

		const token = localStorage.getItem("token"); //token needed
		const authstr = "Bearer " + token;
		//post
		this.setState({ loading: true }, () => {
			axios
				.post(save_data+this.props.guidnam+"/answers", insdata, { headers: { Authorization: authstr } })
				.then((res) => {
					console.log("Result: 1");
					console.log(res);
					//this.setState({ redirect: redirect, loading: false });
					this.setState({loading: false });
				})
				.catch((err) => {
					console.log("Result: error ");
					console.log(err);
					this.setState({ loading: false,showErrorModal: 1 });
					/*if (isidea)
					  delete this.state.savedata["c" + stepidea + indexidea];*/
				});
		});
	}

	/*
	 * Save results for when the user leaves the guide
	 * Used only when user clicks "Finish"
	 */
	_saveresults(type) {
		this.guide = this.props.guidnam;
		this._updateresults(true, false, type);
	}

	//#endregion End of functions for saving results of exercises

	handleShowLeaveModal = (final = 2) => { // If it is 1, means has finished module. 2 means is other case.
		this.setState({ isdashactive: true });

		//Here I have to add the content of how many activities are missing
		//Compare total exercises with exercises answered 
		if (this.props.totalexercises!==this.props.totalexercises_ans) {
			var n_completed_aux = Math.floor((100*this.props.totalexercises_ans)/this.props.totalexercises);
			this.setState({n_completed: n_completed_aux});
		}
		this.setState({ showLeaveModal: final });
	};

	handleHideLeaveErrorModal = () => {
		this.setState({ showErrorModal: 0 });
		
	};

	handleHideLeaveModal = (leavingModule = false) => {
		this.setState({ isdashactive: false });
		if (leavingModule) {
			//let realType = this.state.showLeaveModal === 1 ? 1 : 0;
			this._saveresults(1);
			this.setState({ isdashactive: true });
			this.setState({ redirect: true });
		}		
		this.setState({ showLeaveModal: 0 });
		
	};

	handleShowCelebrationEndModuleModal = () => {
		this._saveresults(1);
		//Count Learnings when clicking finished button
		this.props.answersCount(this.state.savedata);
		this.setState({ showCelebrationEndModuleModal: true });
	}

	handleHideCelebrationEndModuleModal = () => {
		this.setState({ showCelebrationEndModuleModal: false });
	}

	/** When user clicks on End in celebration modal
	 * and should be redirected and save their
	*/
	handleClickEndCelebrationEndModuleModal = () => {
		this.handleHideCelebrationEndModuleModal();
		//Should save learnings
		this.setState({ redirect: 1 });
	}

	/** When user clicks on Cancel in celebration modal
	 * And should stay in the guide
	 */
	handleClickCancelCelebrationEndModuleModal = () => {
		this.handleHideCelebrationEndModuleModal();
	}

	/*
   * Goes through the sections in the json and calls _renderSectionType
   * to render them
   */
	_renderSections(classes, { sections }, step) {
		/*if ()
		let imground = */
		const sidebaricon = [guideIcon,activity,activitynodataicon,exampleicon];
		

		const MyTimelineSeparator = ({ isLastSection, type,goal, indextime, isfirstsection, onClickf }) => {
			let imground = sidebaricon[0];
			if (type==="exercise" && goal!==-1)
				imground =sidebaricon[1];
			else if (type==="exercise")
				imground =sidebaricon[2];
			else if (type==="example" || type==="interactive_example" || type==="interactive_example_b")
				imground =sidebaricon[3];
			/*if (isLastSection) {
				return (
					<TimelineSeparator className={classes.roottop}>
						<TimelineDot className={classes.dotimage}>
							<img
								src={imground}
								className={classes.dotimage2}
								alt='dot icon guide'
							/>
						</TimelineDot >
						<hr style={{ height: "100%", border: "1px dotted #ff8855", borderWidth: "0 6px 0 0", position: "relative", zIndex: "-1", margin: "0" }} />
						<TimelineDot className={classes.dotimage}>
							<img
								src={lastGuideIcon}
								className={classes.dotimage2}
								alt='dot icon guide'
							/>
						</TimelineDot>
					</TimelineSeparator>
				)
			}*/
			return (
				<TimelineSeparator className={classes.roottop}>
					<TimelineDot className={classes.dotimage} onClick={onClickf} style={{cursor:"pointer"}}>
						{!isfirstsection && <img
							src={imground}
							className={classes.dotimage2}
							alt='dot icon guide'
						/>}
						{isfirstsection && <div className={classes.dotstep} ><img
								src={lastGuideIcon}
								className={classes.dotimage2+" "+classes.dotimagestep}
								alt='dot icon guide'
								/><h2 className={classes.dotcentered}>{step.stepn}</h2>
								</div>}
					</TimelineDot>
					{(!isLastSection) && 
					<hr style={{ height: "100%", border: "1px dotted #ff8855", 
					borderWidth: "0 6px 0 0", position: "absolute", zIndex: "-1" }} />}
				</TimelineSeparator>
			)
		}

		return (
			<Timeline>
				{sections.map((obj, index) => {
					return (
					<TimelineItem key={index}>
						<MyTimelineSeparator type={obj.type} goal={obj.goal!==undefined ? obj.goal:-1} 
							isLastSection={sections.length - 1 === index ? true : false}
							isfirstsection={index===0}
							indextime={index}
						/>
						<TimelineContent classes={{ root: classes.roottime }}>
							<div 
								key={index} 
								style={{scrollMargin:"50px"}}
								ref={el => {this.state.itemsRef[index] = el; return true;}} >
							</div>
							{this._renderSectionType(classes, obj, index,step.stepn,
							(sections.length - 1 === index ? true : false)
							,this.props.readContent[step.stepn-1][index-1])}
						</TimelineContent>
					</TimelineItem>
					);
				})}
			</Timeline>
		)
	}

	handleCard(index,type,id,step) {
		if (type==="standard" || type==="example" || type==="interactive_example" || type==="interactive_example_b") {
			this._saveresultslocal("ContentCard", 1, index, id);
		}
		this.props.openedSection(index,step);
		/*this.setState((state) => {
			const sa = state.collaparr;
			sa[index] = !sa[index];
			return { collaparr: sa };
		}, () => {
			//if (this.itemsRef[index] !== undefined && toOpen)
			//	this.itemsRef[index].scrollIntoView({behavior: "smooth"});
		});*/
		
	}
 
	renderRedirect = () => {
	if (this.state.redirect) {
		return <Redirect to="/admin/guides" />;
	}

	};

	_goNextSection = (currentSection,step,type,id) => {
		let toindex = parseInt(currentSection)+1;
		if (this.state.itemsRef[toindex] !== undefined){
			this.props.openedSection(toindex,step,true,this.state.itemsRef[toindex]);						
		}
	}
 
  /*
   * Checks what type of section is and renders de corresponding component
   */
  _renderSectionType(classes, obj, index, stepn,isLastSection,show) {
	const { type } = obj;
    switch (type) {
      case "standard":
		let type = obj.type!==undefined ? obj.subtype:"";
		let images = obj.images!==undefined ? obj.images:"";
		let isitrich2 = (obj.subtype==="content_carousel" || obj.isitrich) ? true:false;
        return (
          <GuideStandardCard 
		  title={obj.title} 
		  key={index+stepn} 
		  id={index+stepn}
		  defaultView={obj.defaultView}
		  isLastSection={isLastSection}
		  type={type}
		  images={images}
		  textContent={ReactHtmlParser(ReactHtmlParser(obj.body))}
		  videoContent={ReactHtmlParser(ReactHtmlParser(obj.bodyVideo))}
		  goNextSection={() => this._goNextSection(index,stepn,type,obj.id)}
		  openSection={() => this.handleCard(index,type,obj.id,stepn)}
		  showcard={show}
		  stepn={stepn}
		  smoothtrans={this.state.itemsRef[index]}
		  datasav={this.state.savedata[obj.id]}
		  saveresloc={() => this._saveresultslocal("ContentCard", 1, index, obj.id)}
		  isitrich={isitrich2}>
          </GuideStandardCard>
        );
      case "video":
        return (
          <GuideVideoCard
            key={index}
            title={obj.title}
            videoURL={obj.videoURL}
          />
        );
      case "example":
        return (
          <GuideExample
		  key={index+stepn} 
		  id={index+stepn}
		  goNextSection={() => this._goNextSection(index,stepn,type,obj.id)}
		  openSection={() => this.handleCard(index,type,obj.id,stepn)}
		  title={obj.title}
		  isLastSection={isLastSection}
		  defaultView={obj.defaultView}
		  smoothtrans={this.state.itemsRef[index]}
		  isContent={obj.isContent}
		  textContent={ReactHtmlParser(ReactHtmlParser(obj.body))}
		  showcard={show}
		  datasav={this.state.savedata[obj.id]}
		  saveresloc={() => this._saveresultslocal("ContentCard", 1, index, obj.id)}
		  videoContent={ReactHtmlParser(ReactHtmlParser(obj.bodyVideo))}
		  >
          </GuideExample>
        );
	   case "interactive_example":
			return (
			  <GuideExampleInteractive 
			  key={index+stepn} 
			  id={index+stepn}
			  title={obj.title}
			  isLastSection={isLastSection}
			  textContent={ReactHtmlParser(ReactHtmlParser(obj.body))}
			  videoContent={(obj.bodyVideo !== undefined) ? ReactHtmlParser(ReactHtmlParser(obj.bodyVideo)):""}
			  defaultView={obj.defaultView}
			  isContent={(obj.isContent!== undefined) ? obj.isContent:false}
			  defaultFirst={obj.firstboxdefault}
			  goNextSection={() => this._goNextSection(index,stepn,type,obj.id)}
			  openSection={() => this.handleCard(index,type,obj.id,stepn)}
			  showcard={show}
			  smoothtrans={this.state.itemsRef[index]}
			  datasav={this.state.savedata[obj.id]}
			  saveresloc={() => this._saveresultslocal("ContentCard", 1, index, obj.id)}
			  boxes={obj.boxes}
			  >
			  </GuideExampleInteractive>
			);
	  case "interactive_example_b":
				return (
				  <GuideExampleInteractive_b 
				  key={index+stepn} 
				  id={index+stepn}
				  title={obj.title}
				  isLastSection={isLastSection}
				  textContent={ReactHtmlParser(ReactHtmlParser(obj.body))}
				  videoContent={(obj.bodyVideo !== undefined) ? ReactHtmlParser(ReactHtmlParser(obj.bodyVideo)):""}
				  defaultView={obj.defaultView}
				  isContent={(obj.isContent!== undefined) ? obj.isContent:false}
				  inColumns={obj.incolumns}
				  goNextSection={() => this._goNextSection(index,stepn,type,obj.id)}
				  openSection={() => this.handleCard(index,type,obj.id,stepn)}
				  boxes={obj.boxes}
				  showcard={show}
				  smoothtrans={this.state.itemsRef[index]}
				  datasav={this.state.savedata[obj.id]}
				  saveresloc={() => this._saveresultslocal("ContentCard", 1, index, obj.id)}
				  colorBox={obj.color}
				  >
				  </GuideExampleInteractive_b>
				);
      case "exercise":
		let color = (obj.background_color!==undefined && obj.background_color!=="") ? {backgroundColor:"#"+obj.background_color}:{};
        return (
          <GuideExercise
            //ref="child" //this was giving problem with translation
            key={index}
            title={obj.title}
            exercise={obj.exercise}
			background_color={obj.background_color}
            id={obj.id}
            type={obj.exercise.type}
			isFeedback={obj.goal != null}
            subtype={obj.exercise.subtype}
            saveresloc={this._saveresultslocal}
            indexk={index}
			stepn={stepn}
			goNextSection={() => this._goNextSection(index,stepn,type,obj.id)}
			openSection={() => this.handleCard(index,type,obj.id,stepn)}
			isLastSection={isLastSection}
            datasav={this.state.savedata[obj.id]}
			showcard={show}
			smoothtrans={this.state.itemsRef[index]}
            alldata={this.state.savedata}
          >
			  <Grid container spacing={3}>
              <Grid item xs={12} md={obj.goal != null ? 6 : 12}>
                {ReactHtmlParser(ReactHtmlParser(obj.body))}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className={obj.goal != null ? "" : classes.visibleexer}
              >
				  <Card className={classes.goalcard} style={color}>
                  <Container style={{ padding: "0 15px" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={2} className={classes.centermobile}>
                        <img
						  alt=""
                          src={goalicon}
                          width="60"
                          className={classes.marginmobile}
                        />
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <div id="ideabox" className={classes.marginmobile2}>
                          {obj.goal}
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                 </Card>
              </Grid>
			  </Grid>
          </GuideExercise>
        );
      case "withoutonetext":
        return (
          <WithoutOneStatic
            dimensions={obj.dimensions}
            body={obj.body}
            alldata={this.state.savedata}
          >
            {ReactHtmlParser(ReactHtmlParser(obj.body))}
          </WithoutOneStatic>
        );
      default:
        return "";
    }
  }
 
  /*Renders a page of the module*/
  render() {
    const {
      backNavPath,
      stepZero = {},
      step,
      classes,
      stepn,
      numPages,

      RecapProps,

	  titleModule,
	  labelsIdeas,
	  t
    } = this.props;
    const { title, postid, sections } = step;
	  const { title : szTitle, introduction : szIntro, img : szImg, learnings : szLearnings, 
		  feedbacks : szFeedbacks, steps : szSteps, startLink} = stepZero;
	  const { showRecap, onHideRecap, contentWarning, showRecapModal, handleHideLeaveModalWarning, showWarning, ...otherRecapProps} = RecapProps;
	  
    const guideNumber = titleModule; //5 will extract after "guide" word, example: guide1 -> 1
    if (!this.state.loading2) {
      return (
        <div className={classes.sectionManager}>
          {this.renderRedirect()}
        <LifeCycleProvider>
            <GuideRecap 
              saveresloc={this._saveresultslocal}
              indexk={0}
              datasav={this.state.savedata["clearnings"]}
              showModal={showRecap} 
              onHide={onHideRecap}
			  labels={labelsIdeas}
              onFinishClick={() => {onHideRecap();this.handleShowCelebrationEndModuleModal();}}
              {...otherRecapProps} />

            <GuideTopContainer
              saveres={() => this.handleShowLeaveModal()}
              saveresloc={this._saveresultslocal}
			  isdashactive={this.state.isdashactive}
              indexk={0}
              backNavPath={backNavPath}
              title={title}
			  scroll={this.state.scroll}
			  labels={labelsIdeas}
            />
			
            <div style={{position:"relative",paddingTop:"40px"}}>

            {/*<img
                src={topPatternd}
                alt="top corner web pattern"
                className={classes.topPattern}
			/>*/}
              <div className={classes.margAround}>
			  	{stepn === '0' 
                  ? <StepZero 
                      title={szTitle}
					  imageModule={szImg}
                      introduction={szIntro}
                      learnings={szLearnings}
                      feedbacks={szFeedbacks}
                      steps={szSteps}
                      startLink={startLink}
                    />
                  : this._renderSections(classes, { sections }, { stepn }, {postid})
                }
				{/*If last page, show end module button*/}
				{parseInt(stepn)===parseInt(numPages) && <div style={{textAlign:"center"}}>
					<ActionButton size="large" centered onClick={showRecapModal}>
						{t('End Module')}
						<img
							src={endmodule}
							title="End module"
							alt="end module"
							className={classes.iconFinish}
						/>
					</ActionButton>					
					</div>	}			
              </div>
            </div>
          </LifeCycleProvider>
		  <CustomModal
            show={this.state.showErrorModal===1}
            onHide={this.handleHideLeaveErrorModal}
          >
            <h5 style={{textAlign:"center", marginTop:'50px',marginBottom:'50px'}}></h5>
			<div>
				<h2>Well, this was unexpected. </h2>
				<p>
					Sorry, something is not working properly. We now know about
					it and are working to fix it.
				</p>
				<p>In the meantime, here is what it can be done:
					<ul>
						<li>Refresh the page (sometimes this helps).</li>
						<li>Make sure you have internet connection.</li>
						<li>Try again in 30 minutes.</li>
						<li>Email us at inceptsustainability@ramboll.com and tell us what happened.</li>
					</ul>
				</p>
			</div>
          </CustomModal>
          <CustomModal
            show={this.state.showLeaveModal===1 || this.state.showLeaveModal ===2}
            onHide={this.handleHideLeaveModal}
          >
            <h5 style={{textAlign:"center", marginTop:'50px',marginBottom:'50px'}}>
				{t('Are you sure you want to leave?')}</h5>
			{(this.props.totalexercises!==this.props.totalexercises_ans) && <p style={{textAlign:"center"}}>
				<Trans
					i18nKey="leavingDashboard" // optional -> fallbacks to defaults if not provided
					defaults="So far you have completed {{n_completed}}% of the module. You are missing activities in step(s): 
					{{missingExercises}}.
					If you chose to leave, your current progress is saved for next time." // optional defaultValue -> <0> represents the index in "components" array which makes it easy
					values={{ n_completed:this.state.n_completed,missingExercises:this.props.missingExercises}} // useful when you need to add dynamic data in form of variables and not components
				/>
			</p>}
            <Grid container spacing={2}>
              <Grid item sm style={{textAlign:"right"}}>
                <ActionButton size="large" onClick={() => this.handleHideLeaveModal(true)}>{t('Yes')}</ActionButton>
              </Grid>
              <Grid item sm>
                <ActionButton size="large" lighten onClick={() => this.handleHideLeaveModal()}>{t('Cancel')}</ActionButton>
              </Grid>
            </Grid>
          </CustomModal>
          <CustomModal
            show={this.state.showCelebrationEndModuleModal}
            onHide={this.handleHideCelebrationEndModuleModal}
          >
            <img alt="" src={fireCracker} width={100} style={{margin:'0 auto'}}/>
            <h1 style={{textAlign:"center", marginTop:'50px'}}>{t('Congratulations!')}</h1>
            <h5 style={{textAlign:"center", marginBottom:'50px'}}>{t('You have finished module number x', {guideNumber:guideNumber, defaultValue:"You have finished module {{guideNumber}}"})}</h5>
            <Grid container spacing={2}>
              <Grid item sm style={{textAlign:"right"}}>
                <ActionButton size="large" onClick={this.handleClickEndCelebrationEndModuleModal}>{t('End')}</ActionButton>
              </Grid>
              <Grid item sm>
                <ActionButton size="large" lighten onClick={this.handleClickCancelCelebrationEndModuleModal}>{t('Cancel')}</ActionButton>
              </Grid>
            </Grid>
          </CustomModal>
		  <CustomModal
            show={showWarning}
            onHide={handleHideLeaveModalWarning}
          >
			<p style={{textAlign:"center"}}>
				{(contentWarning!=="") && <p style={{textAlign:"center"}}>
					<Trans
						i18nKey="goingToWrapUp" // optional -> fallbacks to defaults if not provided
						defaults="You are missing activities in step(s): {{contentWarningtext}}. The Wrap-Up will be available when you have finished these activities." // optional defaultValue -> <0> represents the index in "components" array which makes it easy
						values={{ contentWarningtext:contentWarning}} // useful when you need to add dynamic data in form of variables and not components
					/>
				</p>}
			</p>
            <Grid container spacing={2} style={{textAlign:"center"}}>
              <Grid item sm>
                <ActionButton size="large" onClick={handleHideLeaveModalWarning}>{t('Back to Module')}</ActionButton>
              </Grid>
            </Grid>
          </CustomModal>
          <CustomModal
            show={this.state.showCelebrationEndModuleModal}
            onHide={this.handleHideCelebrationEndModuleModal}
          >
            <img alt="" src={fireCracker} width={100} style={{margin:'0 auto'}}/>
            <h1 style={{textAlign:"center", marginTop:'50px'}}>{t('Congratulations!')}</h1>
            <h5 style={{textAlign:"center", marginBottom:'50px'}}>{t('You have finished module number x', {guideNumber:guideNumber, defaultValue:"You have finished module {{guideNumber}}"})}</h5>
            <Grid container spacing={2}>
              <Grid item sm style={{textAlign:"right"}}>
                <ActionButton size="large" onClick={this.handleClickEndCelebrationEndModuleModal}>{t('End')}</ActionButton>
              </Grid>
              <Grid item sm>
                <ActionButton size="large" lighten onClick={this.handleHideCelebrationEndModuleModal}>{t('Cancel')}</ActionButton>
              </Grid>
            </Grid>
          </CustomModal>
        </div>
      );
    }
    else {
      return(<div></div>)
    }
  }
}

/*
 * Description: this component renders renders
 * the whole module/guide and the pagination
 */

class Guide extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		var decodedtoken = "";
	    var redi = false;
		if (!isAuthenticated()) {
			redi = true;
		}
		else 
        	decodedtoken = jwt(localStorage.getItem( 'token' ));
		this.state = {
			module: null,
			loading: true,
			userid: (decodedtoken!=="") ? decodedtoken.data.user.id:"",
			showRecap: false,
			error: false,
			responsearrived: false,
			redirect: redi,
			n_exercises_step: [],
			total_exercises_answered: 0,
			total_exercises_answered_nowrapup: 0,
			missing_exercises: "",
			contentWarning: "",
			showWarning: false,
			sectionsRead:[]
		};
	}

	showRecapModal = () => {
		//Check if exercises are completed before showing wrap up (recap)
		let totalActivities = parseInt(this.state.module.n_exercises_all);
		if (totalActivities!==this.state.total_exercises_answered_nowrapup) {
			let auxString = this.state.missing_exercises.replace('and the Wrap-up','');
			auxString = auxString.replace('the Wrap-up','');
			/*this.setState({contentWarning: 
				"You are missing activities in step(s): "+auxString+".\n"
				+"The Wrap-Up will be available when you have finished these activities."});*/
			this.setState({ contentWarning: auxString });
			this.setState({ showWarning: true });
		}
		else {
			this.setState({ showRecap: true });
		}
	}

	hideWarning = () => {
		this.setState({ showWarning: false });
	}

	handleChange_answers = (array_answers) => {
		let lengtharray = 0;
		let lengtharray_nowrapup = 0;
		let nsteps = this.state.module.steps.length+1;
		let answers_per_step = Array(nsteps).fill(0);
		for (var key in array_answers) {
			//if (key === "cIdeas" || key === "clearnings")
			if (key === "cIdeas")
				continue;
			if (array_answers[key].type === "ContentCard")
				continue;
			if (key === "clearnings") {
				//answers_per_step[nsteps-1] = 1;
				//lengtharray += 1;
				continue;
			}		
			let step = array_answers[key].step;
			answers_per_step[step-1] += 1;
			lengtharray += 1;
			lengtharray_nowrapup += 1;
		}
		let steps= this.state.module.steps;
		let missing_activities_aux = [];
		let missing_string = "";
		steps.map((value, index) => {
			if (answers_per_step[index]!==value.n_exercises) {
				missing_activities_aux.push(index+1); 
			}
		});
		if (answers_per_step[answers_per_step.length-1]!==1)
			missing_activities_aux.push("the Wrap-up"); 
		for (var i=0;i<missing_activities_aux.length;i++) {
			if (i==0)
				missing_string += missing_activities_aux[i];
			else if (missing_activities_aux.length>1 && i==missing_activities_aux.length-1)
				missing_string += " and "+missing_activities_aux[i];
			else	
				missing_string += ", "+missing_activities_aux[i];
		}
		this.setState({n_exercises_step:answers_per_step,total_exercises_answered:lengtharray,
			total_exercises_answered_nowrapup:lengtharray_nowrapup, missing_exercises: missing_string});
	};

	updateOpenSection = (index,step,opensect=false,itemsRef=[]) => {
		this.setState((state) => {
			const sa = state.sectionsRead;
			if ((opensect && !this.state.sectionsRead[step-1][index-1]) || !opensect)
				sa[step-1][index-1] = !sa[step-1][index-1];
			return { sectionsRead: sa };
		}, () => {
			if (opensect && this.state.sectionsRead[step-1][index-1]) {
				//itemsRef.scrollIntoView({behavior: "smooth"});
			}
		});
	};

	isexpired = () => {
		this.setState({
			redirect: true,
		});
	}
	/*logout*/
	renderRedirectLogin = () => {
		if (this.state.redirect) {
			return <Redirect to="/login" />;
		}
	};

	getjsons() {
		//get_jsons
		if (!isAuthenticated()) {
			if (this._isMounted) {
				this.setState({
					redirect: true,
				});
			}
			return;
		}
		
		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		axios
			.get(get_jsons + this.props.match.params.guideName+ "?wpml_language="+ useLang, {
				headers: { Authorization: authstr },
			})
			.then((response) => {
				if (response.data==="")
					this.setState({error:true});
				this.setState(
					(prevState) => {
						const newModule = JSON.parse(response.data);
						const recap = newModule.steps.pop()['sections'][0];
						let aux = { ...newModule, recap: { ...recap } };
						const { pageNum } = this.props.match.params;
						let sectionsRead1 = Array(aux.steps.length).fill([]);
						//go throgh each section 
						for (let i=0;i<aux.steps.length;i++) {							
							sectionsRead1[i] = Array(aux.steps[i].sections.length).fill(false);
						}
						return {
							module: { ...newModule, recap: { ...recap } },
							loading: false,
							sectionsRead: sectionsRead1
						}
					});
			})
			.catch((err) => {
				this.setState({error:true});
				if (err.response!==undefined)
					console.log(err.response.data.message);
				console.log("Error:");
				console.log(err);
			});
	}
	componentDidMount() {
		//get_jsons
		this._isMounted = true;
		this.getjsons();

	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	renderRedirect = () => {
		if (this.state.error) {
		  return <Redirect to="/admin/guides" />;
		}
	 
	};

	/*
	 * Handles the rendering of the whole module/guide and pagination
	 */
	render() {
		const { match, classes } = this.props;
		const { guideName, pageNum } = match.params;

		const guideInfo = this.state.module;

		

		const sectionManagerExtraProps = {};

		if (!this.state.loading && !this.state.error) {
			let stepPagination = guideInfo.steps.slice(0);
			stepPagination.push({title:"Wrap-up",n_exercises:1});
			//let totalActivities = parseInt(guideInfo.n_exercises_all)+1;
			let totalActivities = parseInt(guideInfo.n_exercises_all);

			//let sectionsRead = Array(guideInfo.steps[pageNum > 0 ? pageNum - 1 : 0].sections.length).fill(false),
			if (pageNum < 1) {
				//Use this to prepare information needed by StepZero component
				//We need steps titles in array
				guideInfo.stepZero['steps'] = [];
				guideInfo.stepZero['startLink'] = `/guides/${guideName}/1`;
				guideInfo.stepZero.steps = guideInfo.steps.map(({ title }, index) => title);
				sectionManagerExtraProps['stepZero'] = guideInfo.stepZero;
			}
			return (
				<div>
					{this.renderRedirectLogin()}
					<ScrollToTop />
					<GuideSectionManagerWithRouter
						classes={classes}
						labelsIdeas={guideInfo.stepZero.labelIdeas}
						titleModule={guideInfo.stepZero.title}
						isExpired={this.isexpired}
						backNavPath={`/guides/${guideName}`}
						step={guideInfo.steps[pageNum > 0 ? pageNum - 1 : 0]}
						readContent={this.state.sectionsRead}
						openedSection={this.updateOpenSection}
						stepn={pageNum}
						guidnam={guideName}
						totalexercises={totalActivities}
						totalexercises_ans={this.state.total_exercises_answered}
						missingExercises={this.state.missing_exercises}
						numPages={guideInfo.steps.length}
						basePath={`/guides/${guideName}`}
						{...sectionManagerExtraProps}
						answersCount={this.handleChange_answers}
						RecapProps={
							{
								...this.state.module.recap,
								showRecap: this.state.showRecap,
								handleHideLeaveModalWarning: this.hideWarning,
								contentWarning: this.state.contentWarning,
								showWarning: this.state.showWarning,
								onHideRecap: () => this.setState({ showRecap: false }),
								showRecapModal: this.showRecapModal,
							}
						}
					/>
					{/*<div style={{
						position: 'fixed',
						height: '150px',
						bottom: '28px',
						width: "100%",
						textAlign:"center",
						maxWidth: "1760px"
					}}>
						<img
							src={bottomPattern}
							alt='bottom pattern'
							style={{
								position: 'relative',
								width: '980px',
							}}
						/>
						</div>*/}

					<Pagination
						totalexercises={totalActivities}
						totalexercises_ans={this.state.total_exercises_answered}
						exercises={this.state.n_exercises_step}
						steps={stepPagination}
						numPages={guideInfo.steps.length}
						basePath={`/guides/${guideName}`}
						currentPage={pageNum}
						showRecapModal={this.showRecapModal}
						onAfterFinish={() => this.setState({ showRecap: true })}
					/>
				</div>
			);
		} else {
			return (
			<LoadingOverlay
					active={this.state.loading}
					spinner={<BounceLoader />}
				>
					<div>{this.renderRedirectLogin()}{this.renderRedirect()}</div>
				</LoadingOverlay>
			)
		}
	}
}
const GuideSectionManagerWithRouter = withRouter(withTranslation()(GuideSectionManager));
export default withTranslation()(withStyles(style)(Guide));

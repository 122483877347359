import React from "react";

import axios from "axios";

import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import clientConfig from "../client-config";
import withStyles from "@material-ui/core/styles/withStyles";
import loginStyle from "assets/jss/material-dashboard-react/views/loginStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import { Redirect } from "react-router-dom";

import { Form, FormGroup, Label } from "reactstrap";
import { default as CustomModal } from "components/Modal/Modal.jsx";
import jwt from "jwt-decode";

import loadinggif from "assets/img/loading.gif";
import login_pattern_top from "assets/img/pattern01.png";
import login_pattern_bottom from "assets/img/login_pattern-02.png";
import incept_logo from "assets/img/incept_logo.png";
import { ourDarkGreen, ourBlue, ourBlue60, reset_password } from 'assets/jss/incept-sustainability-variables.jsx';
import ActionButton from "components/CustomButtons/ActionButton.jsx";

const pStyle = {

	input: {
		borderRadius: "2rem",
		height: "3rem",
		border: "1px solid gray",
		marginBottom: "1rem",
	},
	button: {
		width: "70%",
		height: "50px",
		backgroundColor: ourBlue,
		color: "white",
		border: "1px",
		borderRadius: "20px",
		cursor: "pointer",
		fontSize: "15px",
		padding: "2px 8px",
		display: "block",
		position: "inherit",
		margin: "auto",
		"&:hover": {
			backgroundColor: ourBlue60,
		},
	},
	linkPointer: {
		cursor: "pointer"
	}
};

class Login extends React.Component {
	constructor(props) {
		super(props);
		let params = props.match.params;
		this.state = {
			username: "",
			password: "",
			userEmail: "",
			loggedInM: false,
			loggedInE: false,
			loggedin: false,
			loading: false,
			email: "",
			forgotpassword: false,
			role: "",
			missEmail: false,
			sendingmail: false,
			afterMailError: false,
			afterMail: false,
			afterMailMessage: "",
			error: "",
			invalidkey: params.invalidkey
		};

		if (localStorage.getItem("token") !== null) {
			this.state.loggedin = true;
			//this.checkuser(decodedtoken.data.user.id,localStorage.getItem( 'token' ));
		}

		this.forgotPassowordCloseModal = this.forgotPassowordCloseModal.bind(this);
	}

	/*async checkuser(userid, token2) {
		  const usrurl = user_general_info;
		  const authstr = 'Bearer '+token2;
		  axios.get(usrurl+userid, { headers: { Authorization: authstr } })
		   .then(response => {
			   // If request is good...
			   this.setState({role: response.data.role})
			   localStorage.setItem( 'role', response.data.role );
			   localStorage.setItem( 'company', response.data.compname );
			   this.setState({loggedin: true});
			   /*if (response.data.role == "Manager")
				  this.setState({loggedInM: true});
			   else
				  this.setState({loggedInE: true});*/
	//console.log(response.data.role);
	//document.getElementById("loadinggd").style.display = "none";
	/* })
		   .catch((error) => {
			   console.log('Error ' + error);
			   document.getElementById("errorlogin").style.display = "block";
			   document.getElementById("loadinggd").style.display = "none";
			});
	  }*/

	handleOnChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		document.getElementById("errorlogin").style.display = "none";
	};

	clickenter = (event) => {
		if (event.keyCode === 13) {
			this.handleClick(event);
		}
	};

	handleClick = (event) => {
		const siteUrl = clientConfig.siteUrl;

		const loginData = {
			username: this.state.username,
			password: this.state.password,
		};

		//setState(updater[, callback])
		this.setState({ loading: true }, () => {
			document.getElementById("loadinggd").style.display = "block";
			axios
				.post(`${siteUrl}wp-json/jwt-auth/v1/token`, loginData)
				.then((res) => {
					if (undefined === res.data.token) {
						this.setState({ error: res.data.message, loading: false });
						return;
					}
					//get info from api
					const { token, user_nicename, user_email } = res.data;

					localStorage.setItem("token", token);

					//localStorage.setItem("userName", user_nicename);

					//save info
					this.setState({
						loading: false,
						token: token,
						userEmail: user_email,
					});

					const decodedtoken = jwt(token);
					this.setState({ role: decodedtoken.role });
					this.setState({ loggedin: true });

					/*const info = jwt(token); 
		  
							  this.checkuser(info.data.user.id,token);
							  localStorage.setItem( 'userid', info.data.user.id );*/
				})
				.catch((err) => {
					console.log("There is an error");
					console.log(err);
					document.getElementById("errorlogin").style.display = "block";
					document.getElementById("loadinggd").style.display = "none";

					//this.setState( { error: err.response.data.message, loading: false } );
				});
		});
	};

	sendemail_password() {
		const insdata = {
			email: this.state.email
		};
		//post
		this.setState({ loading: true }, () => {
			axios
			.post(reset_password, insdata)
			.then((response) => {
				this.setState({afterMail: true, afterMailMessage: "Email sent!", sendingmail: false, 
					afterMailError: false, invalidkey: ""});
				document.getElementById("loadinggd2").style.display = "none";
			})
			.catch((err) => {
				if (err.response!==undefined)
					this.setState({afterMail: true, afterMailMessage: err.response.data.message,sendingmail: false, 
						afterMailError:true, invalidkey: ""});
				else 
					this.setState({afterMail: true, afterMailMessage: "Error",sendingmail: false, afterMailError:true, invalidkey: ""});
				document.getElementById("loadinggd2").style.display = "none";	
			});
		});
	}

	resetPasswordEmail = (event) => {
		this.setState({ email: event.target.value,missEmail: false, afterMail: false});
	};

	forgotPassowordModal() {
		this.setState({ forgotpassword: true });
	}

	forgotPassowordCloseModal() {
		if (this.state.sendingmail)
			return;
		this.setState({ forgotpassword: false,afterMail: false,email: "" });
	}

	resetPassword(e) {
		//Check if mail is empty
		if (this.state.email==="") {
			this.setState({ missEmail: true });	
			return;
		}	
		document.getElementById("loadinggd2").style.display = "block";	
		this.setState({ sendingmail: true });
		this.sendemail_password();
	}

	render() {
		const { classes } = this.props;
		const {
			username,
			password,
			email,
			loggedin,
		} = this.state;
		
			/*if ( loggedInM || localStorage.getItem( 'role' )=="Manager" ) {
				  return ( <Redirect to={`/Isjdg/`} noThrow /> )
			  } 
			  else if ( loggedInM || localStorage.getItem( 'role' )=="Admin" ) {
				  return ( <Redirect to={`/admin/`} noThrow /> )
			  } 
			  else if ( loggedInE || localStorage.getItem( 'role' )=="Employee" ) {
				  return ( <Redirect to={`/Isreg/`} noThrow /> )
			  } */
		
		if (loggedin) {
			return <Redirect to={`/admin/`} noThrow />;
		} else {
			return (
				<GridContainer style={{ height: "100vh" }}>
					<div className={classes.topBackgroundPattern}>
						<img
							src={login_pattern_top}
							alt="modules background pattern"
						/>
					</div>
					<div className={classes.gridItem}>
						<Card className={classes.card}>
							<CardHeader
								style={{ padding: "0" }}
								color="primary"
								className="text-center"
							>
								<div>
									<img width="40%" src={incept_logo} alt="incept logo" />
								</div>
							</CardHeader>
							<CardBody style={{ boder: "1px solid yellow" }}>
								<Form style={pStyle}>
									<FormGroup>
										<Label className={classes.label} for="username">
											Login
                    					</Label>
										<input
											style={pStyle.input}
											type="text"
											className="form-control"
											name="username"
											value={username}
											onChange={this.handleOnChange}
										/>
										{/* </FormGroup>
                    					<FormGroup> */}
										<Label className={classes.label} for="password">
											Password
                    					</Label>
										<input
											style={pStyle.input}
											type="password"
											className="form-control"
											name="password"
											value={password}
											onKeyDown={(event) => this.clickenter(event)}
											onChange={this.handleOnChange}
										/>
									</FormGroup>
									<div
										id="errorlogin"
										style={{
											color: "red",
											marginBottom: "20px",
											display: "none",
										}}
									>
										Wrong user or password
                  					</div>
									<div id="loadinggd" style={{ display: "none" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
											style={{ marginBottom: "20px" }}
										/>
									</div>
									<a onClick={(e) => this.forgotPassowordModal()} style={pStyle.linkPointer}>
										<p style={{ marginLeft: "4rem", color: ourDarkGreen, fontWeight: "bold" }}>
										Forgot your password
                  						</p>
										</a>
										{(this.state.invalidkey===":invalidkey") && 
										<p style={{ color: "red", fontWeight: "bold" }}>
										Activation link is no longer valid, click on "Forgot your password" to 
										create your password.
                  						</p>}
									<Button
										style={pStyle.button}
										color="info"
										onClick={(event) => this.handleClick(event)}
									>
										Submit
                  					</Button>
								</Form>
							</CardBody>
						</Card>
					</div>
					<div className={classes.bottomBackgroundPattern}>
						<img
							width="100%"
							src={login_pattern_bottom}
							alt="modules background pattern"
						/>
					</div>
					<CustomModal
						show={this.state.forgotpassword}
						onHide={this.forgotPassowordCloseModal}
						backdrop={true}
					>
						<div style={{ minHeight: '100px' }}>
						<Form style={pStyle}>
							<FormGroup>
								<Label className={classes.label} for="username">
									Please enter you e-mail address. We will send you an e-mail to reset your password:
								</Label>
								<input
									style={pStyle.input}
									className={classes.inputShort+" form-control"}
									type="text"
									name="email"
									value={email}
									onChange={this.resetPasswordEmail}
								/>
								{this.state.missEmail && <p className={classes.errorfield}>
									You need to fill out e-mail
								</p>}
								{this.state.afterMail && <p className={this.state.afterMailError ? classes.errorfield:classes.successfield}>
									{this.state.afterMailMessage}
								</p>}
							</FormGroup>
							<div id="loadinggd2" style={{ display: "none" }}>
								<img
								className="responsive"
								src={loadinggif}
								width="25"
								alt=""
								style={{ marginBottom: "20px" }}
								/>
							</div>
							<ActionButton
								size="auto"
								onClick={(e) => this.resetPassword(e)}
							>
							Submit
							</ActionButton>
						</Form>
						</div>
					</CustomModal>
				</GridContainer>
			);
		}
	}
}

export default withStyles(loginStyle)(Login);

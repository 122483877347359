/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import i18n from "i18next";
import { withTranslation, Trans } from 'react-i18next';
import i18next from "i18next";

function Footer({ ...props }) {
  const { classes,t } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link to="/admin/user">
                {t('MY PROFILE')}
              </Link>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withTranslation()(withStyles(footerStyle)(Footer));

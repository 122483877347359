/*!
 * Description: StepZero component refers to the very first step in a guide
 * It is supposed to be used as an introduction to the guide.
 */

//Basic Import
import React from "react";
import ReactHtmlParser from "react-html-parser";
//Verify types
import PropTypes from 'prop-types';

// Material-UI Styles and Helpers
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { Link as RouterLink } from "react-router-dom";

import ActionButton from "components/CustomButtons/ActionButton.jsx";

//Translations
import { withTranslation } from 'react-i18next';
import { ourGreen, ourBlue, standardRadious, ourOrange,ourDarkGreen } from 'assets/jss/incept-sustainability-variables.jsx';

const colors = {
	textBase : '#000',
	scrollbar:'#FFC5C3'
};

const numIcons = {
	root : {
		width: '36px',
		lineHeight: '36px', //has to be the same as width
		borderRadius: '50%',
		textAlign: 'center',
		fontSize: '24px',
		fontWeight:'700',
		color: '#fff',
		background: ourBlue,
		display:'inline-block'
	}
};

/* Base object to make two types of stepMarkers and to avoid
installing plugins to make CSS extend */
// This is used below in "style" object.
const stepMarker = 	{
	background: ourOrange,
	height:'20px',
	width:'130px',
	position: 'relative',
	'&::before' : {
		content:'""',
		display:'inline-block',
		height: '100%',
		width: '10px',
		position: 'absolute',
		borderTop: '10px solid transparent',
		borderBottom: '10px solid transparent',
		borderLeft: '8px solid white',
	},
	'&::after' : {
		content:'""',
		display:'inline-block',
		height: '100%',
		width: '15px',
		left:'100%',
		position: 'absolute',
		borderTop: '10px solid transparent',
		borderBottom: '10px solid transparent',
		borderLeft: '8px solid '+ourOrange,
	}
};

const style = {
	root:{
		padding:'20px 50px',
		color:colors.textBase,
		"@media only screen and (max-width: 767px)": {
			'padding': '0'
		}
	},
	card:{
		borderRadius:'2rem',
	},
	title : {
		marginBottom: "0"
	},
	introduction : {
		/*marginTop : '40px'*/
		margin: "0 80px",
		"@media only screen and (max-width: 1280px)": {
			margin: "0"
		}
	},
	courseContent : {
		marginTop:'40px',
	},
	courseContentHeading : {
		fontSize:'20px',
		fontWeight:'700',
	},
	summaryWrapper : {
		marginTop:'40px',
	},
	learningsWrapper : {
		padding:'20px 50px 20px 0',
	},
	learningsHeading : {
		fontSize:'20px',
		fontWeight:'700',
	},
	feedbackWrapper : {
		background:ourDarkGreen,
		padding:'20px',
		borderRadius:standardRadious,
		color: "white"
	},
	feedbackHeading : {
		fontSize:'20px',
		fontWeight:'700',
		color:'white'
	},
	buttonWrapper : {
		textAlign:'center',
	},
	buttonStart : {
		maxWidth: '400px',
		color: 'white',
		fontSize: '24px',
		borderRadius : '20px',
		backgroundColor: colors.attention,
		"&:hover, &:active, &:focus": {
		  filter: "brightness(85%)",
		  backgroundColor: colors.attention,
		},
	},
	stepMarker : {
		...stepMarker,
		color: "white",
		fontSize: "10px",
		lineHeight: "20px"
	},
	beginStepMarker : {
		...stepMarker,
		'&::before' : {
			display:'none'
		},
		borderTopLeftRadius: '10px',
		borderBottomLeftRadius: '10px',
		textAlign: "center",
		color: "white",
		fontSize: "10px",
		lineHeight: "20px"
	},
	stepText: {
		textAlign: "center",
		display: "inline-block",
		width: "100%"
	},
	stepItem : {
		display:'inline-flex',
		flexDirection:'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		marginRight:'8px'
	},
	stepItemText : {
		transform : 'rotate(-135deg)',
		writingMode:'vertical-rl',
		flex:'1 1 200px',
		maxWidth: "50px"
	},
	stepItemsWrapper : {
		display:'flex',
		overflowX:'auto',
		paddingBottom:'15px',
		'&::-webkit-scrollbar' : {
			height:'7px'
		},
		'&::-webkit-scrollbar-thumb' : {
			backgroundColor: colors.scrollbar,
			borderRadius: '20px'
		},
		scrollbarColor: `${colors.scrollbar} transparent`, //only firefox > 64
		scrollbarWidth: 'thin', //only firefox > 64
	},
	'scrollIconContainer' : {
		textAlign:'center',
		marginTop:'10px'
	},
	titleStepZero: {
		padding: "10px 20px",
		backgroundColor: ourGreen,
		color: "white",
		borderRadius: "2rem",
		textAlign: "center",
		marginBottom: "60px"
	}
};

/** Helper Component to encapsulate Number Icons used here */
const NumberIcon = withStyles(numIcons)(
	({classes,children}) => <span className={classes.root}>{children}</span>
);

const LearningItem = (
	({classes, number, content}) => (
		<Grid container spacing={1} style={{padding:"8px 0",alignItems:"center"}}>
			<Grid item xs='auto'>
				<NumberIcon>{number}</NumberIcon>
			</Grid>
			<Grid item xs>
				{content}
			</Grid>
		</Grid>
	)
);

const StepItem = (
	//type: can be either 'standard' or 'begin'
	//this type defines which step marker should be displayed
	//'begin' type is supposed to have a rounded right side.
	({classes, text, index, type = 'standard'}) => (
		<div className={classes.stepItem}>
			<div className={classes.stepItemText}>{text}</div>
			<div className={type==='begin' ? classes.beginStepMarker : classes.stepMarker}>
				<span className={classes.stepText}>{index+1}</span></div>
		</div>
	)
);

const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
};



class StepZero extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			showScrollIcon:false,
		};
	}

	handleResize = () => {
		const x = document.getElementById("checkOverflow");

		this.setState({
			showScrollIcon:isOverflown(x)
		});
	}

	componentDidMount(){
		this.handleResize();
		window.addEventListener('resize',this.handleResize);
	}

	componentWillUnmount(){
		window.removeEventListener('resize',this.handleResize);
	}

	render(){
		const {classes, 
			title, 
			introduction, 
			steps, 
			imageModule,
			learnings, 
			feedbacks, 
			startLink,
			t
		} = this.props;
		const stepItems = steps.map((text,index) => <StepItem key={index} classes={classes} index={index} type={index === 0? 'begin' : 'standard'} text={text}/>);
		const learnItems = learnings.map((text,index) => <LearningItem key={index} number={index+1} content={text}/>);
		return (
			<div className={classes.root}>
			<Container>
				<Card className={classes.card}>
					<CardBody>
						<div className={classes.titleStepZero}>
							<h2 className={classes.title}>{title}</h2>
						</div>
						<div className={classes.introduction}>
							<Grid container>
								<Grid item xs={12} md={4}>
									<img alt="" src={imageModule} className={"responsive"}/>
								</Grid>
								<Grid item xs={12} md={8}>
									<div style={{marginLeft:"60px", marginRight: "50px"}}>
									{ReactHtmlParser(ReactHtmlParser(introduction))}
									</div>
								</Grid>
							</Grid>	
						</div>
						<div className={classes.courseContent}>
							<p className={classes.courseContentHeading}>{t('Course Content')}</p>
							<div id="checkOverflow" className={classes.stepItemsWrapper}>
							{stepItems}
							</div>
							{this.state.showScrollIcon &&
								<div className={classes.scrollIconContainer}><img alt="" src="/img/guides-hardcoded/icons/scroll-red.svg" width="32" height="32"/></div>
							}
						</div>
						<div className={classes.summaryWrapper}>
							<Grid container>
								<Grid item xs={12} md={6}>
									<div className={classes.learningsWrapper}>
										<p className={classes.learningsHeading}>{t('You learning goals')}</p>
										{learnItems}
									</div>
								</Grid>
								<Grid item xs={12} md={6}>
									<div className={classes.feedbackWrapper}>
										<p className={classes.feedbackHeading}>{t('Your feedback will help')}</p>
										<div>{ReactHtmlParser(ReactHtmlParser(feedbacks))}</div>
									</div>
								</Grid>
							</Grid>
						</div>
						<div className={classes.buttonWrapper}>
							<ActionButton 
								size="large"
								component={RouterLink} 
								to={startLink}>
							{t('Start')}
							</ActionButton>
						</div>
					</CardBody>
				</Card>
			</Container>
			</div>
		);
	}
}

//If you want a quick example on how to use each prop, you can uncomment this.
// StepZero.defaultProps = {
// 	learnings:[],
//	steps:[]
// };

//This is useful to avoid any errors at development stage, and when testing.
StepZero.propTypes = {
	learnings:PropTypes.arrayOf(PropTypes.string),
	steps:PropTypes.arrayOf(PropTypes.string)
};

export default withTranslation()(withStyles(style)(StepZero));
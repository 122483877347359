/*!
* Description: this file contains the component for
*  the type of exercise ExtraSDG, ex. guide 3, step 3, 
*  					"  Exercise: The 6th SDGs at DFDS" (only for DFDS)
* Components: ExtraSDG
*/
import React from "react";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";

// Style components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

//Input components
import DropDownImage from "components/CustomInput/DropDownImage.js";

//Constants
import {SDGS, PLACEHOLDER} from "variables/sdgs.jsx";

//Translations
import { withTranslation } from 'react-i18next';

const FIVE_SGDS = [2,4,12,13,16]; //These should correspond to SDGS array constant indexes you want to display.

const styles = {
	root: {},

	imgsContainer:{
		display:'flex',
		flexWrap:'wrap'
	},
	dropdownContainer:{
		paddingLeft:'10px'
	}
};

const SDGSBlockStyles = {
	imgBlockFlex:{
		padding:'10px'
	},
	imgBlock:{
		backgroundColor:"#78909cff",
		height:'100px',
		width:'100px',
		backgroundRepeat:'no-repeat',
		backgroundSize:'cover'
	}
};

const SDGSBlock = withStyles(SDGSBlockStyles)(
	(props) => {
		const {classes, url} = props;
		return (
			<div className={classes.imgBlockFlex}>
				<div className={classes.imgBlock} style={{backgroundImage:`url(${url})`}}/>
			</div>
		);
	}
);

/*
* this component renders the exercise for DFDS, "Extra SDG"
*/
class ExtraSDG extends React.Component {

	state = {
		selectedIndex : -1
	}
	
	constructor(props){
		super(props);
		this.state = {
                    selectedIndex : -1
                }
	
		if(typeof this.props.datasav2  !== "undefined") {
                    var aux = this.props.datasav2["content"];
                    this.state.selectedIndex =aux;
                }
	}

	handleSelectDropDown(e){
		const imageIndex = parseInt(e.target.value);
		this.setState({
			selectedIndex : imageIndex
		});
                this.props.save2("Extrasdg",imageIndex, this.props.inkey);
	}

	render(){
		const {classes, t} = this.props;
		const five_sdgs = FIVE_SGDS.map(
			(sdgindex, index) => {
				return <SDGSBlock key={index} url={SDGS[sdgindex].imageURL} />
			}
		)
		return (
			<div className={classes.root}>
				<Card>
					<CardBody isexercise={true}>
						<p><b>{t('The 5 SDG’s that DFDS focus on currently + your selected SDG')}</b></p>
						<div className={classes.imgsContainer}>
							{five_sdgs}
							<div className={classes.dropdownContainer}>
								<DropDownImage images={SDGS} currentValue={this.state.selectedIndex} onChange={(e) => this.handleSelectDropDown(e)} placeholderImgURL={PLACEHOLDER.imageURL} />
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(ExtraSDG));
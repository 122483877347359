const analytics = {
	engagement: [
		{
			key:'Last completed exercise',
			statement:'Last completed exercise',
			include_AE:false,
			style:'last_completed'
		},
		{
			key:'X num of Active Employees completed The Kick Off package',
			statement:'of <0/> completed The Kick Off package',
			include_AE:true,
			style:"green_bg"
		},
		{
			key:'learned something new',
			statement:'learned something new',
			include_AE:false,
			style:'learning'
		},		
	],
	company: [
		{
			key:'company_1',
			statement:'of <0/> selected',
			include_AE:true,
			style:"white_bg"
		},
		{
			key:'company_2',
			statement:'of <0/> selected',
			include_AE:true,
			style:"yellow_bg"
		},
		{
			key:'company_3',
			statement:'of <0/> selected',
			include_AE:true,
			style:"white_bg"
		},
	],
	ideas: [
		{
			key:'ideas have been submitted',
			statement:'ideas have been submitted',
			include_AE:false,
			style:"green_bg"
		},
		{
			key:'idea1',
			statement:'',
			include_AE:false,
			style:"white_bg"
		},
		{
			key:'idea2',
			statement:'',
			include_AE:false,
			style:"white_bg"
		},
		{
			key:'idea3',
			statement:'',
			include_AE:false,
			style:"white_bg"
		}
	]
};

module.exports = {
  analytics
};
/*!
 * Description: this file contains the main base component for
 *  the rendering the main page view of analytics
 *
 * Components: AnalyticsPage
 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
	Card,


	CardBody,



} from "reactstrap";
import { analytics } from "variables/analytics.jsx";
import { n_comp_pck, ourGreen, ourDarkGreen, 
	ourBlue,isAuthenticated } from "assets/jss/incept-sustainability-variables.jsx";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import CardHeader from "components/Card/CardHeader.jsx";
import jwt from "jwt-decode";
import {
	
	whiteColor,
	grayColor,
	hexToRgb,
} from "assets/jss/material-dashboard-react.jsx";
import { blackColor } from "assets/jss/material-dashboard-react";
import pattern from "assets/img/pattern01.png";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";





import "react-circular-progressbar/dist/styles.css";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
//Translate
import { withTranslation, Trans } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";

const style = {
	typo: {
		paddingLeft: "25%",
		marginBottom: "40px",
		position: "relative",
	},
	containerAnalytics: {
		padding: "20px 0px",
		"@media only screen and (max-width: 960px)": {
			margin: "60px 0"
		}
	},
	titlepackage: {
		fontFamily: "Raleway,Helvetica,Arial,sans-serif",
		fontWeight: "300",
		lineHeight: "1.5em",
	},
	lineunder: {
		display: "block",
		border: "1px solid #116628",
		marginLeft: "-60px",
		width: "50%",
		marginBottom: "40px",
		color: "rgba(0, 0, 0, 0.87)",
	},

	buttoncolor: {
		backgroundColor: "#0095a9",
		borderColor: "#0095a9",
	},
	containerother: {
		paddingRight: "50px",
		paddingLeft: "50px",
		marginLeft: "auto",
		height: "80%",
		"@media only screen and (max-width: 960px)": {
			marginTop: "5px",
			paddingRight: "20px",
			paddingLeft: "20px",
			/*marginLeft: "-30px",
			marginRight: "-30px",*/
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: portrait) ": {
			marginTop: "5px",
			marginLeft: "-30px",
			marginRight: "-30px",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			height: "1024px",
		},
	},
	readMoreContainer: {
		textAlign: "center",
		paddingTop: "10px",
		width: "40%",
		marginTop: "-60px",
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			marginTop: "-90px",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			width: "60%",
			marginTop: "-90px",
		},
	},
	gridItem: {
		display: "flex",
		flexFlow: "column",
		height: "95vh",
		width: "100vh",
		alignItems: "center",
		"@media only screen and (max-width: 600px)": {
			height: "auto",
		},
	},
	buttonRM1: {
		backgroundColor: ourBlue,
		"&:hover, &:active, &:focus": {
			filter: "brightness(85%)",
			backgroundColor: ourBlue,
			opacity: "0.6"
		},
		color: "white",
		fontSize: "15px",
		borderRadius: "3rem",
		width: "100%",
		height: "100%",
		alignItems: "center",
		textTransform: "initial",
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "2vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "1.6vh"
		},
	},

	backgroundPatternImage: {
		width: "23%",
		zIndex: "0",
		right: "0",
		top: "0",
		position: "absolute",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	
};
const columnInfoStyle = {
	root: {
		color: "white",
		lineHeight: "1.2em",
		textAlign: "center",
		borderTop: "8px solid black",
		borderBottom: "8px solid black",
		display: "flex",
		flexFlow: "column",
		flex: "1",
		borderColor: ({ type }) => {
			if (type === "company") {
				return "#C07004";
			} else if (type === "ideas") {
				return "#003A00";
			}
			return "#006978";
		},
	},
	title: {
		fontSize: "30px",
		fontWeight: "bold",
		backgroundColor: ({ type }) => {
			if (type === "company") {
				return "#FFAB40";
			} else if (type === "ideas") {
				return "#116628";
			}
			return "#0097A7";
		},
		padding: "20px 10px 30px 10px",
	},
	card: {
		borderRadius: "2rem",
		height: "100%",
		width: "100%",
		minHeight: "433px",
		"@media only screen and (min-width: 960px)": {
			maxWidth: "50vh",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			height: "95%",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			height: "950px",
			marginTop: "28px",
		},
	},
	cardCategory: {
		color: grayColor[0],
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		paddingTop: "100px",
		marginBottom: "0",
	},
	cardCategoryWhite: {
		color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0",
	},
	cardTitle: {
		color: grayColor[2],
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Raleway', 'Helvetica', 'Arial', sans-serif",

		textDecoration: "none",
		marginBottom: "10px",
		"& small": {
			color: grayColor[1],
			fontWeight: "400",
			lineHeight: "1",
		},
	},
	cardTitleWhite: {
		color: blackColor,

		fontSize: "4vh",
		minHeight: "auto",

		fontFamily: "'Raleway', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		textAlign: "center",
		"& small": {
			color: grayColor[1],
			fontWeight: "400",
			lineHeight: "1",
		},
		fontWeight: "bold",
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "3.5vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "2.6vh",
		},
	},
	cardHeaderEngagement: {
		backgroundColor: "rgb(255, 255, 255)",
		padding: "0.75rem 1.5rem 0rem 1.5rem",
	},
	cardHeaderCompany: {
		backgroundColor: "rgb(255, 255, 255)",
		padding: "0.75rem 1.5rem 0rem 1.5rem",
	},
	cardHeaderIdeas: {
		backgroundColor: "rgb(255, 255, 255)",
		padding: "0.75rem 1.5rem 0rem 1.5rem",
	},
	cardHeaderImageCompany: {
		width: "42%",
		height: "100%",
		padding: "0.78vw 0.39vw 0.78vw 0.78vw",
		marginLeft: "-2%",
	},
	cardHeaderImageEngagement: {
		width: "27%",
		height: "100%",
		padding: "0.78vw 0.39vw 0.78vw 0.78vw",
		marginLeft: "-2%",
	},
	cardHeaderImageIdeas: {
		width: "35%",
		height: "100%",
		padding: "0.78vw 0.39vw 0.78vw 0.78vw",
	},
	cardBody: {
		padding: "1vw",
		textAlign: "center",
		color: "black",
		marginTop: "-10px",
		position: "relative",
	},
	containerother: {
		paddingRight: "1.95vw",
		paddingLeft: "1.95vw",
		marginRight: "auto",
		marginLeft: "auto",
	},
};
const columnRowStyle = {
	rootEngagement: {
		display: "flex",
		flexDirection: "row",
		borderTop: "1px solid #fff",
		padding: "0.78vw 0.78vw",
		backgroundColor: "#FFFFFF",
		"@media only screen and (max-width: 960px)": {
			margin: "3vh 1vh 3vh 1vh",
			paddingTop: "2vh",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			position: "absolute",
			bottom: "130px",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			paddingTop: "30px"
		},
	},
	rootBox: {
		display: "flex",
		flexDirection: "row",
		borderTop: "1px solid #fff",
		padding: "1.56vw 0.78vw 0.78vw 0.78vw",
		margin: "0.78vw 0vw 30px 0vw",
		backgroundColor: ourGreen,
		borderRadius: "1.5rem",
		color: "#FFFFFF",
		height: "20vh",
		"@media only screen and (max-width: 960px)": {
			margin: "30px 10px 30px 10px",
			height: "25vh",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			height: "20vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			height: "20vh",
			marginTop: "40px",
			paddingTop: "30px"
		},
	},
	rootCompany: {
		height: "30%",
		/*display: "flex",*/
		flexDirection: "row",
		borderTop: "1px solid #fff",
		padding: "20px 10px 10px 10px",
		backgroundColor: ({ statement }) => {
			if (
				statement ===
				"Of all participants would like initiatives to stay healthy"
			) {
				return "#ffe682";
			}
			return "#FFFFFF";
		},
		borderRadius: ({ statement }) => {
			if (
				statement ===
				"Of all participants would like initiatives to stay healthy"
			) {
				return "1.5rem";
			}
			return "0";
		},
		color: ({ statement }) => {
			/*if (
				statement ===
				"Of all participants would like initiatives to stay healthy"
			) {
				return "#FFFFFF";
			}*/
			return "black";
		},
		fontSize: "1.8vh",
		"@media only screen and (max-width: 960px)": {
			margin: "10px 10px 10px 10px",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			paddingBottom: "10px !important",
		},
	},
	rootIdeas: {
		display: "flex",
		flexDirection: "row",
		borderTop: "1px solid #fff",
		padding: "20px 10px 10px 10px",
		margin: "0.78vw 0vw 0.78vw 0vw",
		backgroundColor: "#FFFFFF",
		"@media only screen and (max-width: 960px)": {
			padding: "10px 10px 0px 10px",
			marginTop: "20px",
			borderRadius: "4vh !important",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			height: "18vh",
		},
	},
	IdeaColumnImage: {
		width: "2vw",
		height: "100%",
		padding: "0.78vw 0px 0.78vw 0px",
		"@media only screen and (max-width: 960px)": {
			width: "4.5vh",
			paddingTop: "20px",
			paddingRight: "5px",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			width: "15%",
			height: "40%",
			marginTop: "20px",
		},
	},
	progressbar: {
		width: "42%",
		height: "100%",
		right: "0",
		top: "0",
		position: "relative",
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			width: "16vh",
			marginLeft: "-260px",
			marginTop: "40px",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			marginTop: "123px",
			marginLeft: "-295px",
			width: "15vh",
			zIndex: "1",
		},
	},
	data: {
		fontSize: "400%",
		fontWeight: "bold",
		paddingTop: "0.75vw",
		paddingBottom: "0.75vw",
		paddingLeft: "0.78vw",
		marginTop: "-5%",
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "5vh !important",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "5vh !important",
		},
	},
	statement2: {
		width: "100%",
		heigh: "100%",
		/*display: "flex",*/
		fontSize: "110%",
		paddingLeft: "0.78vw",
		paddingRight: "0.39vw",
		textAlign: "left",
		lineHeight: "1.1",
		display: "block",
		overflow: "hidden",
		"-webkit-line-clamp": "2",
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "2.1vh !important",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "1.87vh !important",
		},
	},
	statement: {
		width: "100%",
		heigh: "100%",
		display: "flex",
		fontSize: "130%",
		paddingLeft: "0.78vw",
		paddingRight: "0.39vw",
		textAlign: "left",
		lineHeight: "1.1",
		"-webkit-line-clamp": "2",
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "2.1vh !important",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "1.87vh !important",
		},
	},
	mobileData: {
		fontSize: "4vh",
		fontWeight: "bold",
		paddingBottom: "0.75vw",
		paddingLeft: "0.78vw",
		"@media only screen and (max-width: 960px)": {
			fontSize: "7vh !important",
			paddingLeft: "10px",
			paddingTop: "2vh",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "6.5vh !important",
			paddingTop: "15px !important",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "5.3vh",
			paddingTop: "15px !important",
		},
	},
	mobileCompanyData: {
		fontSize: "4vh",
		fontWeight: "bold",
		paddingTop: "1.5vw",
		paddingBottom: "0.75vw",
		marginTop: "-5%",
		"@media only screen and (max-width: 960px)": {
			paddingTop: "30px",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "6vh !important",
			paddingTop: "20px",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "5.3vh",
			paddingTop: "15px !important",
			paddingRight: "15px",
		},
	},
	mobileStatement: {
		width: "100%",
		heigh: "100%",
		/*display: "flex",*/
		fontSize: "130%",
		paddingLeft: "0.78vw",
		paddingRight: "0.39vw",
		textAlign: "left",
		lineHeight: "1.1",
		"-webkit-line-clamp": "2",
		display: "block",
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
		"@media only screen and (max-width: 960px)": {
			fontSize: "3vh !important",
			paddingLeft: "10px",
			paddingTop: "10px",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "2.2vh !important",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "1.7vh !important",
		},
	},
	mobileProgress: {
		width: "100%",
		heigh: "100%",
		/*display: "flex",*/
		fontSize: "130%",
		paddingLeft: "0.78vw",
		paddingRight: "0.39vw",
		textAlign: "left",
		lineHeight: "1.1",
		"-webkit-line-clamp": "2",
		"-webkit-box-orient": "vertical",
		display: ({ statement }) => {
			if (
				statement ===
				"Participants  learned something new in at least 1 of the guides"
			) {
				return "block";
			}
			return "-webkit-box"
		},
		overflow: "hidden",
		"@media only screen and (max-width: 960px)": {
			height: "100% !important",
			width: "50% !important",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			height: "25vh !important",
			width: "31vh !important",
			fontSize: "3vh !important",
			marginTop: "230px !important",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			height: "25vh !important",
			width: "24vh !important",
			fontSize: "2vh !important",
			paddingLeft: "30px",
		},
	},
	mobileComplete: {
		width: "100%",
		heigh: "100%",
		display: "flex",
		/*fontSize: "130%",*/
		paddingLeft: "0.78vw",
		paddingRight: "0.39vw",
		/*textAlign: "left",*/
		lineHeight: "1.1",
		fontWeight: "bold",
		textAlign: "center",
		fontSize: "3vh",
		"-webkit-line-clamp": "2",
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
		"@media only screen and (max-width: 960px)": {
			fontSize: "3vh !important",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "2.3vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "1.7vh"
		},
	},
	mobileCompleteData: {
		/*fontSize: "400%",*/
		fontWeight: "bold",
		paddingBottom: "0.75vw",
		/*paddingLeft: "0.78vw",*/
		paddingLeft: "10px",
		paddingTop: "15px",
		color: ourDarkGreen,
		paddingRight: "1.17vw",
		marginTop: "-0.30vw",
		textAlign: "center",
		fontSize: "4vh",
		"@media only screen and (max-width: 960px)": {
			fontSize: "6vh !important",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			fontSize: "4.5vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			fontSize: "3vh"
		},
	},
	mobileRootBox: {
		display: "flex",
		flexDirection: "row",
		borderTop: "1px solid #fff",
		padding: "10px 0.78vw 0.78vw 0.78vw",
		margin: "0.78vw 0vw 30px 0vw",
		backgroundColor: ourGreen,
		borderRadius: "1.5rem",
		color: "#FFFFFF",
		"@media only screen and (max-width: 960px)": {
			margin: "30px 10px 30px 10px",
			height: "11vh",
		},
		"@media only screen and (min-width: 1366px) and (max-width: 1366px)": {
			height: "7vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
			height: "7vh",
			paddingTop: "30px"
		},
	},
	rowbox: {
		overflowY: "auto",
		height: "90%",
		minHeight: "11.2vh"
	},
	greenbg: {
		//height: "5vw",
		height: "auto",
		"@media only screen and (max-width: 960px)": {
			height: "auto",
			padding: "20px"
		},
	},
	greenbg1: {
		margin: "0px 10px",
		"@media only screen and (max-width: 960px)": {
			margin: "0",
		},
	},
	graybg: {
		borderTop: "1px solid #fff",
		backgroundColor: "rgb(242, 242, 242)",
		borderRadius: "20px !important",
		marginTop: "-0.78vw",



		height: "30%",
		"@media only screen and (max-width: 600px)": {
			height: "auto",
		},
	},
	novalue: {
		paddingTop: "30px",
		marginTop: "-5%"
	},
	height30: {
		height: "30%",
		"@media only screen and (max-width: 600px)": {
			height: "auto",
		},
	},
	marginLastBottom: {
		"@media only screen and (max-width: 600px)": {
			marginBottom: "100px",
		},
	}

};

const ColumnInfo = withStyles(columnInfoStyle)((props) => {
	const {
		classes,
		title,
		info,
		type,
		first,
		second,
		third,
		fourth,
		extra,
		secextra,
		thextra,
		title_ex
	} = props;
	const rows = info.map(({ key, data, statement }, i) => (
		<ColumnRow
			data={data}
			statement={statement}
			type={type}
			key={i}
            key_analytics={key}
			title={title}
			f={first}
			s={(type === "ideas" && second.length > 0) ? second[0] : second}
			ttt={(type === "ideas" && second.length > 1) ? second[1] : third}
			fo={(type === "ideas" && second.length > 2) ? second[2] : fourth}
			ext={extra}
			xts={secextra}
			thext={thextra}
			title_ex = {title_ex}
		/>
	));
	if (type === "ideas") {
		return (
			<Card className={classes.card}>
				<CardHeader
					className={classes["cardHeader" + title]}
					style={{
						borderRadius: "2rem",
					}}
				>
					<div
						style={{}}
					>
						{/*<img
							className={classes["cardHeaderImage" + title]}
							src={ideasImage}
							alt="header image"
						/>*/}
						<h3 className={classes.cardTitleWhite}>{title}</h3>
						<p className={classes.cardCategoryWhite}></p>
					</div>
				</CardHeader>
				<CardBody className={classes.cardBody}>{rows}</CardBody>
			</Card>
		);
	} else if (type === "company") {
		return (
			<Card className={classes.card}>
				<CardHeader
					className={classes["cardHeader" + title]}
					style={{
						borderRadius: "2rem",
					}}
				>
					<div
						style={{}}
					>
						{/*<img
							className={classes["cardHeaderImage" + title]}
							src={companyImage}
							alt="header image"
						/>*/}
						<h3 className={classes.cardTitleWhite}>{title}</h3>
						<p className={classes.cardCategoryWhite}></p>
					</div>
				</CardHeader>
				<CardBody className={classes.cardBody}>{rows}</CardBody>
			</Card>
		);
	}
	return (
		<Card className={classes.card}>
			<CardHeader
				className={classes["cardHeader" + title]}
				style={{  borderRadius: "2rem" }}
			>
				<div
					style={{}}
				>
					{/*<img
						className={classes["cardHeaderImage" + title]}
						src={engagementImage}
						alt="header image"
					/>*/}
					<h3 className={classes.cardTitleWhite} style={{}}>
						{title}
					</h3>
					<p className={classes.cardCategoryWhite}></p>
				</div>
			</CardHeader>
			<CardBody className={classes.cardBody}>{rows}</CardBody>
		</Card>
	);
});
//Retunrns the jsx for the corresponding row
const ColumnRow = withTranslation()(withStyles(columnRowStyle)((props) => {
	//const { heightWindow, widthWindow } = useWindowDimensions();
	const {
		classes,
		data,
        key_analytics,
		statement,
		
		f,
		s,
		t,
		ttt,
		fo,
		ext,
		xts,
		thext,
		title_ex
	} = props;
	if (key_analytics === "Last completed exercise"){
		//let foriphone = Math.ceil((window.innerWidth * 1.56 / 100));
		return (
			<div className={classes.height30} style={{paddingBottom:"-20px"}}>
			<div
				className={classes.mobileRootBox+" "+classes.greenbg}
				style={{
					fontSize: "1.5vw",
					fontWeight: "bold"
				}}
			>
				<GridContainer>
					<GridItem xs={12} sm={6} md={6} space={0} className={classes.greenbg1} style={{fontSize:"22px"}}>
						{statement}
					</GridItem>
					<GridItem xs={12} sm={6} md={6} space={0} style={{fontSize:"30px",color:"white"}}>
						{ttt}
					</GridItem>
				</GridContainer>
			</div>
			</div>
		);
	} else if (
		key_analytics ===
		"learned something new"
	) {
		return (
			<div
			className={classes.rootCompany+" "+classes.height30+" "+classes.marginLastBottom}
		>
			<GridContainer>
				<GridItem xs={4} sm={4} md={4} space={0}>
					<div className={classes.mobileCompanyData}
						style={{
							marginLeft: "-0.5vw",
						}}>
						{s + "%"}
					</div>
				</GridItem>
				<GridItem xs={8} sm={8} md={8} space={0}>
					<div
						className={classes.statement2}
						style={{ minHeight: "100%" }}
					>
						{t('of')}
								<ActiveEmployees_tooltip tooltipid={"tool2"} />
								{t('learned something new')}
					</div>
				</GridItem>
			</GridContainer>
		</div>
		);
	} else if (key_analytics === "X num of Active Employees completed The Kick Off package") {
		return (
			<div
				className={classes.rootCompany+" "+classes.graybg}
				style={{backgroundColor: "rgb(242, 242, 242)"}}
			>
				<GridContainer>
					<GridItem xs={4} sm={4} md={4} space={0}>
						<div className={classes.mobileCompanyData}
							style={{
								marginLeft: "-0.5vw",
							}}>
							{f + "%"}
						</div>
					</GridItem>
					<GridItem xs={8} sm={8} md={8} space={0}>
						<div
							className={classes.statement2}
							style={{ minHeight: "100%" }}
						>
							<Trans
								i18nKey="X num of Active Employees completed The Kick Off package"
								defaults="of <0/> completed The Kick Off package"
								components={[<ActiveEmployees_tooltip tooltipid={"tool1"}/>]}
							/>
						</div>
					</GridItem>
				</GridContainer>	
			</div>
		);
	} else if (key_analytics === "ideas have been submitted") {
		return (
			<div
				className={classes.mobileRootBox+" "+classes.greenbg}
				style={{
					fontSize: "1.5vw",
					fontWeight: "bold",
					marginBottom: "1.56vw"
				}}
			>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3} space={0} className={classes.greenbg1} style={{fontSize:"30px"}}>
						{f}
					</GridItem>
					<GridItem xs={12} sm={6} md={9} space={0} style={{fontSize:"22px",color:"white"}}>
						{t('ideas have been submitted')}
					</GridItem>
				</GridContainer>
			</div>
		);
	} else if (key_analytics === "idea1") {
		return (
			<div className={classes.rootIdeas}>
				{/*<img
					className={classes.IdeaColumnImage}
					src={starImage}
					alt="idea image"
				/>*/}
				<div
					className={classes.statement}
					style={{ minHeight: "11.2vh", overflow: "auto", fontSize: "2vh" }}
				>
					"{s}"
		</div>
			</div>
		);
	} else if (key_analytics === "idea2") {
		return (
			<div>
				<div
					className={classes.rootIdeas}
					style={{ backgroundColor: "#F2F2F2", borderRadius: "1rem" }}
				>
					{/*<img
						className={classes.IdeaColumnImage}
						src={starImage}
						alt="idea image"
					/>*/}
					<div
						className={classes.statement}
						style={{ minHeight: "11.2vh", overflow: "auto", fontSize: "2vh" }}
					>
						"{ttt}"
		  </div>
				</div>
				<div className={classes.rootIdeas+" "+classes.marginLastBottom}>
					{/*<img
						className={classes.IdeaColumnImage}
						src={starImage}
						alt="idea image"
					/>*/}
					<div
						className={classes.statement}
						style={{ minHeight: "11.2vh", overflow: "auto", fontSize: "2vh" }}
					>
						"{fo}"
		  </div>
				</div>
			</div>
		);
	} /* else if (statement == "Third idea of the employees") {
	return (
	  <div className={classes.root}>
		<img
		  className={classes.IdeaColumnImage}
		  src={starImage}
		  alt="idea image"
		/>
		<div
		  className={classes.statement}
		  style={{ minHeight: "110px", fontSize: "70%" }}
		>
		  "{fo}"
		</div>
	  </div>
	);
  } */ else if (
    key_analytics === "company_1"
	) {
		let emtpy_values = (f===0) ? true:false;
		return (
			<div className={classes.rootCompany} style={{ marginTop: "10px" }}>
				<GridContainer>
					<GridItem xs={4} sm={4} md={4} space={0}>
						<div
							className={classes.mobileCompanyData}
							style={{
								marginLeft: "-0.5vw",
							}}
						>
							{f + "%"}
						</div>
					</GridItem>
					<GridItem xs={8} sm={8} md={8} space={0}>
						<div className={classes.statement2} style={{ minHeight: "100%" }}>
							{!emtpy_values && <div className={classes.rowbox}>
								{t('of Employees')+" "}
								{/*<ActiveEmployees_tooltip
									tooltipid={"tool3"}>
								</ActiveEmployees_tooltip>*/}
								{t('selected')} <span style={{fontWeight:"600"}}>{ext+" "}</span>
								{t('in')} <span style={{fontWeight:"600"}}>{title_ex[0]}</span>
								</div>
							}
							{emtpy_values && <div className={classes.rowbox+" "+classes.novalue}>
								{t('No values to show yet')}
								</div>}
						</div>
					</GridItem>
				</GridContainer>	
			</div>
		);
	} else if (
		key_analytics === "company_2"
	) {
		let emtpy_values = (s===0) ? true:false;
		return (
			<div className={classes.rootCompany} style={{ marginTop: "-0.78vw" }}>
				<GridContainer>
					<GridItem xs={4} sm={4} md={4} space={0}>
						<div className={classes.mobileCompanyData}>{s + "%"}</div>
					</GridItem>
					<GridItem xs={8} sm={8} md={8} space={0}>
						<div className={classes.statement2} style={{ minHeight: "60px" }}>
						{!emtpy_values && <div className={classes.rowbox}>
							{t('of Employees')+" "}
							{/*<ActiveEmployees_tooltip
								tooltipid={"tool4"}>
							</ActiveEmployees_tooltip>*/}
							{t('selected')}
							<span style={{fontWeight:"600"}}>{" "+xts+" "}</span>
							in <span style={{fontWeight:"600"}}>{title_ex[1]}</span>
							</div>
						}
						{emtpy_values && <div className={classes.rowbox+" "+classes.novalue}>
								{t('No values to show yet')}
								</div>}
						</div>
					</GridItem>
				</GridContainer>	
			</div>
		);
	} else if (
		key_analytics === "company_3"
	) {
		let emtpy_values = (ttt===0) ? true:false;
		return (
			<div
				className={classes.rootCompany+" "+classes.marginLastBottom}
			>
				<GridContainer>
					<GridItem xs={4} sm={4} md={4} space={0}>
						<div className={classes.mobileCompanyData}>{ttt + "%"}</div>
					</GridItem>
					<GridItem xs={8} sm={8} md={8} space={0}>
						<div className={classes.statement2} style={{ minHeight: "60px" }}>
							{!emtpy_values && <div className={classes.rowbox}>
								{t('of Employees')+" "}
								{/*<ActiveEmployees_tooltip
									tooltipid={"tool5"}>
								</ActiveEmployees_tooltip>*/}
								{t('selected')} <span style={{fontWeight:"600"}}>{thext+" "}</span>
								in <span style={{fontWeight:"600"}}>{title_ex[2]}</span>
								</div>
							}
							{emtpy_values && <div className={classes.rowbox+" "+classes.novalue}>
								{t('No values to show yet')}
								</div>}
						</div>
					</GridItem>
				</GridContainer>
			</div>
		);
	} else {
		return (
			<div className={classes.root}>
				<div className={classes.data}>{data}</div>
				<div className={classes.statement}>{statement}</div>
			</div>
		);
	}
}));
/*
 * Component: AnalyticsPage
 * Description: renders the cards for the main analytics page (summary)
 */
class AnalyticsPage extends React.Component {
	constructor(props) {
		super(props);
		var redlog = false;
		var decodedtoken = "";
		if (!isAuthenticated()) {
			redlog = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem("token"));
		this.state = {
			completedguides: 0,
			loadingfirst: true,
			learnedrate: 0,
			lastcompl: "",
			nideas: 0,
			ideas_3: [],
			company: [],
			tooltipOpenID:"",
			userid: (decodedtoken!=="") ? decodedtoken.data.user.id:"",
			actions: "",
			reloadlogin: redlog
		};
	}

	//gets data from backend
	async getncomplpack() {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		this.setState({ loadingfirst: true });
		const token = localStorage.getItem("token");
		
		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		axios
			.get(n_comp_pck + "statistics?wpml_language="+ useLang, { headers: { Authorization: authstr } })
			.then((response) => {
				console.log(response);
				this.setState({
					completedguides: response.data.completed,
					learnedrate: response.data.learnednew,
					lastcompl: response.data.lastcompleted,
					nideas: response.data.nideas,
					ideas_3: response.data.ideas,
					company: response.data.company,
					/*noppor: response.data.nopportunities,
					boppor: response.data.opportunities,
					nwork: response.data.nworkculture,
					workc: response.data.workculture,
					nactions: response.data.nactions,
					actions: response.data.actions,*/
					load: 1,
					loadingfirst: false
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ error: err, loading: false });
				this.setState({ loadingfirst: false });
			});
	}
	componentDidMount() {
		this.getncomplpack();
		i18next.on('languageChanged', () => this.getncomplpack());
	}
	//renders component
	render() {
		const { classes, t } = this.props;
		const { engagement, company, ideas } = analytics;
		if (this.state.load) {
			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
					{
						<img
							className={classes.backgroundPatternImage}
							src={pattern}
							alt="background patterns"
						/>
					}
					<div className={classes.containerother}>
						<div className={classes.containerAnalytics}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} lg={4} className={classes.gridItem}>
									<ColumnInfo
										title={t("Engagement")}
										info={engagement}
										first={this.state.completedguides}
										second={this.state.learnedrate}
										third={this.state.lastcompl}
									/>
									<div className={classes.readMoreContainer}>
										<Link to="/admin/read_more">
											<Button variant="contained" className={classes.buttonRM1}>
												{t('See more')}
					  </Button>
										</Link>
									</div>
								</Grid>
								<Grid item xs={12} sm={12} lg={4} className={classes.gridItem}>
									<ColumnInfo
										title={t("Company")}
										info={company}
										type="company"
										first={this.state.company[0].nvalues}
										extra={this.state.company[0].values}
										second={this.state.company[1].nvalues}
										secextra={this.state.company[1].values}
										third={this.state.company[2].nvalues}
										thextra={this.state.company[2].values}
										title_ex={[this.state.company[0].title,this.state.company[1].title,this.state.company[2].title]}
									/>
									<div className={classes.readMoreContainer}>
										<Link to="/admin/read_more2">
											<Button variant="contained" className={classes.buttonRM1}>
												{t('See more')}
					  </Button>
										</Link>
									</div>
								</Grid>
								<Grid item xs={12} sm={12} lg={4} className={classes.gridItem}>
									<ColumnInfo
										title={t("Ideas")}
										info={ideas}
										type="ideas"
										first={this.state.nideas}
										second={this.state.ideas_3}
									/>
									<div className={classes.readMoreContainer}>
										<Link to="/admin/read_more3">
											<Button variant="contained" className={classes.buttonRM1}>
												{t('See more')}
					  </Button>
										</Link>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</LoadingOverlay>
			);
		} else {
			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
					<div className={classes.containerother}>
						<div style={{ padding: "50px 0" }}></div>
					</div>
				</LoadingOverlay>
			);
		}
	}
}
ColumnInfo.defaultProps = {
	info: [],
};
AnalyticsPage.propTypes = {
	classes: PropTypes.object,
};
export default withTranslation()(withStyles(style)(AnalyticsPage));
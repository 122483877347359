/*!
* Description: this file contains the component for
*  the type of exercise DropDownGroups, ex. guide 3, step 3, "Exercise: Matching the Triple Bottom Line with the SDG’s"
* Components: DropDownGroups
*/
import React from "react";
import ReactHtmlParser from 'react-html-parser';

// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import { ourGreen } from 'assets/jss/incept-sustainability-variables.jsx';

// Style components
import Grid from '@material-ui/core/Grid';

//Forms
import FormControl from '@material-ui/core/FormControl';
import DropDownImage from "components/CustomInput/DropDownImage.js";
import checked from 'assets/img/checkedWhiteBg.png';
import Container from '@material-ui/core/Container';
// Effects
import Collapse from '@material-ui/core/Collapse';

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {paddingTop: '40px'},
	rootdrop: {
		"@media only screen and (max-width: 768px)": {
			textAlign: "center",
		}
	},
	card: {
		margin: '0',
		height: '100%'
	},
	buttonContainer: {
		textAlign: 'right',
		marginTop: "20px",
	},
	buttonContainerAnswers: {
		textAlign: "right",
		marginBottom: "60px"
	},
	buttonAnswer: {
		color: "#fff",
		backgroundColor: ourGreen,
		'&:hover, &:active, &:focus': {
			filter: 'brightness(85%)',
			backgroundColor: ourGreen,
		}
	},
	halfsize: {
		width: "40%",
		"@media only screen and (max-width: 960px)": {
			width: "100%",
			marginBottom: "20px"
		}
	},
	itemcenter: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"@media only screen and (max-width: 960px)": {
			paddingBottom: "0 !important",
			marginTop: "40px"
		}		
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "24px",
		marginBottom: "20px"
	},
};

const DIMENSIONS = [
	{
		name: 'Economy',
		amount: 4,
	},
	{
		name: 'Society',
		amount: 8,
	},
	{
		name: 'Biosphere',
		amount: 4,
	},
];

const CAKEURL = '/img/guides-hardcoded/the-mindsets-of-sustainability/cake.png';
const ECONOMY = '/img/guides-hardcoded/the-mindsets-of-sustainability/economy2.png';
const SOCIETY = '/img/guides-hardcoded/the-mindsets-of-sustainability/society2.png';
const BIO = '/img/guides-hardcoded/the-mindsets-of-sustainability/biosphere2.png';

const IMGDEFAULT = {
	imageURL: '/img/guides-hardcoded/SDGIcons/0main.png',
	title: 'SDGs'
};

var IMGOPTIONS = [
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/1nopoverty.png',
		title: 'No poverty',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/2nohunger.png',
		title: 'No hunger',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/3goodhealth.png',
		title: 'Good health',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/4education.png',
		title: 'Education',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/5genderequality.png',
		title: 'Gender Equality',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/6cleanwater.png',
		title: 'Clean Water',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/7cleanenergy.png',
		title: 'Clean Energy',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/8work.png',
		title: 'Work',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/9industry.png',
		title: 'Industry',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/10inequality.png',
		title: 'Inequality',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/11community.png',
		title: 'Community',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/12compsumption.png',
		title: 'Compsumption',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/13climate.png',
		title: 'Climate',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/14lifewater.png',
		title: 'Life Water',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/15lifeland.png',
		title: 'Life Land',
		show: true
	},
	{
		imageURL: '/img/guides-hardcoded/SDGIcons/16peace.png',
		title: 'Peace',
		show: true
	}
	,
	{
		imageURL: '',
		title: '',
		show: true
	}
];

const Dimension = (props) => {

	const { name, amount, classes, values, onChange,allimgoptions, t, ...rest } = props;

	const list = Array(amount).fill(-1).map((val, index) => 
	<DropDownImage key={index} images={allimgoptions} 
	currentValue={values[index]} 
	//onChange={(e) => onChange(e, index)} placeholderImgURL={IMGDEFAULT.imageURL} {...rest} />);
	onChange={(e) => onChange(e, index)} placeholderImgURL={t('cakeurl',IMGDEFAULT.imageURL)} {...rest} />);
	
	var imgurl = ECONOMY;
	if (name==="Society")
		imgurl = SOCIETY;
	else if (name==="Biosphere")
		imgurl = BIO;

	return (
		<Grid container spacing={6} >
			<Grid item xs={12} md={4} className={classes.itemcenter}>
				<img alt="" src={imgurl} className={"responsive"} />
			</Grid>
			<Grid item xs={12} md={8}>
				<div style={{marginTop: "20px"}}>
					<h1 className="guide-subheading">{name}</h1>
					<div style={{display:"flex", flexWrap: "wrap"}}>
					{list}
					</div>
				</div>
			</Grid>
		</Grid>
	);
}

/*
* Description: component for dropdown exercise
*/
class DropDownGroups extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedAnswers: Array(DIMENSIONS.length).fill(-1).map((value, index) => Array(DIMENSIONS[index].amount).fill(-1)),
			showAnswer: false,
			allimgoptions: IMGOPTIONS,
			statusContainer: false,
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		}
		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswers = this.props.datasav2["content"];
			let aux = this.state.selectedAnswers;
			let indexNotShow = 0;
			//Go through them to see which ones are selected
			for(var i=0;i<aux.length;i++) {
				for (var j=0;j<aux[i].length;j++) {
					if (aux[i][j] !== -1) {
						
						indexNotShow = aux[i][j];
						if (indexNotShow!==this.state.allimgoptions.length-1)
							this.state.allimgoptions[indexNotShow].show = false;
					}
				}
			}
		}
	}

	handleChange(e, dimensionIndex, answerIndex) {
		const imageIndex = parseInt(e.target.value);
		this.setState(state => {
			const sa = state.selectedAnswers;
			//Previous selected
			const prevChoise = sa[dimensionIndex][answerIndex];
			sa[dimensionIndex][answerIndex] = imageIndex;
			var aux = state.allimgoptions;
			if (prevChoise!==-1)
				aux[prevChoise].show = true;
			if (imageIndex!==aux.length-1)
				aux[imageIndex].show = false;
			//this.props.save2("DropDownGroup", sa, this.props.inkey, this.props.exercise_id);
			return { selectedAnswers: sa , allimgoptions: aux, submittedanswer: false};
		});
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ submittedanswer: true });
		this.props.save2("DropDownGroup", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
	}

	handleSubmitAnswer(e) {
		e.preventDefault();
		if (this.state.showAnswer)
			this.setState({ showAnswer: false });
		else
			this.setState({ showAnswer: true });
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { answerimg } = exercise;

		const dimensions = DIMENSIONS.map((value, index) => <Dimension key={index} t={t}  classes={classes} 
		allimgoptions={this.state.allimgoptions}
		name={t("dimenName",DIMENSIONS[index].name)} amount={DIMENSIONS[index].amount} 
		values={this.state.selectedAnswers[index]} 
		onChange={(e, answerIndex) => this.handleChange(e, index, answerIndex)} />);

		if (answerimg !== "") {
			return (
				<div className={classes.root}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<img alt="" src={CAKEURL} className={classes.halfsize +" responsive"} />
						</Grid>
						<Grid item xs={12} md={12} className={classes.rootdrop}>
							<div>
								{/*<FormControl style={{width:"100%"}}>*/}
									{dimensions}
								{/*</FormControl>*/}
							</div>
						</Grid>
					</Grid>
					<div className={classes.statusContainer}>
						{this.state.submittedanswer && <p>{t("Great! Your answer has been saved.")}</p>}
					</div>
					<Container className={classes.buttonContainer}>
						<ActionButton
							size="auto"
							type="submit"
							nomargin={true}
							centered={true}
							onClick={(e) => this.handleSubmit(e)}
						>
							{t('Submit')}
						</ActionButton>
					</Container>
					<Container className={classes.buttonContainerAnswers}>
						<ActionButton 
							onClick={(e) => this.handleSubmitAnswer(e)} 
							type="submit" 
							size="auto"
						>
							{t('See correct answer')}
						</ActionButton>
					</Container>
					<Container className={classes.answercontainer}>
						<Collapse in={this.state.showAnswer}>{ReactHtmlParser(answerimg)}</Collapse>
					</Container>
				</div>
			);
		}
		else {
			return (
				<div className={classes.root}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<img alt="" src={CAKEURL} className="responsive" />
						</Grid>
						<Grid item xs={6}>
							<FormControl>
								{dimensions}
							</FormControl>
						</Grid>
					</Grid>
				</div>
			);
		}
	}
}

export default withTranslation()(withStyles(styles)(DropDownGroups));
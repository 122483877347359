import React from 'react';
import { ResponsiveLine } from '@nivo/line'

export default (data,nDays,t) => (
    <ResponsiveLine
        data={data}
        colors={{ scheme: "set1" }}
        xScale={{
          type: "time",
          format: "%d-%m-%Y"
        }}
        xFormat="time:%d-%m-%Y"
        yScale={{
          type: "linear",
          stacked: false
        }}
        colors="#ffe682"
        pointSize={14}
        pointColor="#FFFFFF"
        pointBorderWidth={4}
        pointBorderColor="#69EF72"
        pointLabel="y"
        pointLabelYOffset={-12}
        axisLeft={{
          format: e => Math.floor(e) === e && e,
          legend: t('Number of employees'),
          legendOffset: -50,
          legendPosition: "middle"
        }}
        axisBottom={{
          format: "%b %d",
          tickValues: "every "+nDays+" days",
          tickRotation: 45,
          // tickRotation: -90,
          legend: "time scale",
          legendOffset: 50,
          legend: t("Date"),
          legendPosition: "middle",
        }}
        curve="monotoneX"
        enablePoints={true}
        useMesh={true}
        margin={{ top: 10, right: 40, bottom: 60, left: 80 }}
        animate={true}
      />
);
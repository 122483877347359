/**
 * Description: this file contains the main base component for
 *  the rendering the page view of analytics "Ideas"
 *
 * Components: AnalyticsReadMore3
 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
// Style components
import Container from "@material-ui/core/Container";
// core components
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/TableIdeas.jsx";

import { n_comp_nly_3 } from "assets/jss/incept-sustainability-variables.jsx";

import axios from "axios";
import pattern from "assets/img/pattern01.png";
import GridItem from "components/Grid/GridItem.jsx";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import { save_fav_id } from "assets/jss/incept-sustainability-variables.jsx";
import { save_label, isAuthenticated, ourYellow} from "assets/jss/incept-sustainability-variables.jsx";


import jwt from "jwt-decode";

import icon from "assets/img/Icons-36.png";
import GridContainer from "components/Grid/GridContainer.jsx";
//Translations
import { withTranslation, Trans } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";
const style = {
	typo: {
		paddingLeft: "25%",
		marginBottom: "40px",
		position: "relative",
	},
	titlepackage: {
		fontWeight: "300",
		lineHeight: "1.5em",
	},
	lineunder: {
		display: "block",
		border: "1px solid #116628",
		marginLeft: "-60px",
		width: "50%",
		marginBottom: "40px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	buttoncolor: {
		backgroundColor: "#0095a9",
		borderColor: "#0095a9",
	},
	containerother: {
		paddingRight: "25px",
		paddingLeft: "25px",
		marginRight: "auto",
		marginLeft: "auto",
		marginTop: "0px !important",
	},
	gridItem: {
		width: "100%",
	},
	image: {
		width: "20%",
		height: "100%",
		padding: "10px 10px 10px 10px",
		"@media only screen and (max-width: 960px)": {
			height: "25vh",
			width: "24vh",
			marginTop: "50px",
			marginLeft: "8vh",
		},
	},

	header: {
		display: "flex",
		flexDirection: "row",
		"@media only screen and (max-width: 960px)": {
			flexDirection: "column",
		},
	},
	headerText: {
		display: "flex",
		flexDirection: "column",
		marginTop: "220px",
		fontWeight: "bold",
		"@media only screen and (max-width: 960px)": {
			marginLeft: "20px",
			marginTop: "0px"
		},
	},
	cardBox: {
		borderRadius: "2rem",
		marginTop: "10px",
	},
	bodyHeader: {
		display: "flex",
		flexDirection: "row",
	},
	imageIcon: {
		width: "8%",
		height: "100%",
		padding: "15px 15px 15px 15px",
	},
	paragraph: {
		width: "70%",
		marginTop: "20px",
		marginLeft: "10px",
		fontSize: "95%",
	},
	backgroundPatternImage: {
		width: "23%",
		zIndex: "0",
		right: "0",
		top: "0",
		position: "absolute",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	pagination: {
		display: "flex"
	},
	title: {
		fontSize: "60px",
		"@media only screen and (max-width: 960px)": {
			fontSize: "40px"
		}
	}
};

const ideaBoxStyle = {
	favorite: {
		color: "white",
		textAlign: "right",
	},
	iconFavorite: {
		fontSize: "25px",
		"&.is-favorite": {
			color: ourYellow,
		},
	},
};

/*const IdeaBox = withStyles(ideaBoxStyle)((props) => {
	const { classes, description, isFavorite, onClick } = props;
	return (
		<div className={classes.root}>
			<div className={classes.favorite}>
				<IconButton onClick={onClick}>
					<Icon
						className={classNames(classes.iconFavorite, "fa fa-star", {
							"is-favorite": isFavorite,
						})}
					/>
				</IconButton>
			</div>
		</div>
	);
});*/

const invertDirect = {
	asc: "desc",
	desc: "asc",
};

const columnnumber = {
	Date: 1,
	Module: 2,
	Idea: 3,
	Favorites: 4,
	Label: 5,
};

const columnname = {
	1: "dateidea",
	2: "module",
	3: "description",
	4: "isFavorite",
	5: "label",
};

/*
 * Component: AnalyticsReadMore3
 * Description: renders the cards for the analytics see more 3 (Ideas)
 */
class AnalyticsReadMore3 extends React.Component {
	constructor(props) {
		super(props);
		var decodedtoken = "";
		if (isAuthenticated()) {
			decodedtoken = jwt(localStorage.getItem("token"));
		}
		this.state = {
			ideas: [],
			nideas: 0,
			nparticipants: 0,
			loadingfirst: true,
			test: 0,
			columnToSort: 1,
			sortDirection: "desc",
			userid: (decodedtoken!=="") ? decodedtoken.data.user.id:"",
			upperPageBound: 3,
            lowerPageBound: 0,
			currentPage: 1,
			isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
			pageNumbers:[],
			ideasPerPage:20,
			active:[],
			showpagination: false,
			labelsideas: []
		};

		//For pagination
		this.handleClick = this.handleClick.bind(this);
		this.btnDecrementClick = this.btnDecrementClick.bind(this);
		this.btnIncrementClick = this.btnIncrementClick.bind(this);
		this.btnNextClick = this.btnNextClick.bind(this);
		this.btnPrevClick = this.btnPrevClick.bind(this);
		// this.componentDidMount = this.componentDidMount.bind(this);
		this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions)
	}
	
	updateWindowDimensions() {
		if (window.innerWidth<600)
	   	this.setState({ upperPageBound: 1,pageBound:1 });
	}

	handleClick(event) {
		let listid = Number(event.target.id);
		var auxArr = new Array(this.state.ideas.length).fill(false);
		auxArr[listid] = true;
		this.setState({
		  	currentPage: listid,
			  active: auxArr
		});
		//ul li.active").removeClass('active');
		//$('ul li#'+listid).addClass('active');
		this.setPrevAndNextBtnClass(listid);
	}

	setPrevAndNextBtnClass(listid) {
		let totalPage = Math.ceil(this.state.ideas.length / this.state.ideasPerPage);
		this.setState({isNextBtnActive: 'disabled'});
		this.setState({isPrevBtnActive: 'disabled'});
		if(totalPage === listid && totalPage > 1){
			this.setState({isPrevBtnActive: ''});
		}
		else if(listid === 1 && totalPage > 1){
			this.setState({isNextBtnActive: ''});
		}
		else if(totalPage > 1){
			this.setState({isNextBtnActive: ''});
			this.setState({isPrevBtnActive: ''});
		}
	}

	btnIncrementClick() {
		this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
		this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
		let listid = this.state.upperPageBound + 1;
		var auxArr = new Array(this.state.ideas.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage: listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	btnDecrementClick() {
		this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
		this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
		let listid = this.state.upperPageBound - this.state.pageBound;
		var auxArr = new Array(this.state.ideas.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage: listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	btnPrevClick() {
		if((this.state.currentPage -1)%this.state.pageBound === 0 ){
			this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
			this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
		}
		let listid = this.state.currentPage - 1;
		var auxArr = new Array(this.state.ideas.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage : listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	btnNextClick() {
		if((this.state.currentPage +1) > this.state.upperPageBound ){
			this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
			this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
		}
		let listid = this.state.currentPage + 1;
		var auxArr = new Array(this.state.ideas.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage : listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	//Function to order columns
	handleSort = (columnname) => {
		this.setState((state) => ({
			columnToSort: columnnumber[columnname],
			sortDirection:
				state.columnToSort === columnnumber[columnname]
					? invertDirect[state.sortDirection]
					: "asc",
		}));
	};

	//Get ideas from backend
	async getncomplpack() {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem("token");
		
		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		const urlapi = n_comp_nly_3+"all?wpml_language="+ useLang;
		axios
			.get(urlapi , { headers: { Authorization: authstr } })
			.then((response) => {
				console.log(response.data);
				this.setState({ ideas: response.data.ideas, 
					active: new Array(response.data.ideas.length).fill(false),
					nideas: response.data.nideas,
					nparticipants: response.data.pideas,
					labelsideas: response.data.labels_ideas,
					loadingfirst: false
				});
				if (response.data.nideas>this.state.ideasPerPage)
					this.setState({showpagination: true});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	componentDidMount() {
		window.addEventListener("resize", this.updateWindowDimensions());
		this.getncomplpack();
		i18next.on('languageChanged', () => this.getncomplpack());
	}

	//Save when an idea is favorite
	handleFavoriteClick = (e, isfav, index, idea_id) => {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		//modifies array of ideas
		this.setState((state) => {
			const newIdeas = state.ideas.map((item2, i) => {
				let newItem = item2;
				if (item2.ideaid === idea_id) {
					let { isFavorite, ...restItem } = item2;
					newItem = { ...restItem, isFavorite: !isFavorite };
				}
				return newItem;
			});
			return {
				ideas: [...newIdeas],
			};
		});

		const user_id = this.state.userid; //userid
		const insdata = {
			userid: user_id,
			ideaid: idea_id,
			isfavorite: !isfav,
		};

		const token = localStorage.getItem("token"); //token needed
		const authstr = "Bearer " + token;
		//post
		this.setState({ loading: true }, () => {
			axios
				.post(save_fav_id+"favorite", insdata, { headers: { Authorization: authstr } })
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	//saves value of label on table
	labelCallback = (e, labelid) => {
		var newlabel = e.target.value;
		//modifies array of ideas
		this.setState((state) => {
			const newIdeas = state.ideas.map((item2, i) => {
				let newItem = item2;
				if (item2.ideaid === labelid) {
					let { label, ...restItem } = item2;
					newItem = { ...restItem, label: newlabel };
				}
				return newItem;
			});
			return {
				ideas: [...newIdeas],
			};
		});

		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		
		const user_id = this.state.userid; //userid
		const insdata = {
			userid: user_id,
			newlabel: e.target.value,
			labelid: labelid,
		};

		const token = localStorage.getItem("token"); //token needed
		const authstr = "Bearer " + token;
		//post
		axios
			.post(save_label+"label", insdata, { headers: { Authorization: authstr } })
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//saves when a new label is added
	/*labelCallback_save = (e,labelid) => {

		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		
		const user_id = this.state.userid; //userid
		const insdata = {
			userid: user_id,
			newlabel: e.target.value,
			labelid: labelid,
		};

		const token = localStorage.getItem("token"); //token needed
		const authstr = "Bearer " + token;
		//post
		axios
			.post(save_label+"label", insdata, { headers: { Authorization: authstr } })
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};*/

	//Order array of objects
	_sortArray(array, property, direction) {
		direction = direction || 1;
		array.sort(function compare(a, b) {
			let comparison = 0;
			if (a[property] > b[property]) {
				comparison = 1 * direction;
			} else if (a[property] < b[property]) {
				comparison = -1 * direction;
			}
			return comparison;
		});
		return array; // Chainable
	}

	//renders the component
	render() {
		const { classes,t } = this.props;
		var ideas = this.state.ideas;
		var ideas_array = [];

		if (this.state.ideas.length > 0) {
			//Creates an array of ideas to create the table
			if (this.state.sortDirection==="desc") {
				ideas = this._sortArray(ideas, columnname[this.state.columnToSort], -1);
			}
			else {
				ideas = this._sortArray(ideas, columnname[this.state.columnToSort]);
			}

			const indexOfLastTodo = this.state.currentPage * this.state.ideasPerPage;
            const indexOfFirstTodo = indexOfLastTodo - this.state.ideasPerPage;
			const currentIdeas = ideas.slice(indexOfFirstTodo, indexOfLastTodo);

			currentIdeas.map((item, index) => {
				ideas_array.push([
					item.ideaid,
					item.dateidea,
					item.module,
					item.description,
					item.isFavorite,
					item.label,
				]);
				return item;
			})
			

			// Logic for displaying page numbers			
			let pageNumbers2 = [];
			for (let i = 1; i <= Math.ceil(ideas.length / this.state.ideasPerPage); i++) {
				pageNumbers2.push(i);
			}

			const renderPageNumbers = pageNumbers2.map(number => {
                if(number === 1 && this.state.currentPage === 1){
					this.state.pageNumbers[number] = true;
                    return(
                        <li key={number} className={'active'}  id={number}>
							<a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
                    )
                }
                else if((number < this.state.upperPageBound + 1) && number > this.state.lowerPageBound){
                    return(
                        <li key={number} className={this.state.active[number] ? 'active' : ''} id={number}>
							<a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
                    )
                }
				return number;
            });
			let pageIncrementBtn = null;
            if(pageNumbers2.length > this.state.upperPageBound){
                pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip; </a></li>
            }
			let pageDecrementBtn = null;
            if(this.state.lowerPageBound >= 1){
                pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
            }
			let renderPrevBtn = null;
            if(this.state.isPrevBtnActive === 'disabled') {
                renderPrevBtn = <li className={this.state.isPrevBtnActive}><span id="btnPrev"> Prev </span></li>
            }
			else{
                renderPrevBtn = <li className={this.state.isPrevBtnActive}><a href='#' id="btnPrev" onClick={this.btnPrevClick}> Prev </a></li>
            }
			let renderNextBtn = null;
            if(this.state.isNextBtnActive === 'disabled') {
                renderNextBtn = <li className={this.state.isNextBtnActive}><span id="btnNext"> Next </span></li>
            }
            else{
                renderNextBtn = <li className={this.state.isNextBtnActive}><a href='#' id="btnNext" onClick={this.btnNextClick}> Next </a></li>
            }


			return (
				<div className={classes.containerother} style={{ marginTop: "100px" }}>
					{
						<img
							className={classes.backgroundPatternImage}
							src={pattern}
							alt="background patterns"
						/>
					}
					<GridContainer style={{ marginTop: "50px" }}>
						<GridItem xs={12} sm={12} style={{ position: "relative",marginBottom: "60px", marginTop:"50px"  }}>
							<div style={{ position: "absolute", zIndex: 10 }}>
								<p className={classes.title} style={{ fontWeight: "bold" }}>
								{/*<img
									className={classes.image}
									src={ideasImage}
									alt={"Idea Image"}
								/>*/}
								{t("Ideas")+(" ")}
								<span style={{fontSize:"14px"}}>
								({this.state.nideas + (" ") + t("ideas submitted")})
								</span>
								</p>
							</div>
						</GridItem>
					<div style={{overflow:"auto"}}>
					<Card className={classes.cardBox}>
						<CardBody>
							<div className={classes.bodyHeader}>
								<img
									className={classes.imageIcon}
									src={icon}
									alt={"icon image"}
								/>
								<p className={classes.paragraph}>
								<Trans
									i18nKey="textofideas" // optional -> fallbacks to defaults if not provided
									defaults="A total of {{nideas}} and comments have been have been collected. 
									These have been submitted by {{nparticipants}}% of the participants.
									Below you can read all of them, star the ones you like the most and sort them into categories." // optional defaultValue -> <0> represents the index in "components" array which makes it easy
									values={{ nideas:this.state.nideas,nparticipants:this.state.nparticipants}} // useful when you need to add dynamic data in form of variables and not components
								/>
                				</p>
							</div>
							<Table
								tableHeaderColor="success"
								tableHead={[
									"",
									t("Date"),
									t("Module"),
									t("Idea"),
									t("Favorites"),
									t("Label"),
								]}
								tableData={ideas_array}
								handleSort={this.handleSort}
								clickfunction={this.handleFavoriteClick}
								columnToSort={this.state.columnToSort}
								sortDirection={this.state.sortDirection}
								editlabelfunction={this.labelCallback}
								labelsIdeas={this.state.labelsideas}
							/>
							{this.state.showpagination && <div className={classes.pagination}>
								<ul id="page-numbers" className="pagination">
								{renderPrevBtn}
								{pageDecrementBtn}
								{renderPageNumbers}
								{pageIncrementBtn}
								{renderNextBtn}
								</ul>
							</div>}
						</CardBody>
					</Card>
					</div>
					</GridContainer>
				</div>
			);
		} else {
			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
					<div
						className={classes.containerother}
						style={{ marginTop: "100px" }}
					>
						<Container>There are no ideas yet.</Container>
					</div>
				</LoadingOverlay>

			);
		}
	}
}

AnalyticsReadMore3.propTypes = {
	classes: PropTypes.object,
};

export default withTranslation()(withStyles(style)(AnalyticsReadMore3));

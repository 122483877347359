/*!
* Description: this file contains the component for
*  the type of exercise ImageHover, ex. guide 3, step 2, 
*  					"  Exercise: Exercise: The SDGs"
* Components: ImageHover
*/
import React from "react";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";

// Effects
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
	root: {
		paddingTop: "20px"
	},
	imgsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: "center",
  		justifyContent: "center"
	},
	imgBlockFlex: {
		padding: '10px'
	},
	imgBlock: {
		backgroundColor: "#78909cff",
		height: '150px',
		width: '150px',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	}
};

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

/* 
* component that render exercise of image hover
*/
class ImageHover extends React.Component {

	state = {
		tooltipOpenID: ""
	}

	handleTooltipClick(e, tooltipID) {
		this.setState({
			tooltipOpenID: tooltipID
		});
	}

	_renderImage(image, index) {
		const { classes } = this.props;
		const { imageURL, title } = image;
		return (
			<div key={index} className={classes.imgBlockFlex}>
				<TextOnlyTooltip title={title} 
				placement="top"
				disableFocusListener
				disableHoverListener
				disableTouchListener
				open={index === this.state.tooltipOpenID}
				onClick={(e) => this.handleTooltipClick(e, index)}
				>
					<div key={index} className={classes.imgBlock} 
					style={{ backgroundImage: `url(${imageURL})` }} />
				</TextOnlyTooltip>
			</div>
		);
	}

	_renderImages(images) {
		const { classes } = this.props;
		const imgs = images.map(
			(image, index) => this._renderImage(image, index)
		);
		return (
			<div className={classes.imgsContainer}>
				{imgs}
			</div>
		);
	}

	render() {
		const { classes, exercise } = this.props;
		const { images } = exercise;
		return (
			<div className={classes.root}>
				{this._renderImages(images)}
			</div>
		);
	}
}

export default withStyles(styles)(ImageHover);
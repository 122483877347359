import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";

import log from 'loglevel';

import GridContainer from "components/Grid/GridContainer.jsx";
import {Link } from "react-router-dom";

import i18n from "i18next";

import {
	Card,
	CardImg,
	CardBody,
	CardSubtitle,
} from "reactstrap";


import modules_patter from "assets/img/modules_pattern.png";

import axios from "axios";
import { IconButton, Grid } from "@material-ui/core";
import icon7 from "assets/img/icon7.png";

import jwt from "jwt-decode";

import { is_g_comp, ourGreen, ourBlue,  ourOrange,isAuthenticated } from "assets/jss/incept-sustainability-variables.jsx";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton";

//Modals
import Modal from "react-bootstrap/Modal";
import { default as CustomModal } from "components/Modal/Modal.jsx";
import ReactHtmlParser from "react-html-parser";

//Certificate PDF
//import Certificate from "components/PDF/Certificate";
//import { BlobProvider } from '@react-pdf/renderer';
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

//Translations
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
//import ErrorBoundary from "components/Log/ErrorBoundary.jsx";

const style = (theme) => ({
	typo: {
		paddingLeft: "25%",
		marginBottom: "40px",
		position: "relative",
	},
	titlepackage: {
		fontFamily: "Raleway,Helvetica,Arial,sans-serif",
		fontWeight: "600",
		marginBottom: "1.5rem",
		paddingTop: "30px",
		//marginLeft: "1.2rem"
	},
	lineunder: {
		display: "block",
		border: "1px solid #116628",
		marginLeft: "-60px",
		width: "50%",
		marginBottom: "40px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	buttoncolor: {
		backgroundColor: "yellow",
		borderColor: "#0095a9",
	},
	button: {
		width: "110px",
		backgroundColor: ourBlue,
		color: "white",
		border: "1px",
		borderRadius: "20px",
		cursor: "pointer",
		fontSize: "15px",
		padding: "2px 8px",
		display: "block",
		position: "inherit",
		"&:hover": {
			backgroundColor: ourBlue,
			opacity: "0.6"
		},
		[theme.breakpoints.down("md")]: {
			marginBottom: "5px",
		},
	},
	patternBackgroundImage: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	wrapper: {
		marginTop: "-9.5rem",
		marginRight: "2rem",
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
	},
	containerother: {
		paddingLeft: "40px",
		marginRight: "auto",
		marginLeft: "auto",
		[theme.breakpoints.down("sm")]: {
			padding: "0 25px",
		},
	},
	kickOff: {
		borderRadius: "2.2rem",
		padding: "10px 10px 0 10px",
		backgroundColor: ourGreen,
		border: "none",
		height: "100%",
		"& p, strong": {
			fontSize: "1rem",
		},
		"& p": {
			fontSize: ".875rem",
		}
	},
	didYouKnow: {
		borderRadius: "2.2rem",
		padding: "1rem 1rem 0 1rem",
		backgroundColor: "white",
		height: "100%",
		"& p, strong": {
			color: "black",
			fontSize: "1rem",
			[theme.breakpoints.down("sm")]: {
				marginRight: "0",
			},
		},
		"& p": {
			fontSize: ".875rem",
		}
	},
	didYouKnowItem: {
		paddingBottom: "20px", 
	},
	moduleCard: {
		borderRadius: "2.2rem",
		//marginBottom: "2.2rem",
		padding: "20px 10px",
		marginBottom: "10px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "20px"
		},
	},
	moduleCardWrapper1: {
		margin: "2rem auto 0 auto",
		padding: "3%",
		textAlign: "center",
		backgroundColor: ourOrange,
		borderRadius: "50%",
		width: "70%",
		[theme.breakpoints.down("sm")]: {
			width: "45%",
		},
	},
	moduleCardWrapper: {
		margin: "2rem auto 0 auto",
		padding: "3%",
		textAlign: "center",
		backgroundColor: "#CBCBCB",
		borderRadius: "50%",
		width: "70%",
		[theme.breakpoints.down("sm")]: {
			width: "45%",
		},
	},
	moduleCardInnerWrapper: {
		backgroundColor: "white",
		borderRadius: "50%",
		width: "100%",
		height: "100%",
		margin: "auto",
		[theme.breakpoints.down("md")]: {},
	},
	"@supports not (-ms-ime-align: auto)": {
		buttonContainer: {
		  justifyContent: "space-evenly !important"
		}
	  },
	buttonContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "space-around",
		margin: "1rem 0",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
			alignItems: "center",
		},
		[theme.breakpoints.down("sm")]: {
			flexDirection: "row",
		},
	},
	moduleCardInnerWrapper1: {
		backgroundColor: "white",
		borderRadius: "50%",
		width: "100%",
		height: "100%",
		margin: "auto",
	},
	moduleTitle: {
		marginTop: "40px",
		fontWeight: "bold",
		[theme.breakpoints.down("sm")]: {
			marginTop: "1rem",
		},
	},
	guideTitle: {
		marginTop: "2px",
		fontSize: "0.85rem",
		fontWeight: "500",
	},
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right"
	},
	modalBox: {
		"& .modal-content": {
			borderRadius: "2rem",
		}
	},
	infopackage: {
		marginBottom: "50px"
	},
});

class GuidesPage extends React.Component {
	constructor(props) {
		super(props);
		this.routeChange = this.routeChange.bind(this);
		var decodedtoken = "";
		if (isAuthenticated()) {
			decodedtoken = jwt(localStorage.getItem("token"));
		}
		const hasEverShowedEndModulesModal = JSON.parse(localStorage.getItem('hasEverShowedEndModulesModal') ?? 'false');

		this.state = {
			manager: //Determines if it is a manager or a employee
				decodedtoken.role === "Manager" || decodedtoken.role === "Admin"
					? true
					: false,
			numModules: 0,
			all_modules: [],
			allcompleted: 0,
			showAboutModal: false,
			aboutModalNumber: 0,
			aboutModalTitle: "",
			aboutModalDes: "",
			aboutModalImg: "",
			loading: true,
			company: (decodedtoken!=="") ? decodedtoken.company:"",
			userid: (decodedtoken!=="") ? decodedtoken.data.user.id:"",
			showEndModulesModal: !hasEverShowedEndModulesModal,
			errorBoundary: false,
			errorMessage: "",
			complete_before: "",
			greenbox: "",
			whitebox: ""
		};
	}



	showHideModal = (moduleNumber, moduleTitle, description, img) => {
		try {
			this.setState({
				showAboutModal: !this.state.showAboutModal,
				aboutModalNumber: moduleNumber,
				aboutModalTitle: moduleTitle,
				aboutModalDes: description,
				aboutModalImg: img
			});
		}
		catch(error) {
			throw new Error("Component: GuidesPage - showHideModal");
		}
	};

	handleEndModulesModalHide = () => {
		try {
			this.setState({ showEndModulesModal: false });
		}
		catch (error) {
			throw new Error("Component: GuidesPage - handleEndModulesModalHide");
		}
	};

	error_ocurred = (msg = "") => {
		this.setState({errorBoundary:true,errorMessage:msg})
	}

	/*
	 * Getting info about guides
	 *
	 */
	async getcompletedguides() {
		this.setState({ loading: true });
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem("token");

		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		axios
			.get(is_g_comp + "?wpml_language=" + useLang, { headers: { Authorization: authstr } })
			.then((response) => {
				console.log(response);
				//let completed = Object.entries(response.data.completed_guides);
				let modules = response.data.modules;
				let started = Object.entries(response.data.guideStarted);
				let date_completed = response.data.date_completed;
				for (const [guideid, isstarted] of started) {
					const index = modules.findIndex(module => module.id === guideid);
					if (index !== -1)
						modules[index].started = isstarted;
				}
				this.setState({
					numModules: response.data.nModules,
					all_modules: modules,
					date_completed: date_completed,
					complete_before: response.data.completeb,
					userrealname: response.data.flname,
					allcompleted: modules.every((elem) => elem.completed === 1),
					loading: false,
					greenbox: response.data.greenbox,
					whitebox: response.data.whitebox
				});
			})
			.catch((err) => {
				//this.error_ocurred("Error API "+is_g_comp+" "+err);
				log.error("Error API "+is_g_comp+" "+err);
				this.setState({ error: err, loading: false, errorMessage:  err });
				this.setState({ loadingfirst: false });
			});
	}
	componentDidMount() {
		this.getcompletedguides();
		console.log("Mount component");
		i18next.on('languageChanged', () => this.getcompletedguides());
	}
	/*
	 *
	 */

	routeChange() {
		let path = `/guidesNav/1`;
		this.props.history.push(path);
	}
	toggle() {
		try {
			this.setState((prevState) => ({
				modal: !prevState.modal,
			}));
		}
		catch(error) {
			throw new Error("Component: GuidesPage - toggle");
		}
	}

	button_word(started,completed,t) {
		if (started===1 && completed===1) {
			return t("Recap");
		}
		else if (started===1) {
			return t("Continue");
		}
		else {
			return t("Start");
		}
	}

	render() {
		const { classes, t } = this.props;
		const list_modules = this.state.all_modules.map((module_guide, index) => {
				let compbef = module_guide.complete_before;
				let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
				let dt = new Date(compbef.replace(pattern,'$3-$2-$1'));
				let today = new Date();
				let isAfter = (today>dt);
			return (
			<GridItem xs={12} sm={6} md={3} spaceLeft={0} key={index}>
				<Card className={classes.moduleCard} key={index}>
					<div className={module_guide.completed === 1 ?
						classes.moduleCardWrapper1 : classes.moduleCardWrapper}>
						{" "}
						<div className={classes.moduleCardInnerWrapper1}>
							<CardImg
								className="mx-auto d-block"
								top
								// height="120"
								src={(module_guide.completed === 1 ? module_guide.logo_aft : module_guide.logo_bef)}
								alt="Card image cap"
							/>
						</div>
					</div>
					<div style={{ textAlign: "center" }}>
						<CardSubtitle className={classes.moduleTitle}>
							{t("Module Number", { number: index + 1, defaultValue: "Module {{number}}" })}
						</CardSubtitle>
						<CardSubtitle className={classes.moduleTitle} style={{margin:"5px 0 10px 0"}}>
							{module_guide.name}
						</CardSubtitle>
					</div>
					<CardBody style={{padding:"0"}}>  
					<div style={{textAlign:"center"}}>
						{module_guide.type!==null && <CardSubtitle className={classes.guideTitle}>
						<span style={{fontWeight:"700"}}>{t("Type")+": "}</span>{module_guide.type}
						</CardSubtitle>}
						{module_guide.complete_before!=="" && <CardSubtitle className={classes.guideTitle}>
						<span style={{fontWeight:"700",color:(isAfter ? "red":"initial")}}>{(!isAfter) ? (t("Complete before")+": "):(t("Overdue")+": ")}</span>
						{module_guide.complete_before}
						</CardSubtitle>}
						{module_guide.module_length!==null && <CardSubtitle className={classes.guideTitle}>
						<span style={{fontWeight:"700"}}>{t("Module length")+": "}</span>{module_guide.module_length}
						</CardSubtitle>}
						</div>  
					</CardBody>                
					<div className={classes.buttonContainer}>
						{/*<Link to={(module_guide.started === 1
							? "/guides/" + module_guide.id + "/1" : "/guides/" + module_guide.id + "/0")}>
							<ActionButton size="auto" className={classes.button}>
								{this.button_word(module_guide.started,module_guide.completed)}
							</ActionButton>
						</Link>*/}
						<Link to={"/guides/" + module_guide.id + "/0"}>
							<ActionButton size="auto" className={classes.button}>
								{this.button_word(module_guide.started,module_guide.completed,t)}
							</ActionButton>
						</Link>
						<ActionButton size="auto"
							onClick={(e) => this.showHideModal(index + 1, module_guide.name, module_guide.des, module_guide.img)}
							className={classes.button}>{t('About')}</ActionButton>
					</div>
					{/* </CardBody> */}
				</Card>
			</GridItem>
			);
		});
		if (this.state.errorBoundary) {
			throw new Error("Error: "+this.state.errorMessage);
		}
		return (
			<LoadingOverlay
				active={this.state.loading}
				spinner={<BounceLoader />}
			>
				{this.state.errorMessage==="" &&
				<div className={classes.containerother}>
					<div className={classes.patternBackgroundImage}>
						<img
							width="100%"
							src={modules_patter}
							alt="modules background pattern"
						/>
					</div>
					<div className={classes.wrapper}>
						<h3 className={classes.titlepackage}> {t('Modules')} </h3>
						<GridContainer>
							<GridItem xs={12} sm={12} md={5} space={0} otherclasses={classes.infopackage}>
								<Card className={classes.kickOff}>
									<CardBody style={{ padding: "1rem" }}>
										{ReactHtmlParser(this.state.greenbox)}
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={12} sm={12} md={7} otherclasses={classes.infopackage}> 
								<Card className={classes.didYouKnow}>
									<CardBody
										style={{
											color: "white",
											padding: ".4rem",
										}}
									>
										{ReactHtmlParser(this.state.whitebox)}
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
						<div className={classes.didYouKnowItem}>
						<GridContainer>
								{this.state.manager &&
								<GridItem xs={12} >
									<Card className={classes.didYouKnow}>
										<CardBody
											style={{
												color: "white",
												padding: ".4rem",
											}}
										>
											<p style={{ fontSize: ".875rem" }}>
											{t('Analytics not record')}
											</p>
										</CardBody>
									</Card>
								</GridItem>
								}
								{!this.state.manager && this.state.company==="GPA Global" &&
								<GridItem xs={12} >
									<Card className={classes.didYouKnow}>
										<CardBody
											style={{
												color: "white",
												padding: ".4rem",
											}}
										>
											<p style={{ fontSize: ".875rem" }}>
											Dear users, we have made a system update to make it 
											easier to navigate the modules. If you have already started your training, 
											you will be missing some green check-marks in the material you have already 
											completed - this has no effect on your logged completion in our system. 
											Please reach out in the Contact form if you have any questions.
											</p>
										</CardBody>
									</Card>
								</GridItem>
								}
								{false && (!this.state.loading) && this.state.all_modules.length > 0 && this.state.all_modules.every((elem) => elem.completed === 1) &&
								<GridItem xs={12} >
									<Card className={classes.didYouKnow}>
										<CardBody
											style={{
												color: "white",
												padding: ".4rem",
											}}
										>
											<p>{t('Congratulations on finishing the package!')}&nbsp;&nbsp;&nbsp;&nbsp;
                      								<ActionButton size="large"
													onClick={() => this.setState({ showEndModulesModal: true })}>{t('Get certificate')}</ActionButton></p>
										</CardBody>
									</Card>
								</GridItem>
							}
						</GridContainer>
						</div>
						<GridContainer>

							{list_modules}


						</GridContainer>
						<Modal
							id="3"
							show={this.state.showAboutModal}
							onHide={() => this.setState({ showAboutModal: false })}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
								<IconButton onClick={() => this.setState({ showAboutModal: false })}
									className={classes.modalCancelButton}>
									<img
										src={icon7}
										alt="cancel icon"
										style={{ width: "40px", height: "40px" }}
									/>
								</IconButton>
								<div style={{ margin: "20px" }}>
									<h4 style={{ marginBottom: "15px" }}>
										{t('Module Number and Title',
											{
												modulenumber: this.state.aboutModalNumber,
												moduletitle: this.state.aboutModalTitle,
												defaultValue: "Module {{modulenumber}}: {{moduletitle}}!"
											}
										)}
									</h4>
									<GridContainer>
										<GridItem xs={12} sm={4} md={4} spaceLeft={0}>
											<img
												src={this.state.aboutModalImg}
												alt="cancel icon"
												className={"responsive"}
											/>
										</GridItem>
										<GridItem xs={12} sm={8} md={8} space={0}>
											{ReactHtmlParser(this.state.aboutModalDes)}
										</GridItem>
									</GridContainer>
								</div>
							</Modal.Body>
						</Modal>
						<CustomModal
							show={false && (!this.state.loading) && this.state.all_modules.length > 0 && this.state.all_modules.every((elem) => elem.completed === 1)
								&& this.state.showEndModulesModal}
							onHide={this.handleEndModulesModalHide}
							onShow={() => localStorage.setItem('hasEverShowedEndModulesModal', JSON.stringify(true))}
						>
							<h2>{t('Congratulations')}</h2>
							<h5>{t('You have finished the Incept Sustainability learning course')}</h5>
							<Grid container spacing={2}>
								<Grid item sm style={{ textAlign: "right" }}>
									{/*this.state.userrealname && this.state.date_completed ?
										<BlobProvider document={<Certificate name={this.state.userrealname} date={this.state.date_completed} />} fileName="certificate.pdf" >
											{({ blob, url, loading, error }) =>
											(loading ? t('Loading document', { defaultValue: 'Loading document...' })
												: <ActionButton size="large" href={url} target="_blank">{t('Download PDF!')}</ActionButton>)}
										</BlobProvider>
										: "Loading data..."
									*/}
								</Grid>
								<Grid item sm>
									<ActionButton size="large" lighten onClick={this.handleEndModulesModalHide}>
										{t('Cancel')}
									</ActionButton>
								</Grid>
							</Grid>
						</CustomModal>
					</div>
				</div>
				}
				{this.state.errorMessage!=="" &&
				<div className={classes.containerother}>
					<div className={classes.gridWrappper} style={{marginTop:"30px"}}>
					<h2>Well, this was unexpected. </h2>
					<p>
						Sorry, this is not working properly. We now know about
						it and are working to fix it.
					</p>
					<p>In the meantime, here is what it can be done:
						<ul>
							<li>Refresh the page (sometimes this helps).</li>
							<li>Make sure you have internet connection.</li>
							<li>Try again in 30 minutes.</li>
							<li>Email us at inceptsustainability@ramboll.com and tell us what happened.</li>
						</ul>
					</p>
					</div>
				</div>
				}
			</LoadingOverlay>
		);
	}
}

GuidesPage.propTypes = {
	classes: PropTypes.object,
};

export default withTranslation()(withStyles(style)(GuidesPage));

const cardBodyStyle = {
  cardBody: {
    padding: "30px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  exerciseBody: {
    padding: "30px 0",
    "@media only screen and (max-width: 960px)": {
      padding: "0",
      },
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  cardBodyProfile: {
    marginTop: "15px"
  }
};

export default cardBodyStyle;

/*!
* Description: this file contains the component for
*  the section "example"
* Components: GuideExample
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@material-ui/core/Container';
import withStyles from "@material-ui/core/styles/withStyles";
import arrowdown from 'assets/img/down-arrow.png';
import checkmark from 'assets/img/checkmark.png';
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Collapse from "@material-ui/core/Collapse";
//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {},
	cardExample: {
		borderRadius: "2rem",
		marginBottom: "10px"
	},
	cardBodyText: {
		marginTop: "20px"
	},
	iconNavBack: {
		fontSize: '30px',

	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "3px 20px 20px 0",
		paddingRight: "0",
		"@media only screen and (max-width: 960px)": {
			margin: "3px 20px 20px 0",
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
		cursor: "pointer",
	},
	iconDownArrow: {
		cursor: "pointer",
		width: "50px"
	}
};

class GuideExample extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			isdefaultView: (this.props.videoContent!=="") ? true:false,
			defaultView: (this.props.defaultView==="Video content") ? false:true,
			loading: true
		}
	}

	componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps);
		if (this.state.loading) {
			console.log(nextProps.videoContent);
			console.log(nextProps.videoContent.length);
			this.setState({isdefaultView:(nextProps.videoContent.length!==0) ? true:false,
				defaultView: (nextProps.defaultView==="Video content") ? false:true, loading: false});
		}
    }

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

	render() {
		const { classes, title,textContent,videoContent, t,goNextSection,saveresloc,
			isLastSection,showcard,openSection,datasav,smoothtrans } = this.props;
		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					<Card className={classes.cardExample}>
						<CardBody style={(!showcard) ? {padding: "0 30px",margin: "1rem 0 0.2rem 0"}:{}}>
							
							<GridContainer justify="center" nonunset={true}>
								<GridItem xs={10} sm={10} md={10} 
								lg={10} spaceLeft={0}>
									{!showcard && <GuideCardTitle>{t('Example')}: {title}</GuideCardTitle>}
								</GridItem>
								<GridItem xs={2} sm={2} md={2} 
								lg={2} spaceLeft={0} style={{cursor:"pointer"}} onClick={openSection}>
									<div className={`${classes.iconDownArrowDiv}`} style={{paddingTop:"7px",textAlign:"right"}}>
										<img
											src={checkmark}
											style={{width:"30px",marginRight:"20px", 
											marginTop: showcard ? "-27px":"-5px",
											visibility: (typeof  datasav !== "undefined") ? "visible":"hidden"}}
											alt="Completed section"
											title="Completed section"		
										/>
										<img
											src={arrowdown}
											style={showcard ? {transform:"rotate(-180deg)", marginTop:"-22px"}:{}}
											alt="Go to next section"
											title="Go to next section"									
											className={classes.iconDownArrow}
										/>
									</div>
								</GridItem>
							</GridContainer>
							{showcard && 
							<GridContainer justify="center" nonunset={true}>
								<GridItem sm={(this.state.isdefaultView) ? 6:12} md={(this.state.isdefaultView) ? 8:12} 
								lg={(this.state.isdefaultView) ? 8:12} spaceLeft={0}>
									<GuideCardTitle>{t('Example')}: {title}</GuideCardTitle>
								</GridItem>
								<GridItem sm={(this.state.isdefaultView) ? 6:0} md={(this.state.isdefaultView) ? 4:0} 
								lg={(this.state.isdefaultView) ? 4:0} spaceLeft={0}>
									{showcard && this.state.isdefaultView && <Container className={classes.buttonContainer}>
										<ActionButton 
											onClick={(e) => this.handleSubmit(e)} 
											type="submit" 
											size="auto"
											centered={true}
											nomargin={true}
										>
											{(this.state.defaultView) && t('See video instead')}
											{(!this.state.defaultView) && t('See text instead')}
										</ActionButton>
									</Container>}
								</GridItem>
							</GridContainer>}
							<Collapse in={showcard} 
							addEndListener={() => {
								if (showcard) {
									saveresloc();
									setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
								}
							}} >
							{(this.state.defaultView) && <div className={classes.cardBodyText}>
								{textContent}</div>}
							{(!this.state.defaultView) && <div className={classes.cardBodyText}>
								{videoContent}</div>}	
						{!isLastSection && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
							<img
								src={arrowdown}
								alt="Go to next section"
								title="Go to next section"
								
								className={classes.iconDownArrow}
							/>
						</div>}
						</Collapse>
						</CardBody>						
					</Card>
				</Container>
			</div>
		);
	}
}

GuideExample.defaultProps = {
	children: (<p>Here comes the content for this Example</p>),
	title: 'Example',
};


export default withTranslation()(withStyles(styles)(GuideExample));
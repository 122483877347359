/*!
* Description: this file contains the component for
*  the sections that contain videos
* Components: GuideVideoCard
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@material-ui/core/Container';
import Ikon from "components/Ikon/Ikon.js";

//Styles
import withStyles from "@material-ui/core/styles/withStyles";

import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";

const styles = {
	root: {},
	cardExample: {
		backgroundColor: '#fff',
		color: 'black',
	},
	cardHeader: {

	},
	cardBody: {
		textAlign: 'center'
	},
	iconSection: {
		fontSize: '30px',
		marginLeft: '10px'
	}
};

class GuideVideoCard extends React.Component {

	render() {
		const { classes, title, body, videoURL } = this.props;
		return (
			<div className={classes.root}>
				<Container>
					<Card className={classes.cardExample}>
						<CardHeader className={classes.cardHeader}>
							<GuideCardTitle><Ikon url="/img/guides-hardcoded/icons/video-player.png" /> {title}</GuideCardTitle>
						</CardHeader>
						<CardBody className={classes.cardBody}>
							<div className={classes.cardBodyText}>
								{body}
							</div>
							<iframe key={title} title={title} id="ytplayer" type="text/html" width="720" height="405" src={videoURL} frameBorder="0" allowFullScreen />
						</CardBody>
					</Card>
				</Container>
			</div>
		);
	}
}

export default withStyles(styles)(GuideVideoCard);
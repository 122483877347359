import React from "react";
import BrowserDetection from 'react-browser-detection';
import IEIncompatible from 'components/Feedback/IEIncompatible.js';


class BrowserDetection2 extends React.Component {
	render(){
		const {children} = this.props;
		return (
			<BrowserDetection>
				{{
					ie: () => <IEIncompatible />,
					default: () => children
				}}
			</BrowserDetection>
		);
		
	}
}

export default BrowserDetection2;
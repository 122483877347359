/*!
* Description: this file contains the component for
*  the section "example"
* Components: GuideExampleInteractive
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@material-ui/core/Container';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { ourBlue60,standardRadious,ourBlue } from 'assets/jss/incept-sustainability-variables.jsx';
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
//Buttons
import arrowdown from 'assets/img/down-arrow.png';
import checkmark from 'assets/img/checkmark.png';
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Collapse from "@material-ui/core/Collapse";
//Translations
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";
import { useMediaQuery } from 'react-responsive';
import Grid from "@material-ui/core/Grid";
const styles = {
	root: {},
	cardExample: {
		borderRadius: "2rem",
        marginBottom: "10px"
	},
	cardBodyText: {
		marginTop: "20px"
	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "5px 20px",
		"@media only screen and (max-width: 960px)": {
			margin: "10px 0"
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
    blockContainer: {
        flex: "1 1 auto",
		padding: "10px 0 0 0",
        "@media only screen and (max-width: 960px)": {
			height: "auto"
		},
    },
    blockContainer_vertical: {
        height: "100%",
		//padding: "10px 0 0 0",
    },
    blockContainer2: {
        padding: "10px 0 0 0",
        cursor: "pointer",
        flex: "0 1 auto",
        "@media only screen and (max-width: 960px)": {
			height: "auto"
		},
	},
    gridItem: {
		textAlign: "center",
		paddingRight: '10px',
		paddingBottom: '50px',
	},
    imgContainer: {
		padding: "0.5rem 1rem",
        height: "100%",
        cursor: "pointer"
    },
	boxContainer: {
        width:'100%',
		borderRadius: standardRadious,
		padding: "1.3rem 1rem",
		height: "100%",
        color: "white",
        "& p": {
            marginBottom: "0"
        }
	},
    boxContainer_vertical: {
        width:'100%',
		borderRadius: standardRadious,
		padding: "1.3rem 1rem",
		height: "70%",
        placeSelf: "center",
        display: "flex",
        color: "white",
        "& p": {
            marginBottom: "0"
        },
        "@media only screen and (max-width: 1500px)": {
			height: "auto"
		}
	},
    subcontainer0: {
        height: "100%",
    },
    containerRow: {
        marginBottom: "30px"
    },
    paddingBox: {
        //padding: "30px 0",
        display: "grid"
    },
    boxContent: {
        fontSize: "1rem"
    },
    visible: {
        visibility: "visible",
        transition: "opacity 1s ease"
    },
    invisible: {
        visibility: "hidden",
        opacity: "0",
        transition: "opacity 1s ease"
    },
    flexcolumn: {
        display: "flex",
        flexFlow: "column",
        height: "100%"
    },
	iconDownArrow: {
		cursor: "pointer",
		width: "50px"
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
        cursor: "pointer",
	}
    
};

const ContentContainer = withTranslation()(withStyles(styles)(
	({ classes, onClick, content, showboxContent, t }) => (
		<React.Fragment>
			<Container style={{placeSelf:"center"}}>
				{/*<Collapse className={classes.boxContent} in={showboxContent}>{ReactHtmlParser(ReactHtmlParser(content))}</Collapse>*/}
                {!showboxContent && <div className={`${classes.subcontainer0} ${classes.boxContent}`} style={{fontStyle:"italic",textAlign:"center"}}>
                    {t("Click on the image to learn more")}                 
                </div>}
                <div className={`${showboxContent ? classes.visible:classes.invisible} ${classes.subcontainer0} ${classes.boxContent}`}>
                    {showboxContent && ReactHtmlParser(ReactHtmlParser(content))}                    
                </div>
			</Container>
		</React.Fragment>
	)
));

const ContentContainerFixed = withTranslation()(withStyles(styles)(
	({ classes, onClick, content, showboxContent, t }) => (
		<React.Fragment>
			<Container className={classes.subcontainer0}>
                {!showboxContent && <div className={`${classes.subcontainer0} ${classes.boxContent}`} style={{fontStyle:"italic",textAlign:"center"}}>
                    {t("Click on the image to learn more")}                 
                </div>}
				<div className={`${showboxContent ? classes.visible:classes.invisible} ${classes.subcontainer0}`}>
                    {ReactHtmlParser(ReactHtmlParser(content))}
                    {showboxContent && <br></br>}
                </div>
			</Container>
		</React.Fragment>
	)
));

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

class GuideExampleInteractive_b extends React.Component {

	constructor(props){
		super(props);
        const { boxes } = props;
		const { options } = boxes;
		this.state = {
			isdefaultView: (this.props.videoContent!="") ? true:false,
			defaultView: (this.props.defaultView==="Video content" ) ? false:true,
			loading: true,
            contentExample: options,
            contentBox: 0,
			defaultFirst: (this.props.defaultFirst) ? true: false,
			showboxContent: Array(options.length).fill(false),
            mobileView: false,
            //colorBoxes: Array(DIMENSIONS.length).fill(this.props.colorBox)
		}
	}

	componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps);
		/*if (this.state.loading) {
			this.setState({isdefaultView:(nextProps.videoContent.length!==0) ? true:false,
				defaultView: (nextProps.defaultView==="Video content") ? false:true, loading: false});
		}*/
    }

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

    handleExampleContent(e,n) {
		e.preventDefault();
        this.setState((state) => {
			const sa = state.showboxContent;
			sa[n] = !sa[n];
			return { showboxContent: sa };
		});
	}

	render() {
		const { classes, title,videoContent,textContent,colorBox,inColumns, t,goNextSection,saveresloc,
            isLastSection,isContent,showcard,openSection,datasav,smoothtrans } = this.props;
        var grid_buttons;
        const emptycontent = <ContentContainer
                content={"Click icon"}
                showboxContent={true}
            />;
        if (!inColumns) {
            grid_buttons = this.state.contentExample.map((item, index) => {
                let breakpoint1 = 4;
                let breakpoint2 = 8;
                let content2 = <ContentContainer
                        content={item.content}
                        showboxContent={this.state.showboxContent[index]}
                    />;
                let content1 = <img class='responsive_nopointer' src={item.img}/>;
                let class1 = classes.imgContainer;
                let class2 = classes.boxContainer_vertical;
                if (index % 2 !== 0 && !this.props.isMobile) {
                    breakpoint1 = 8;
                    breakpoint2 = 4;
                    content1 = <ContentContainer
                            content={item.content}
                            showboxContent={this.state.showboxContent[index]}
                        />;
                    content2 = <img class='responsive_nopointer' src={item.img}/>;
                    class1 = classes.boxContainer_vertical;
                    class2 = classes.imgContainer;
                }
                return (
                    <GridContainer container style={{paddingTop:"20px"}}>
                        <GridItem xs={12} sm={12} lg={breakpoint1} spaceLeft={0} className={classes.gridItem}>
                            <div className={classes.blockContainer_vertical}>
                                <div className={`${(index % 2 !==0 && !this.props.isMobile) ? classes.paddingBox:""} ${classes.subcontainer0}`}
                                    onClick={(index % 2 ==0 || this.props.isMobile) ? (e) => this.handleExampleContent(e,index):0}>
                                    <div className={class1} style={(index % 2 !== 0 && !this.props.isMobile) ? {backgroundColor:"#"+colorBox, opacity: !this.state.showboxContent[index] && "0.5" }:{}}>
                                    {content1}</div>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} lg={breakpoint2} spaceLeft={0} className={classes.gridItem}>
                            <div className={classes.blockContainer_vertical}>
                                <div className={`${(index % 2 ==0 || this.props.isMobile) ? classes.paddingBox:""} ${classes.subcontainer0}`}
                                    onClick={(index % 2 !==0 && !this.props.isMobile) ? (e) => this.handleExampleContent(e,index):0}>
                                <div className={class2} style={(index % 2 == 0 || this.props.isMobile) ? {backgroundColor:"#"+colorBox, opacity: !this.state.showboxContent[index] && "0.5" }:{}}>
                                    {content2}</div>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                );
            });
        }
        else {
            grid_buttons = this.state.contentExample.map((item, index) => {
                return (
                    <GridItem xs={12} sm={12} md={4} spaceLeft={0} style={{marginTop:"30px"}}>
                        <div className={classes.flexcolumn}>
                                <div className={classes.blockContainer2}>
                                    <div className={classes.subcontainer0}
                                        onClick={(e) => this.handleExampleContent(e,index)}>
                                        <div className={classes.imgContainer}>
                                        <img className={`responsive_nopointer`} 
                                        src={item.img}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.blockContainer}>
                                    <div className={classes.subcontainer0}
                                        onClick={(e) => this.handleExampleContent(e,index)}>
                                    <div className={classes.boxContainer} style={{backgroundColor:"#"+colorBox, opacity: !this.state.showboxContent[index] && "0.5" }}>
                                        <ContentContainerFixed
                                            content={item.content}
                                            showboxContent={this.state.showboxContent[index]}
                                        />
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </GridItem>
                );
            });
        }

		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					<Card className={classes.cardExample}>
						<CardBody style={(!showcard) ? {padding: "0 30px",margin: "1rem 0 0.2rem 0"}:{}}>

                        <GridContainer justify="center" nonunset={true}>
								<GridItem xs={10} sm={10} md={10} 
								lg={10} spaceLeft={0}>
                                {!showcard && <GuideCardTitle>
                                    {(isContent) ? "":t('Example')+":"} {title}
                                     </GuideCardTitle>}
                                </GridItem>
                                <GridItem xs={2} sm={2} md={2} 
								lg={2} spaceLeft={0} style={{cursor:"pointer"}} onClick={openSection}>
									<div className={`${classes.iconDownArrowDiv}`} style={{paddingTop:"7px",textAlign:"right"}}>
                                        <img
											src={checkmark}
											style={{width:"30px",marginRight:"20px", marginTop: showcard ? "-27px":"-5px",visibility: (typeof  datasav !== "undefined") ? "visible":"hidden"}}
											alt="Completed section"
											title="Completed section"		
										/>
										<img
											src={arrowdown}
											style={showcard ? {transform:"rotate(-180deg)", marginTop:"-22px"}:{}}
											alt="Go to next section"
											title="Go to next section"									
											className={classes.iconDownArrow}
										/>
									</div>
								</GridItem>
							</GridContainer>
                            
                            {showcard && 
                            <GridContainer justify="center" nonunset={true} >
								<GridItem sm={(this.state.isdefaultView) ? 6:12} md={(this.state.isdefaultView) ? 8:12} 
								lg={(this.state.isdefaultView) ? 8:12} spaceLeft={0}>
									<GuideCardTitle>{(isContent) ? "":t('Example')+":"} {title}</GuideCardTitle>
								</GridItem>
								<GridItem sm={(this.state.isdefaultView) ? 6:0} md={(this.state.isdefaultView) ? 4:0} 
								lg={(this.state.isdefaultView) ? 4:0} spaceLeft={0}>
									{this.state.isdefaultView && <Container className={classes.buttonContainer}>
										<ActionButton 
											onClick={(e) => this.handleSubmit(e)} 
											type="submit" 
											size="auto"
											centered={true}
											nomargin={true}
										>
											{(this.state.defaultView) && t('See video instead')}
											{(!this.state.defaultView) && t('See text instead')}
										</ActionButton>
									</Container>}
								</GridItem>
							</GridContainer>}
                            <Collapse in={showcard} 
                            addEndListener={() => {
                                if (showcard) {
									saveresloc();
									setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
								}
							}} >
                            <div className={classes.cardBodyText}>
							{(this.state.defaultView) && 
								<div>{textContent}</div>}

                            {(this.state.defaultView) && inColumns && 
                                <GridContainer container style={{justifyContent: "center"}}>
                                    {grid_buttons}
                                </GridContainer>}

                            {(this.state.defaultView) && !inColumns && grid_buttons}

                            {(!this.state.defaultView) && <div className={classes.cardBodyText}>
								{videoContent}</div>}		
                            </div>
							
                            {!isLastSection && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
								<img
									src={arrowdown}
									alt="Go to next section"
									title="Go to next section"
									
									className={classes.iconDownArrow}
								/>
						    </div>}
                            </Collapse>
						</CardBody>                        
					</Card>
				</Container>
			</div>
		);
	}
}

GuideExampleInteractive_b.defaultProps = {
	children: (<p>Here comes the content for this Example</p>),
	title: 'Example',
};


export default withTranslation()(withMyHook(withStyles(styles)(GuideExampleInteractive_b)));
/**
 * Description: this file contains the main base component for
 *  the rendering the page view of analytics "Ideas"
 *
 * Components: AnalyticsReadMore3
 */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import pattern from "assets/img/pattern01.png";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Table from "components/Table/TableIdeas.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { n_comp_cust , isAuthenticated } from "assets/jss/incept-sustainability-variables.jsx";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import axios from "axios";

//Translations
import { withTranslation } from 'react-i18next';

import i18next from "i18next";

const style = {
	typo: {
		paddingLeft: "25%",
		marginBottom: "40px",
		position: "relative",
	},
	titlepackage: {
		fontWeight: "300",
		lineHeight: "1.5em",
	},
	backgroundPatternImage: {
		width: "23%",
		zIndex: "0",
		right: "0",
		top: "0",
		position: "absolute",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	containerother: {
		paddingRight: "25px",
		paddingLeft: "25px",
		marginRight: "auto",
		marginLeft: "auto",
		marginTop: "0px !important",
	},
	gridItem: {
		width: "100%",
	},
	header: {
		display: "flex",
		flexDirection: "row",
		"@media only screen and (max-width: 960px)": {
			flexDirection: "column",
		},
	},
	headerText: {
		display: "flex",
		flexDirection: "column",
		marginTop: "220px",
		fontWeight: "bold",
		"@media only screen and (max-width: 960px)": {
			marginLeft: "20px",
			marginTop: "0px"
		},
	},
	cardBox: {
		borderRadius: "2rem",
		marginTop: "10px",
	},
	paragraph: {
		marginTop: "20px",
		fontSize: "14px",
	},
	paddingTopAnalytics2: {
        paddingTop: '100px'
    },
	pagination: {
		display: "flex"
	},
};

const invertDirect = {
	asc: "desc",
	desc: "asc",
};

const columnnumber = {
	Description: 1,
};

const columnname = {
	1: "description",
};

class AnalyticsTable extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			answers: [],
			nanswers: 0,
			title_exercise: "",
			title_field: "",
			columnToSort: 1,
			sortDirection: "desc",
			upperPageBound: 3,
            lowerPageBound: 0,
			currentPage: 1,
			isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
			pageNumbers:[],
			answersPerPage:20,
			active:[],
			showpagination: false,
			loadingfirst: true
		};

		//For pagination
		this.handleClick = this.handleClick.bind(this);
		this.btnDecrementClick = this.btnDecrementClick.bind(this);
		this.btnIncrementClick = this.btnIncrementClick.bind(this);
		this.btnNextClick = this.btnNextClick.bind(this);
		this.btnPrevClick = this.btnPrevClick.bind(this);
		// this.componentDidMount = this.componentDidMount.bind(this);
		this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions)
	}
	
	updateWindowDimensions() {
		if (window.innerWidth<600)
	   	this.setState({ upperPageBound: 1,pageBound:1 });
	}

	handleClick(event) {
		let listid = Number(event.target.id);
		var auxArr = new Array(this.state.answers.length).fill(false);
		auxArr[listid] = true;
		this.setState({
		  	currentPage: listid,
			  active: auxArr
		});
		//ul li.active").removeClass('active');
		//$('ul li#'+listid).addClass('active');
		this.setPrevAndNextBtnClass(listid);
	}

	setPrevAndNextBtnClass(listid) {
		let totalPage = Math.ceil(this.state.answers.length / this.state.answersPerPage);
		this.setState({isNextBtnActive: 'disabled'});
		this.setState({isPrevBtnActive: 'disabled'});
		if(totalPage === listid && totalPage > 1){
			this.setState({isPrevBtnActive: ''});
		}
		else if(listid === 1 && totalPage > 1){
			this.setState({isNextBtnActive: ''});
		}
		else if(totalPage > 1){
			this.setState({isNextBtnActive: ''});
			this.setState({isPrevBtnActive: ''});
		}
	}

	btnIncrementClick() {
		this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
		this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
		let listid = this.state.upperPageBound + 1;
		var auxArr = new Array(this.state.answers.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage: listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	btnDecrementClick() {
		this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
		this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
		let listid = this.state.upperPageBound - this.state.pageBound;
		var auxArr = new Array(this.state.answers.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage: listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	btnPrevClick() {
		if((this.state.currentPage -1)%this.state.pageBound === 0 ){
			this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
			this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
		}
		let listid = this.state.currentPage - 1;
		var auxArr = new Array(this.state.answers.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage : listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	btnNextClick() {
		if((this.state.currentPage +1) > this.state.upperPageBound ){
			this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
			this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
		}
		let listid = this.state.currentPage + 1;
		var auxArr = new Array(this.state.answers.length).fill(false);
		auxArr[listid] = true;
		this.setState({ currentPage : listid,active: auxArr});
		this.setPrevAndNextBtnClass(listid);
	}

	//Get answers from backend
	async getanswers(exerciseid) {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		const insdata = {
			departmentid: this.props.location.query
		};
		axios
			.get(n_comp_cust+exerciseid , { headers: { Authorization: authstr },params: insdata })
			.then((response) => {
				console.log(response.data);
				this.setState({answers:response.data["answers"].values,
								nanswers:response.data["answers"].nvalues,
								title_exercise:response.data["title"],
								title_field:response.data["title_field"],
								loadingfirst:false});
				if (response.data["answers"].nvalues>this.state.answersPerPage)
					this.setState({showpagination: true});
			})
			.catch((err) => {
				this.setState({loadingfirst:false});
				console.log(err);
			});
	}
	componentDidMount() {
		window.addEventListener("resize", this.updateWindowDimensions());
		const { match } = this.props;
		const { exerciseid } = match.params;
		this.getanswers(exerciseid);
		i18next.on('languageChanged', () => this.getanswers());
	}

	//Function to order columns
	handleSort = (columnname) => {
		console.log("ORder ",columnnumber[columnname]);
		this.setState((state) => ({
			columnToSort: columnnumber[columnname],
			sortDirection:
				state.columnToSort === columnnumber[columnname]
					? invertDirect[state.sortDirection]
					: "asc",
		}));
	};

	//Order array of objects
	_sortArray(array, property, direction) {
		direction = direction || 1;
		array.sort(function compare(a, b) {
			let comparison = 0;
			if (a[property] > b[property]) {
				comparison = 1 * direction;
			} else if (a[property] < b[property]) {
				comparison = -1 * direction;
			}
			return comparison;
		});
		return array; // Chainable
	}

	render() {
		const { t,classes } = this.props;
		var table_content = [];
		var answers = this.state.answers;
		console.log("ESSS");
		console.log(this.props.location.query);

		if (this.state.answers.length>0) {
			//Creates an array of answers to create the table
			if (this.state.sortDirection==="desc") {
				answers = this._sortArray(answers, columnname[this.state.columnToSort], -1);
			}
			else {
				answers = this._sortArray(answers, columnname[this.state.columnToSort]);
			}

			const indexOfLastTodo = this.state.currentPage * this.state.answersPerPage;
            const indexOfFirstTodo = indexOfLastTodo - this.state.answersPerPage;
			const currentAnswers = answers.slice(indexOfFirstTodo, indexOfLastTodo);

			currentAnswers.map((item, index) => {
				table_content.push([
					"",
					item.description
				]);
				return item;
			})

			// Logic for displaying page numbers			
			let pageNumbers2 = [];
			for (let i = 1; i <= Math.ceil(answers.length / this.state.answersPerPage); i++) {
				pageNumbers2.push(i);
			}

			const renderPageNumbers = pageNumbers2.map(number => {
                if(number === 1 && this.state.currentPage === 1){
					this.state.pageNumbers[number] = true;
                    return(
                        <li key={number} className={'active'}  id={number}>
							<a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
                    )
                }
                else if((number < this.state.upperPageBound + 1) && number > this.state.lowerPageBound){
                    return(
                        <li key={number} className={this.state.active[number] ? 'active' : ''} id={number}>
							<a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
                    )
                }
				return number;
            });
			let pageIncrementBtn = null;
            if(pageNumbers2.length > this.state.upperPageBound){
                pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip; </a></li>
            }
			let pageDecrementBtn = null;
            if(this.state.lowerPageBound >= 1){
                pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
            }
			let renderPrevBtn = null;
            if(this.state.isPrevBtnActive === 'disabled') {
                renderPrevBtn = <li className={this.state.isPrevBtnActive}><span id="btnPrev"> Prev </span></li>
            }
			else{
                renderPrevBtn = <li className={this.state.isPrevBtnActive}><a href='#' id="btnPrev" onClick={this.btnPrevClick}> Prev </a></li>
            }
			let renderNextBtn = null;
            if(this.state.isNextBtnActive === 'disabled') {
                renderNextBtn = <li className={this.state.isNextBtnActive}><span id="btnNext"> Next </span></li>
            }
            else{
                renderNextBtn = <li className={this.state.isNextBtnActive}><a href='#' id="btnNext" onClick={this.btnNextClick}> Next </a></li>
            }

			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
				<div className={classes.containerother} style={{ marginTop: "100px" }}>
					{
						<img
							className={classes.backgroundPatternImage}
							src={pattern}
							alt="background patterns"
						/>
					}
					<GridContainer style={{ marginTop: "50px" }}>
						<GridItem xs={12} sm={12} style={{ position: "relative", marginBottom: "60px", marginTop: "50px" }}>
							<div style={{ position: "absolute", zIndex: 10 }}>
								<div className={classes.title} style={{ fontWeight: "bold",fontSize:"24px" }}>
								{t('Exercise')}</div>
								<div style={{ fontSize: "16px",margin:"15px 0" }}>
								{this.state.title_exercise}
								</div>
							</div>
						</GridItem>
						<div style={{ overflow: "auto", width:"100%", marginTop:"50px" }}>
							<Card className={classes.cardBox}>
								<CardBody>
									<div className={classes.bodyHeader}>
										<p className={classes.paragraph}>
											<b style={{fontSize:"24px"}}>{this.state.nanswers}</b>
											{t(' Employees answered')+": '"+this.state.title_field+"'"}
										</p>
									</div>
									<Table
										tableHeaderColor="success"
										tableHead={[
											"",
											t("Description"),
										]}
										tableData={table_content}
										handleSort={this.handleSort}
										columnToSort={this.state.columnToSort}
										sortDirection={this.state.sortDirection}
									/>
									{this.state.showpagination && <div className={classes.pagination}>
										<ul id="page-numbers" className="pagination">
										{renderPrevBtn}
										{pageDecrementBtn}
										{renderPageNumbers}
										{pageIncrementBtn}
										{renderNextBtn}
										</ul>
									</div>}
								</CardBody>
							</Card>
						</div>
					</GridContainer>
				</div>
				</LoadingOverlay>
			);
		}
		else {
			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
					<div className={classes.containerother} >
						<div className={classes.paddingTopAnalytics2}>
							Nothing to show yet.
        				</div>
					</div>
				</LoadingOverlay>
			);
		}
	};
}

export default withTranslation()(withStyles(style)(AnalyticsTable));
import {
    successColor,
    whiteColor,
    grayColor,
    hexToRgb,
} from "assets/jss/material-dashboard-react.jsx";
import { ourGreen} from 'assets/jss/incept-sustainability-variables.jsx';
const analyticsPage3Style = (theme) => ({
    '@global': {
        '.ct-readmore-2': {
            '& .ct-bar': {
                stroke: "#489552",
                strokeWidth: '40px'
            },
            '& .ct-grid': {
                stroke: 'rgba(0, 0, 0, 0.2)'
            },
            '& .ct-label': {
                color: 'rgba(0, 0, 0, 0.7)'
            },
            '& .ct-labelpoint': {
                color: "white",
                fontSize: "14px"
            }
        },
        '.ct-readmore-mult': {
            '& .ct-series-a .ct-bar': {
                stroke: "#489552"
            },
            '& .ct-grid': {
                stroke: 'rgba(0, 0, 0, 0.2)'
            },
            '& .ct-label': {
                color: 'rgba(0, 0, 0, 0.7)'
            },
            '& .ct-labelpoint': {
                color: "white",
                fontSize: "14px"
            }
        }
    },
    typo: {
        paddingLeft: "25%",
        marginBottom: "40px",
        position: "relative"
    },
    titlepackage: {
        fontFamily: "Raleway,Helvetica,Arial,sans-serif",
        fontWeight: "300",
        lineHeight: "1.5em"
    },
    lineunder: {
        display: "block",
        border: "1px solid #116628",
        marginLeft: "-60px",
        width: "50%",
        marginBottom: "40px",
        color: "rgba(0, 0, 0, 0.87)"
    },
    buttoncolor: {
        backgroundColor: "#0095a9",
        borderColor: "#0095a9"
    },
    containerother: {
        paddingRight: "25px",
        paddingLeft: "25px",
        marginRight: "auto",
        marginLeft: "auto"
    },
    successText: {
        color: successColor[0]
    },
    upArrowCardCategory: {
        width: "16px",
        height: "16px"
    },
    stats: {
        color: grayColor[0],
        display: "inline-flex",
        fontSize: "12px",
        lineHeight: "22px",
        "& svg": {
            top: "4px",
            width: "16px",
            height: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            top: "4px",
            fontSize: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px"
        }
    },
    cardCategoryWhite: {
        color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: grayColor[2],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Raleway', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        marginBottom: "10px",
        "& small": {
            color: grayColor[1],
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardTitleWhite: {
        color: whiteColor,
        marginTop: "0px",
        minHeight: "auto",
        fontSize: "1.7em",
        fontWeight: "bold",
        fontFamily: "'Raleway', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: grayColor[1],
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardHeaderCompany: {
        backgroundColor: "rgb(255, 171, 64)",
        padding: "0.75rem 1.25rem 1.25rem 1.25rem"
    },
    subtitlecard: {
        borderBottom: "1px solid #e5e5e5",
        marginBottom: "30px"
    },
    paddingTopAnalytics: {
        paddingTop: '20px'
    },
    paddingTopAnalytics2: {
        paddingTop: '100px'
    },
    visibleexer: {
        display: "none"
    },
    backgroundPatternImage: {
        width: "25%",
        position: "absolute",
        right: "0",
        top: "0",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    card: {
        borderRadius: "2rem",
    },
    circularProgressBar: {
        minWidth: "250px",
        maxWidth: "400px",
        padding: "1rem 3rem",
        [theme.breakpoints.down("xs")]: {
            padding: "0",
            marginBottom: "2rem"
        }
    },
    circularProgressBarText: {
        fontSize: "1rem",
        fontWeight: "bold",
        marginTop: "55%",
        textAlign: "center",
    },
    circularProgressBarProcent: {
        fontSize: "2rem",
        fontWeight: "bold",
        position: "absolute",
        bottom: "50%"
    },
    progressBar: {
        borderRadius: "2rem",
        backgroundColor: "#fff4cd",
       /* marginBottom: "1rem",*/
        height: "1.3rem",
        width: "40%",
        float: "left",
        marginRight: "1rem",
        "& .progress-bar": {
            backgroundColor: "#ffe682",
            borderRadius: "2rem",
        },
    },
    progressBar2: {
        height: "3rem",
        marginBottom: "1rem",
        borderRadius: "2rem",
        backgroundColor: "#FFF",
        "& .progress-bar": {
            borderRadius: "2rem",
            fontSize: "1.5rem",
            fontWeight: "600",
        },
    },
    textRight: {
        fontSize: "0.9rem",
        fontWeight: "600",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    textLeft: {
        fontSize: "0.9rem",
        fontWeight: "600",
        textAlign: "right",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    progressInfo: {
        position: "relative",

    },
    illustration: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        }
    },
    illustrationContainer: {
        position: "absolute",
        right: "5%", top: "0",
        backgroundColor: ourGreen,
        borderBottomRightRadius: "2rem",
        borderBottomLeftRadius: "2rem",
        padding: "1.5rem",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        }
    },
    employeesResultInfo: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    },
    employeesResultInfoSmall: {
        [theme.breakpoints.up('md')]: {
            display: "none",
        },
        /*"@media only screen and (max-width: 960px)": {
			width: "100%"
		}*/
    },
    commentsContainer: {
        display: "flex", 
        marginBottom: "1rem",
        marginTop: "30px",  
      },
      commentsIcon: {
        width: "40px",
        height: "40px",
        marginRight: "1rem"
      },
    smallButton: {
        backgroundColor: "rgb(255, 204, 92)",
        color: "white",
        border: "0px",
        borderRadius: "20px",
        fontSize: "13px",
        fontWeight: "bold",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        marginRight: "1rem",
        float: "left"
    },
    smallButtonRed: {
        backgroundColor: "rgb(105, 239, 114)",
        color: "white",
        border: "0px",
        borderRadius: "20px",
        fontSize: "13px",
        fontWeight: "bold",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        //marginRight: "1rem",
        float: "right"
    },
    title: {
		fontSize: "60px",
		"@media only screen and (max-width: 960px)": {
			fontSize: "40px"
		}
	},
    dropdownfilter: {
        width: "auto",
        minWidth: "200px",
        '& .MuiOutlinedInput-notchedOutline':{
			borderRadius:'24px'
		},
    }
});

export default analyticsPage3Style;
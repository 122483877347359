import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.jsx";


const tableStyle = (theme) => ({
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    borderStyle: "hidden!important",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadPointer: {
    cursor: "pointer",
    minWidth: "140px",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    "&, &$tableCell": {
      fontSize: "1em",
    },
  },
  tableCell2: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    "&:first-child": {
      fontSize: "22px",
      color: "red",
    },
  },
  tableCellIdeas: {
    ...defaultFont,
    lineHeight: "2",
    padding: "20px 20px",
    verticalAlign: "middle",
    fontSize: "14px",
    "&:first-child": {
      borderTopLeftRadius: "2.5rem",
      borderBottomLeftRadius: "2.5rem",
    },
    "&:last-child": {
      borderTopRightRadius: "2.5rem",
      borderBottomRightRadius: "2.5rem",
    },
    overflow: "auto",
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    "&:first-child": {
      fontSize: "22px",
      color: "green",
    },
  },
  tableResponsive: {
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "50px",
    padding: "10px",
    margin: "10px"
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
    padding: "10px 10px 10px 10px",
    border: "1.4px solid white",
    "&:nth-child(odd)": {
      backgroundColor: "#F2F2F2",
    },
  },
  tagLabelClass: {
    fontSize: "14px",
    /*fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',*/
    fontWeight: "300",
    lineHeight: "2",
    maxWidth: "100px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
  },
  tagLabelEmpty: {
    fontSize: "13px",
    /*fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',*/
    fontWeight: "300",
    lineHeight: "2",
    maxWidth: "100px",
    fontStyle: "italic",
    color: "#828282",
    cursor: "pointer",
  },
});

export default tableStyle;

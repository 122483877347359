import React from "react";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import { ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';

//form inputs
import Select from '@material-ui/core/Select';

const styles = ({color,marginB,paddingShort}) => ({
	root: {
		color: ({color}) => color === 'magenta' ? ourOrange : color,
		"@media only screen and (max-width: 600px)": {
			width: "160px",
		},
		"@media only screen and (max-width: 1140px)": {
			marginBottom: "30px",
		},
		'& .MuiOutlinedInput-notchedOutline':{
			borderColor: ({color}) => color === 'magenta' ? ourOrange : color,
			borderRadius:'24px'
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline':{
			borderColor: ({color}) => color === 'magenta' ? "#FFA8A5" : color
		},
		'& .MuiSelect-select:focus':{
			backgroundColor:'rgba(255, 255, 255, 0.3)',
			borderRadius:'24px'
		},
		'& .MuiSelect-icon':{
			color:({color}) => color === 'magenta' ? ourOrange : color
		},
		'& .MuiSelect-root':{
			// "@media only screen and (max-width: 960px)": {
			// 	maxWidth: "100px"
			//   }
		},
		'& .MuiOutlinedInput-input':{
			padding: ({paddingShort}) => paddingShort && "6px 32px 7px 10px",
		}
	},
});

class DropDown extends React.Component {
		
	render(){
		const {classes, children, color, paddingShort, ...rest} = this.props;
		return (
			<Select 
				className={classes.root}
				variant="outlined" 
				fullWidth={true} 
				{...rest}>
				{children}
			</Select>
		);
	}
}

export default withStyles(styles)(DropDown);
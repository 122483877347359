import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

const style = {
  grid: {
    padding: "0 15px !important",
    "@media only screen and (max-width: 600px)": {
      padding: "0 !important",
      width: "100%"
		},
    "@media only screen and (max-width: 1280px)": {
      marginTop: "20px"
		}
  },
  gridL: {
    padding: "0 15px 0 0 !important"
  },
  gridTitle: {
    padding: "0 15px !important",
  }
};

function GridItem({ ...props }) {
  const { classes, children, space, spaceLeft,spaceRight,otherclasses, ...rest } = props;
  var spaceclass = classes.grid;
  var otherclass = (otherclasses!==undefined) ? otherclasses: "";
  if (space===0 || spaceLeft===0) {
    if (space===0) {
      spaceclass = "";
    }
    else 
      spaceclass = classes.gridL;
  }
  else if (spaceRight===0) {
    spaceclass = classes.gridTitle;
  }
  return (
    <Grid item {...rest} className={spaceclass+" "+otherclass}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node
};

export default withStyles(style)(GridItem);

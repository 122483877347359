import React from "react";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";


import Paper from '@material-ui/core/Paper';

const styles = {
	root:{
		maxWidth:'500px',
		margin:'0 auto',
		marginTop:'50px'
	},
	paper:{
		padding:'20px'
	}
};

class IEIncompatible extends React.Component {
	render(){
		const {classes} = this.props;
		return (
			<div className={classes.root}>
				<Paper elevation={3} className={classes.paper}>
					<p><b>Internet Explorer</b> is not compatible with our system. Please, use other browsers such as Firefox, Chrome, or Safari.</p>
					<p>We apologize for the inconvenience.</p>
				</Paper>
			</div>
		);
		
	}
}

export default withStyles(styles)(IEIncompatible);
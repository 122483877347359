// Incept sustainability own variables
import clientConfig from 'client-config.js';
import jwt from "jwt-decode";
import { useState, useEffect } from 'react';

const siteUrl = clientConfig.siteUrl;

const standardRadious = "2rem";
const ourDarkGreen    = "#125a40";
const ourGreen    = "#add095";
const ourBlue = "#009df0";
const ourYellow     = "#ffe682";
const ourOrange   = "#ff8855";
const ourDarkGreen60 = "rgba(18,90,64,0.6)";
const ourDarkGreen40 = "rgba(18,90,64,0.4)";
const ourGreen40 = "rgba(173,208,149,0.4)";
const ourGreen60 = "rgba(173,208,149,0.6)";
//const ourBlue60 = "rgba(0,157,240,0.6)";
const ourBlue60 = "#66C4F6";
const ourOrange80 = "rgba(255,136,85,0.8)";
const grayCape20 = "rgba(203,203,203,0.2)";
const grayCape40 = "rgba(203,203,203,0.4)";
const get_company_user_info         = siteUrl+"wp-json/incept-api/v1/user/select=";
const get_jsons         = siteUrl+"wp-json/incept-api/v1/modules/";
const reset_password = siteUrl+"wp-json/incept-api/v1/user/password";
const user_general_info = siteUrl+"wp-json/user/nfpr/22?gdt=";
const user_info         = siteUrl+"wp-json/incept-api/v1/user";
const user_info_up      = siteUrl+"wp-json/user/nfpr_up/22?gdt=";
const help_email = siteUrl+"wp-json/incept-api/v1/user/help";
const mgfile_st = siteUrl+"wp-json/incept-api/v1/user/uploadfl";
const reminder_email = siteUrl+"wp-json/incept-api/v1/user/reminder";
const save_data         = siteUrl+"wp-json/incept-api/v1/modules/";
const is_g_comp         = siteUrl+"wp-json/incept-api/v1/user/modulestatus";
const n_comp_pck        = siteUrl+"wp-json/incept-api/v1/company/select=";
const n_comp_cust        = siteUrl+"wp-json/incept-api/v1/company/exercise/";
//const n_comp_nly        = siteUrl+"wp-json/user/cmplalt/22?gdt=";
//const n_comp_nly_1      = siteUrl+"wp-json/user/cmplalt1/22?gdt=";
//const n_comp_nly_2      = siteUrl+"wp-json/user/cmplalt2/22?gdt=";
const n_comp_nly_3      = siteUrl+"wp-json/incept-api/v1/company/ideas/select=";
const save_fav_id       = siteUrl+"wp-json/incept-api/v1/company/ideas/select=";
const save_label        = siteUrl+"wp-json/incept-api/v1/company/ideas/select=";
const get_answers        = siteUrl+"wp-json/incept-api/v1/modules/";
const urllog        = siteUrl+"wp-json/incept-api/v1/log";
const urllabel        = siteUrl+"wp-json/incept-api/v1/label/";

var isActive = true;

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
}
  
export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
	useEffect(() => {
	  function handleResize() {
		setWindowDimensions(getWindowDimensions());
	  }
  
	  window.addEventListener('resize', handleResize);
	  return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	return windowDimensions;
}

function isAuthenticated() {
	if (!localStorage.getItem("token")) {
		return false;
	}
		
	const decodedtoken = jwt(localStorage.getItem("token"));
	try {
	  if (Date.now() >= decodedtoken.exp * 1000) {
		//Logout
		localStorage.removeItem('token');
      	//localStorage.removeItem('userName');
		return false;
	  }
	} catch (err) {
	  return false;
	}
	return true;
}

const exerciseStyle   = {
	color : "#fff",
	bgColor : ourBlue,
	title : {
		fontSize : '22px'
	}
};

export { 
	standardRadious,
	useWindowDimensions,
	ourDarkGreen,
	ourDarkGreen60,
	ourDarkGreen40,
	ourGreen,
	ourOrange80,
	ourGreen40,
	ourGreen60,
	ourBlue,
	ourBlue60,
	ourYellow,
	ourOrange,
	grayCape20,
	grayCape40,
	exerciseStyle,
	user_general_info,
	reset_password,
	user_info,
	user_info_up,
	help_email,
	mgfile_st,
    save_data,
	reminder_email,
	isActive,
	is_g_comp,
	n_comp_pck,
	n_comp_nly_3,
	save_fav_id,
	save_label,
	get_jsons,
	n_comp_cust,
	get_company_user_info,
	get_answers,
	urllog,
	urllabel,
	isAuthenticated
}
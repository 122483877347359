/*!
* Description: this file contains the main base component for
*  the rendering the page view of analytics "Engagement"
*
* Components: AnalyticsReadMore2
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// Stylesheet import
import style from "assets/jss/material-dashboard-react/views/page3Style.jsx";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import pattern from "assets/img/pattern01.png";

import illustrationCard from "assets/img/illustration4.png";
import { n_comp_pck,ourYellow, ourGreen, isAuthenticated } from 'assets/jss/incept-sustainability-variables.jsx';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Chartist from 'chartist';
import jwt from 'jwt-decode';
import Typography from "@material-ui/core/Typography";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
//Translate
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";
import ReactHtmlParser from 'react-html-parser';
//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { Link } from "react-router-dom";
import icon4 from "assets/img/icon4.png";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Box from '@material-ui/core/Box';
import { ResponsiveEmbed } from "react-bootstrap";
window["Chartist"] = Chartist;

const pointLabels = require("chartist-plugin-pointlabels");
const barLabels = require("chartist-plugin-axistitle");

pointLabels();
barLabels();

//For bar chart with 3 
const BarChartMult = withTranslation()(withStyles(style)(
	(props) => {
		const { classes, data, maintitle, title1, title2, hasactiveE,t,
			description1,description2, hasactiveED, module, hasdata, titleDD1, titleDD2, totalusers,empty } = props;
		const items = [];
		const items_mobile = [];
		var i =0;
		data.forEach(arrayItem => {
			var aux = 100 - (arrayItem.more + arrayItem.have);
			items.push(
				<GridContainer key={i}>
					<GridItem xs>
						<p className={classes.textLeft}>
							{arrayItem.more + "%"} {t('of Employees')+" "}
							{/*<ActiveEmployees_tooltip
							tooltipid={"tool7"}>
							</ActiveEmployees_tooltip>*/}
							chose <span style={{fontStyle:"italic"}}>{titleDD1}</span> "{arrayItem.label}"
						</p>
					</GridItem>
					<GridItem xs={5}>
						<ProgressBar className={classes.progressBar2}>
							<ProgressBar now={arrayItem.more} key={1} label={arrayItem.more + "%"} style={{ backgroundColor: ourYellow }} />
							<ProgressBar now={arrayItem.have} key={2} label={arrayItem.have + "%"} style={{ backgroundColor: ourGreen }} />
							<ProgressBar now={aux} key={3} style={{ backgroundColor: "#F2F2F2" }} />
						</ProgressBar>
					</GridItem>
					<GridItem xs>
						<p className={classes.textRight}>Against {arrayItem.have + "% "} 
						that chose <span style={{fontStyle:"italic"}}>{titleDD2}</span> "{arrayItem.label}"</p>
					</GridItem>
				</GridContainer>
			);
			items_mobile.push(
				<GridContainer key={"M"+i}>
					<GridItem xs={12}>
						{arrayItem.label}
						<ProgressBar className={classes.progressBar2}>
							<ProgressBar now={arrayItem.more} key={1} label={arrayItem.more + "%"} style={{ backgroundColor: "#ffe682" }} />
							<ProgressBar now={arrayItem.have} key={2} label={arrayItem.have + "%"} style={{ backgroundColor: ourGreen }} />
							<ProgressBar now={aux} key={3} style={{ backgroundColor: "#F2F2F2" }} />
						</ProgressBar>
					</GridItem>
				</GridContainer>
			);
			i++;
		});

		return (
			<CompanyCard maintitle={maintitle} 
			module={module} 
			hasdata={hasdata} 
			title1={title1}
			title2={title2}
			hasactiveE={hasactiveE}
			description1={description1}
			description2={description2}
			hasactiveED={hasactiveED} >
			<p>Total Users answers: {totalusers}</p>
			<p style={{marginBottom:"60px"}}>Users that submitted empty: {empty}</p>
				<GridContainer>
					<div className={classes.employeesResultInfo}>
						{items}
					</div>
					<div className={classes.employeesResultInfoSmall}>
						{items_mobile}
						<GridItem xs={12}>
							<div style={{ width: "24px", height: "24px", backgroundColor: "#ffe682", float: "left", marginRight: "10px" }}></div>
							<p>% 
									<ActiveEmployees_tooltip
									tooltipid={"tool2"}>
									</ActiveEmployees_tooltip>:
								 would like more of this sustainable option</p>
						</GridItem>
						<GridItem xs={12}>
							<div style={{ width: "24px", height: "24px", backgroundColor: ourGreen, float: "left", marginRight: "10px" }}></div>
							<p>% 
									<ActiveEmployees_tooltip
									tooltipid={"tool3"}>
									</ActiveEmployees_tooltip>:
									 think they already have this sustainable option</p>
						</GridItem>
					</div>
				</GridContainer>
			</CompanyCard>
		);
	}
));

const PieandBars = withStyles(style)(
	(props) => {
		const { classes, t,module, maintitle, data,exerciseid,
			hasdata, styles, description1, description2, hasactiveED, title1, hasactiveE, 
			title2,totalusers, customvalues, departmentid, empty } = props;
		const items_bars = [];
		const items_pies = [];
		var i = 0;
		data.forEach(arrayItem => {
			if (i < 3) {
				items_pies.push(
					<GridItem xs={12} sm={12} md={12} lg={4} key={i}>
						<div className={classes.circularProgressBar}>
							<CircularProgressbarWithChildren
								value={arrayItem.val}
								circleRatio={0.6}
								strokeWidth={15}
								styles={styles}
							>
								<div className={classes.circularProgressBarProcent}>{arrayItem.val}%</div>
								<div className={classes.circularProgressBarText}>{arrayItem.label}</div>
							</CircularProgressbarWithChildren>
						</div>
					</GridItem>
				);
			}
			else {
				items_bars.push(
					<div className={classes.progressInfo} key={i}>
						<ProgressBar now={arrayItem.val} max={100} className={classes.progressBar} />
						<p style={{ marginBottom: "0.8rem", fontWeight: "500" }}><b>{arrayItem.val}%</b> {arrayItem.label}</p>
					</div>
				);
			}
			i++;
		});

		return (
			<CompanyCard
				maintitle={maintitle}
				module={module}
				hasdata={hasdata}
				title1={title1}
				title2={title2}
				hasactiveE={hasactiveE}
				description1={description1}
				description2={description2}
				hasactiveED={hasactiveED} >
				<p>Total Users answers: {totalusers}</p>
				<p>Users that submitted empty: {empty}</p>
				<GridContainer spacing={0}>
					{items_pies}
				</GridContainer>
				<div style={{marginBottom:"30px"}}>
					<GridItem xs={12} sm={12} md={10} lg={7} xl={12}>
						{items_bars}
					</GridItem>
				</div>
				{customvalues && 
					<Link to={{pathname:"/admin/custom_table/"+exerciseid, query:departmentid}} style={{marginTop:"20px"}}>
						<ActionButton 
							size="auto"
							centered={true}
							nomargin={true}
						>
							{t('See custom answers')}
						</ActionButton>
					</Link>
				}

			</CompanyCard>
		);
	}
);

const SelfWriting = withStyles(style)(
	(props) => {
		const { classes, t,module, maintitle, data,exerciseid,nitems,
			hasdata, description1, description2, hasactiveED, title1, departmentid, hasactiveE, title2 } = props;
		const items = [];
		var i = 0;

		data.forEach(arrayItem => {
			items.push(
					<div key={i} className={classes.commentsContainer}>
						<img alt="" className={classes.commentsIcon} src={icon4} />
						<Typography
							component="p"
							variant="body1"
							style={{ textAlign: "justify" }}
						>
							{arrayItem.description}
						</Typography>
					</div>
				);
			i += 1;
		});

		if (items.length>2)
			items.pop();

		return (
			<CompanyCard
				maintitle={maintitle}
				module={module}
				hasdata={hasdata}
				title1={title1}
				title2={title2}
				hasactiveE={hasactiveE}
				description1={description1}
				description2={description2}
				hasactiveED={hasactiveED} >
				<div style={{marginBottom:"20px"}}>
					<div>
						{items}
					</div>
				</div>
				{(nitems>2) && <Link to={{pathname:"/admin/custom_table/"+exerciseid, query:departmentid}} style={{marginTop:"20px"}}>
					<ActionButton 
						size="auto"
						centered={true}
						nomargin={true}
					>
						{t('See all answers')}
					</ActionButton>
				</Link>}

			</CompanyCard>
		);
	}
);

//For standard card
const CompanyCard = withStyles(style)(
	(props) => {
		const { classes, module, maintitle, title1,
			hasactiveE, title2, description1, description2, hasactiveED, hasdata, children } = props;
		//console.log("for graph: " + description + ", hasdata: " + hasdata);
		if (hasdata) {
			return (
				<GridItem xs={12} sm={12}>
					<Card className={classes.card}>
						<CardBody style={{ padding: "2rem 2rem", position: "relative" }}>
							<h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "0" }}></h3>
							<h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: "0" }}>{title1}</h3>
							<div className={classes.illustrationContainer}>
								<img alt="" src={illustrationCard} width="120px" className={classes.illustration} />
							</div>
							<p style={{ fontSize: "1rem", fontWeight: "500", width: "70%" }}>
								{module+" "+maintitle}
								{/*(hasactiveE) ?
									<ActiveEmployees_tooltip
										tooltipid={"tool1"}>
									</ActiveEmployees_tooltip> :
									""}
								{(hasactiveE) ? title2 : ""*/}
							</p>
							<p style={{ fontSize: "0.8rem", fontWeight: "500", marginTop: "30px", marginBottom: "2rem" }} >
								{ReactHtmlParser(description1)}
								{(hasactiveED) ?
									<ActiveEmployees_tooltip
										tooltipid={"tool1"}>
									</ActiveEmployees_tooltip> :
									""}
								{(hasactiveED) ? ReactHtmlParser(description2) : ""}
							</p>
							{children}
						</CardBody>
					</Card>
				</GridItem>
			);
		}
		else {
			return (
				<div>
					<div style={{ marginBottom: "20px" }}>
						{/*description*/}
					</div>
					{/*<i>No data available yet</i>*/}
				</div>
			);
		}

	}
);


/*
* Component: AnalyticsReadMore2
* Description: renders the cards for the analytics see more 2
*/
class AnalyticsReadMore2 extends React.Component {

	constructor(props) {
		super(props);
		var redlog = false;
		var decodedtoken = "";
		if (!isAuthenticated()) {
			redlog = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem("token"));
		this.state = {
			userid: (decodedtoken!=="") ? decodedtoken.data.user.id:"",
			company: [],
			module1_facts: [],
			module1_wcult: [],
			module2_impr: [],
			datagraph_sdg1: [],
			datagraph_sdg2: [],
			datagraph_actions: [],
			first_1graph: 0,
			first_2graph: 0,
			sec_1graph: 0,
			thr_1graph: 0,
			thr_2graph: 0,
			for_1graph: 0,
			notnull: false,
			loadingfirst:true,
			reloadlogin: redlog,
			departments: [],
			departmentid: 0
		};
	}


	//Get data for graphs from backend
	async getncomplpack(depid) {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem('token');
		
		const authstr = 'Bearer ' + token;
		const useLang = i18n.language;

		const insdata = {
			departmentid: depid
		};
		axios.get(n_comp_pck + "answers?wpml_language="+ useLang, { headers: { Authorization: authstr },params: insdata })
			.then(response => {
				//(Guide 1) For key opportunities
				console.log("paso respuesta");
				console.log(response);
				this.setState({
					company: response.data.company,
					departments: response.data.departments,
					notnull: (response.data.company!=null) ? true:false,
					/*module1_facts: response.data.answers1,
					first_1graph: (response.data.answers1.length > 0) ? 1:0,
					module1_wcult: response.data.workculture,
					first_2graph: (response.data.workculture.length > 0) ? 1:0,
					module2_impr: response.data.improv,
					sec_1graph: (response.data.improv.length > 0) ? 1:0,
					datagraph_sdg1: response.data.wksd,
					thr_1graph: (response.data.wksd.length > 0) ? 1:0,
					datagraph_sdg2: response.data.extrasdg,
					thr_2graph: (response.data.extrasdg.length > 0) ? 1:0,
					extraex: (response.data.extrasdg.length > 0) ? 1:0,
					datagraph_actions: response.data.actions,
					for_1graph: (response.data.actions.length) ? 1:0,*/
					load: 1,
					departmentid: depid,
					loadingfirst: false,
					
				});
			})
			.catch(err => {
				console.log(err);
			})
	}
	componentDidMount() {
		this.getncomplpack(0);
		i18next.on('languageChanged', () => this.getncomplpack());
	}

	handleChange(e) {
		this.setState({
			loadingfirst: true,
			departmentid: e.target.value
		});
		this.getncomplpack(e.target.value);
	}

	//render component
	render() {
		const { t,classes } = this.props;
		const styles = buildStyles({
			rotation: 0.7,
			strokeLinecap: "butt",
			trailColor: "#fff4cd",
			pathColor: "#ffe682",
		});
		
			
			/*if (!this.state.first_1graph && !this.state.first_2graph && 
				!this.state.sec_1graph && !this.state.thr_1graph && 
				!this.state.thr_2graph && !this.state.extraex && 
				!this.state.for_1graph)
				nodata = <div style={{margin:"80px 0 0 20px"}}>No data yet</div>; */
			
			//departments
			const departments_list = this.state.departments.map((data_content, index) => (
				<option value={data_content.id}>{data_content.name}</option>
			));
	
			var list_modules = "";
			if (this.state.company!==null) {
				list_modules = this.state.company.map((data_content, index) => (
					<div style={{width:"100%"}} key={index}>
						{(() => {
						if (data_content.answers.length > 0 || data_content.empty>0) {
							if (data_content.type_chart==="pie") {
								return <PieandBars
								data={data_content.answers}
								hasdata={(data_content.answers.length > 0 || data_content.empty!==0) ? 1:0}
								module={t("Module")+":"}
								maintitle={data_content.module_name}
								title1={data_content.title}
								title2=""
								hasactiveE={false}
								empty={data_content.empty}
								totalusers={(data_content.answers.length>0) ? data_content.answers[0].totalUsers:data_content.empty}
								description1={data_content.description}
								hasactiveED={false}
								customvalues={data_content.arecustoms}
								exerciseid={data_content.exerciseid}
								departmentid={this.state.departmentid}
								styles={styles}
								t={t}
							></PieandBars>;
							}
							else if (data_content.type_chart==="bar") {
								return <BarChartMult
										hasdata={(data_content.answers.length > 0 || data_content.empty!==0)  ? 1:0}
										data={data_content.answers}
										module={t("Module")+":"}
										t={t}
										maintitle={data_content.module_name}
										titleDD1={data_content.titleDropListOne}
										titleDD2={data_content.titleDropListTwo}
										title1={data_content.title}
										totalusers={(data_content.answers.length>0) ? data_content.answers[0].totalUsers:data_content.empty}
										empty={data_content.empty}
										title2=""
										hasactiveE={false}
										description1={data_content.description}
										description2=""
										hasactiveED={false}
									></BarChartMult>;
							}
							else {
								return <SelfWriting
										hasdata={(data_content.nanswers > 0) ? 1:0}
										data={data_content.answers}
										module={t("Module")+":"}
										maintitle={data_content.module_name}
										title1={data_content.title}
										title2=""
										hasactiveE={false}
										description1={""}
										description2=""
										hasactiveED={false}
										departmentid={this.state.departmentid}
										exerciseid={data_content.exerciseid}
										nitems={data_content.nanswers}
										t={t}
									></SelfWriting>;
							}
						}
					})()}				
					</div>			
				));
			}

			return (
				<LoadingOverlay
					active={this.state.loadingfirst}
					spinner={<BounceLoader />}
				>
				<div className={classes.containerother + " " + classes.paddingTopAnalytics}>
					{/* background image pattern */}
					<img
						className={classes.backgroundPatternImage}
						src={pattern}
						alt="background patterns"
					/>
					<GridContainer style={{ marginTop: "50px" }}>
						<GridItem xs={12} sm={12} style={{ position: "relative", marginBottom: "60px", marginTop:"50px" }}>
							<div style={{ position: "absolute", zIndex: 10 }}>
								<p className={classes.title} style={{ fontWeight: "bold" }}>
									{/*<img
										style={{ maxWidth: "30%" }}
										src={illustrationCompany}
										alt="illustration company"
									/>*/}
								Company
								</p>
							</div>
						</GridItem>
						{/* Cards */}
						{this.state.departments.length>0 && <GridItem xs={12} sm={12}>
							<Card className={classes.card}>
								<CardBody style={{ padding: "2rem 2rem", position: "relative" }}>
									<p style={{ fontSize: "1rem", fontWeight: "500", width: "70%" }}>
										{t("Select here if you wish to filter answers")+":"}
									</p>
									<Box sx={{ minWidth: 60 }} style={{width:200,paddingTop:"20px"}}>
										<FormControl fullWidth>
											<InputLabel variant="standard" htmlFor="uncontrolled-native">
											Department
											</InputLabel>
											<NativeSelect
											defaultValue={this.state.departmentid}
											inputProps={{
												name: "department",
												id: "uncontrolled-native"
											}}
											onChange={(e) => this.handleChange(e)}
											>
											<option value={0}>All departments</option>
											{departments_list}
											</NativeSelect>
										</FormControl>
									</Box>
								</CardBody>
							</Card>
						</GridItem>}
						{(!this.state.loadingfirst && this.state.notnull) && list_modules}
						{(this.state.loadingfirst || !this.state.notnull) &&
							<div className={classes.containerother} >
								<div className={classes.paddingTopAnalytics2} style={{marginBottom:"100px"}}>
									Nothing to show yet.
								</div>
							</div>
						}
					</GridContainer>
				</div>
				</LoadingOverlay>
			);
		
	}
}

AnalyticsReadMore2.propTypes = {
	classes: PropTypes.object
};

export default withTranslation()(withStyles(style)(AnalyticsReadMore2));

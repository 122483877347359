/*!
 * Description: this file contains the component for
 *  the type of exercise MultiChoice, e.g. the Learnings in the last step
 * Components: MultiChoice
 */
import React from "react";


// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import {
	ourOrange,
	standardRadious
} from "assets/jss/incept-sustainability-variables.jsx";

// Style components
import Container from "@material-ui/core/Container";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Snackbar from "@material-ui/core/Snackbar";

// Forms
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from '@material-ui/icons/CheckBox';

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import checked from 'assets/img/checkedWhiteBg.png';
//Translations
import { withTranslation } from 'react-i18next';


//color: props.comesf && props.comesf === 'learnings' ? "#3c4858" : "#fff"

const styles = {
	root: (props) => {
		if (props.comesf && props.comesf === 'learnings') {
			return {
				'& .MuiSvgIcon-root': {
					fontSize: '50px',
				},
				'& .MuiFormControlLabel-root': {
					alignItems: 'start'
				},
				'& .MuiCheckbox-root': {
					padding: '0'
				}
			}
		}
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "22px",
		paddingRight:"0"
	},
	card: {
		backgroundColor: "transparent",
		boxShadow: "none",
		color: "#FFF",
		marginTop: "0"
	},
	checkboxst: props => ({
		color: props.comesf && props.comesf === 'learnings' ? "#3c4858" : "#fff",
		marginRight: props.comesf && props.comesf === 'learnings' ? '10px' : '',
		"@media only screen and (max-width: 960px)": {
			padding: "5px"
		}
	}),
	icon: {
		height: "60px",
		margin: "10px",
		"@media only screen and (max-width: 960px)": {
			height: "40px",
			margin: "5px",
		}
	},
	chkcolor: props => ({
		color: props.comesf && props.comesf === 'learnings' ? "#3c4858" : "#fff",
		fontSize: ".875rem",
		fontWeight: props.comesf && props.comesf === 'learnings' ? "400" : "200",
		'.Mui-checked ~ &': {
			color: "blue"
		},
	}),
	checkboxCh: props => ({
		color: props.comesf && props.comesf === 'learnings' ? "#3c4858" : "#fff"
	}),
	formGroup: {
		flexDirection: "row",
	},
	formControlLabel: {
		marginLeft: "0",
		marginRight: "0",
		paddingRight: "15px",
		width: "50%",
		"@media only screen and (max-width: 960px)": {
			width: "100%",
			marginBottom: "20px"
		}
	},
	alert: {
		backgroundColor: ourOrange,
	},
	textarea: {
		'& label.Mui-focused': {
			color: 'white',
		},
		'& label': {
			color: 'white',
		},
		'& .MuiInputBase-input': {
			color: 'white',
		},
		"& .MuiOutlinedInput-root": {
			'& fieldset': {
				borderColor: 'white',
				borderRadius: standardRadious
			},
			'&.Mui-focused fieldset': {
				borderColor: '#ececec',
			},
			'&:hover fieldset': {
				borderColor: '#ececec',
			},
		},
	},
	iconForLearnings: {
		width: '37.5px',
		height: '37.5px',
		border: '1px solid #3c4858',
		margin: '5px'
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginTop: "20px"
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px"
	}
};

/*
 * this component is for the learnings in the last step
 */
class MultiChoice extends React.Component {
	constructor(props) {
		super(props);
		const { exercise } = props;
		const { options } = exercise;
		this.state = {
			selectedAnswers: [...new Array(options.length)].map(() => ({ 'statement_id': -1, 'value': false })), // each index represents the number of the answer from original options array, and it should be filled with true or false
			explanation: "",
			openAlert: false,
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		};
		for (let i = 0; i < options.length; i++) {
			this.state.selectedAnswers[i].statement_id = options[i].statement_id;
		}
		if (this.props.comesf === "learnings") {
			this.props.save2("learnings", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
		}
		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswers = this.props.datasav2["content"];
			if (this.props.comesf !== "learnings")
				this.state.explanation =
					this.props.datasav2["content"].length > this.props.exercise.options.length ?
						this.props.datasav2["content"][
							this.props.exercise.options.length
						].value : "";
		}
		
	}

	/*componentDidUpdate(prevProps, prevState) {
		if (this.props.comesf === "learnings") {
			if (prevProps.showModal !== this.props.showModal) {
				let allFalse = this.state.selectedAnswers.every(function (e) {
					return !e.value;
				});
				if (allFalse) {
					this.props.save2("learnings", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
				}
			}
		}
	}*/

	componentDidMount() {
		//retrieve exercise here
		/*if(typeof this.props.datasav2  !== "undefined") {
				this.state.selectedAnswers = this.props.datasav2["content"];
			}*/
	}

	handleSubmit(e) {
		if (this.state.explanation!=="") {
			this.setState((state) => {
				const sa = state.selectedAnswers;
				sa[this.props.exercise.options.length] = {"statement_id":-1,"value":state.explanation};
				this.props.save2("MultiChoice", sa, this.props.inkey, this.props.exercise_id);
				return { selectedAnswers: sa, submittedanswer: true };
			});
		}
		else {
			//Commented to allow empty answers
			/*let allFalse = this.state.selectedAnswers.every(function (e) {
				return !e.value;
			});*/
			//if (!allFalse) {
				this.setState({ submittedanswer: true });
				if (this.props.comesf === "learnings")
					this.props.save2("learnings", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
				else this.props.save2("MultiChoice", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
			//}
		}
	}

	handleChange(e) {
		const answerIndex = parseInt(e.target.value);
		const setToBool = !this.state.selectedAnswers[answerIndex].value;
		this.setState((state) => {
			const sa = this.state.selectedAnswers;
			const max = this.props.exercise.maxAllowed;

			// Don't allow more than max num of selections
			//typeof i !== "string" had to be added because we are concatenating textarea info into selectedAnswers
			if (sa.filter((i) => i.value && typeof i.value !== "string").length >= max && setToBool && this.props.comesf !== "learnings") {
				return { openAlert: true };
			}

			sa[answerIndex].value = setToBool;
			if (this.props.comesf === "learnings") {
				this.props.save2("learnings", sa, this.props.inkey, this.props.exercise_id);
			}
				
			//else this.props.save2("MultiChoice", sa, this.props.inkey, this.props.exercise_id);
			return { selectedAnswers: sa, submittedanswer: false };
		});
	}

	handleChangeTextArea(e) {
		const answer = e.target.value;
		this.setState({ submittedanswer: false });
		this.setState({
			explanation: answer,
		});
		/*this.setState((state) => {
			const sa = state.selectedAnswers;
			sa[this.props.exercise.options.length] = { "statement_id": -1, "value": answer };
			//this.props.save2("MultiChoice", sa, this.props.inkey, this.props.exercise_id);
			return { selectedAnswers: sa };
		});*/
	}

	handleClose(e, reason) {
		if (reason === "clickaway") {
			return;
		}
		this.setState({ openAlert: false });
	}

	_renderOptions(options, subtype) {
		const { classes, comesf, t } = this.props;
		const st = options.map((option, answerIndex) => {
			const { imageURL, statement } = option;

			return (
				<FormControlLabel
					key={answerIndex}
					control={
						<React.Fragment>
							<Checkbox
								className={classes.checkboxst}
								checkedIcon={<CheckBoxIcon className={classes.checkboxCh} />}
								checked={this.state.selectedAnswers[answerIndex].value}
								value={answerIndex}
								key={answerIndex}
								icon={comesf && comesf === 'learnings' && <div className={classes.iconForLearnings}></div>}
								onChange={(e) => this.handleChange(e)}
							/>
							{imageURL && <img alt="" src={imageURL} className={classes.icon} />}
						</React.Fragment>
					}
					label={<Typography className={classes.chkcolor}>{statement}</Typography>}
					value={answerIndex}
					className={classes.formControlLabel}
				/>
			);
		});

		return (
			<FormGroup className={classes.formGroup}>
				{st}
				<Snackbar
					open={this.state.openAlert}
					autoHideDuration={3000}
					onClose={(e, reason) => this.handleClose(e, reason)}
					message={t("LimitAnsw",'You must choose up to ' + this.props.exercise.maxAllowed)}
				></Snackbar>
			</FormGroup>
		);
	}

	_renderTextArea(statement) {
		const { classes, t } = this.props;

		return (
			<React.Fragment>
				<div style={{ marginTop: "50px" }}>{statement}</div>
				<TextField
					className={classes.textarea}
					variant="outlined"
					label={t('Write your answer here')}
					margin="normal"
					placeholder={t('Write your answer here')}
					fullWidth
					onChange={(e) => this.handleChangeTextArea(e)}
					value={this.state.explanation}
				/>
				<p className={classes.footertext}>
				{t('no personal data',
					{
						defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'})}
				</p>
			</React.Fragment>
		);
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { options, textarea, subtype } = exercise;

		return (
			<div className={classes.root}>
				<Card className={classes.card} style={{marginBottom:0}}>
					<CardBody isexercise={true} style={{paddingBottom:0}}>
						<div>{this._renderOptions(options, subtype)}</div>
						{textarea && textarea.statement && <div>{this._renderTextArea(textarea.statement)}</div>}
						<div className={classes.statusContainer}>
						{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted.")}</p>}
						</div>
						{(this.props.comesf !== "learnings") && <Container className={classes.buttonContainer}>
							<ActionButton
								size="auto"
								type="submit"
								nomargin={true}
								centered={true}
								onClick={(e) => this.handleSubmit(e)}
							>
								{t('Submit')}
							</ActionButton>
						</Container>}
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(MultiChoice));

/*!
 * Description: this file contains the main base component for
 *  the rendering of the section "exercise"
 * Components: GuideSectionManager, Guide
 */
import React from "react";
import Card from "components/Card/Card.jsx";

import CardBody from "components/Card/CardBody.jsx";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
//Styles
import withStyles from "@material-ui/core/styles/withStyles";
import { exerciseStyle } from "assets/jss/incept-sustainability-variables.jsx";
 
//All exercises types you can import
import QA from "components/Exercise/QA.js";
import QAWithQuote from "components/Exercise/QAWithQuote.js";
import DragAndDrop from "components/Exercise/DragAndDrop.js";
import DragAndDropRanking from "components/Exercise/DragAndDropRanking.js";
import DragAndDropTwoList from "components/Exercise/DragAndDropTwoList.js";
import DragAndDropTwoListGrouped from "components/Exercise/DragAndDropTwoListGrouped.js";
import DragAndDropQAPair from "components/Exercise/DragAndDropQAPair.js";
import SimpleChoice from "components/Exercise/SimpleChoice.js";
import SimpleChoiceImage from "components/Exercise/SimpleChoiceImage.js";
import SimpleChoiceGroupedText from "components/Exercise/SimpleChoiceGroupedText.js";
import SimpleChoiceSelectionInfo from "components/Exercise/SimpleChoiceSelectionInfo.js";
import MultiChoice from "components/Exercise/MultiChoice.js";
import LikertScale2 from "components/Exercise/LikertScale.js";
import MultiChoiceGroupedText from "components/Exercise/MultiChoiceGroupedText.js";
import MultiChoiceTextBox from "components/Exercise/MultiChoiceTextBox.js";
import Timeline from "components/Exercise/Timeline.js";
import ImageHover from "components/Exercise/ImageHover.js";
import SelfWriting from "components/Exercise/SelfWriting.js";
import DropDownWithImage from "components/Exercise/DropDownWithImage.js";
import DropDownWithText from "components/Exercise/DropDownWithText.js";
import DropDownGroups from "components/Exercise/DropDownGroups.js";
import LifeCycle from "components/Exercise/LifeCycle.js";
import LifeCycleExtension from "components/Exercise/LifeCycleExtension.js";
import ExtraSDG from "components/Exercise/ExtraSDG.js";
import WithoutOne from "components/Exercise/WithoutOne.js";
import arrowdown from 'assets/img/down-arrow.png';
import checkmark from 'assets/img/checkmark.png';
// import { Button } from "@storybook/react/demo";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import { ourDarkGreen } from "assets/jss/incept-sustainability-variables.jsx";
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";


import { LifeCycleContext } from "./LifeCycleProvider";

//Translations
import { withTranslation } from 'react-i18next';
 
const styles = {
  root: {},
  cardExercise: {
    backgroundColor: ourDarkGreen,
    color: "white",
    borderRadius: "2rem",
    marginBottom: "10px"
  },
  cardHeader: {
    fontSize: exerciseStyle.title.fontSize,
  },
  cardHeaderTitle: {
    fontSize: exerciseStyle.title.fontSize,
    marginTop: "10px",
    fontWeight: "bold",
  },
  cardBody: {
    /*paddingTop: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",*/
  },
  iconNavBack: {
    fontSize: "30px",
  },
  buttonAnswer: {
    color: "#fff",
    borderRadius: "3rem",
    textTransform: "none",
    backgroundColor: "#ff6f69",
    width: "100px",
    height: "25px",
    padding: "0px 8px !important",
    "&:hover, &:active, &:focus,": {
      filter: "brightness(100%)",
      backgroundColor: "#ff6f69",
    },
  },
  container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	iconDownArrow: {
		cursor: "pointer",
		width: "50px"
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
    cursor: "pointer",
	}
};
 
/*
 * Description: this component renders an exercise, it looks the
 *    type of exercise and imports the corresponding component
 */
class GuideExercise extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			scrollsection: true,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//To avoid infinite loop, we ignore passing from "loading:true" to "loading:false" only
		if (prevState.stepn!==this.state.stepn) {
			this.setState({scrollsection: false});
		}

		/*useEffect(() => {
			this.itemsRef.current = this.itemsRef.current.slice(0, 20);
		 });*/

		//Now it won't save exercises when changing step, submit button will do that
		/*const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged)
			this._updateresults(false, false, 0);*/
		//this.refs.child.savecomponentresult();
	};

	/*componentDidMount() {
		this.setState({scrollsection: false});
	}*/

  /*
   * Looks the type of exercise and imports the corresponding component
   *
   * The props passed in each component (exercise, save2, inkey, datasav2)
   * are used for saving the data of the exercises
   */
  _renderExerciseType(exercise, saveresloc, indexkey, datasav, alldata, id) {
    const { type, subtype } = exercise;
    
    switch (type) {
      case "qa":
        switch (subtype) {
          case "with-quote":
            return (
              <QAWithQuote
                key={id}
                ref="child"
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          default:
            return (
              <QA
                key={id}
                exercise={exercise}
                save2={saveresloc}
                exercise_id={id}
                inkey={indexkey}
                datasav2={datasav !== "undefined" ? datasav:""}
              />
            );
        }
      case "dragdrop":
        switch (subtype) {
		  case "dragdrop_ranking":
            return (
			  <DragAndDropRanking
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          case "two-list":
            return (
              <DragAndDropTwoList
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          case "two-list-grouped":
            return (
              <DragAndDropTwoListGrouped
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          case "qa-pair":
            return (
              <DragAndDropQAPair
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          default:
            return (
              <DragAndDrop
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
        }
      case "simple-choice":
        switch (subtype) {
          case "grouped-text":
            return (
              <SimpleChoiceGroupedText
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          case "selection-info":
            return <SimpleChoiceSelectionInfo exercise={exercise} />;
          default:
            return (
              <SimpleChoice
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
        }
      case "simple-choice-image":
        return (
          <SimpleChoiceImage
            key={id}
            exercise={exercise}
            exercise_id={id}
            save2={saveresloc}
            inkey={indexkey}
            datasav2={datasav}
          />
        );
      case "multi-choice":
        switch (subtype) {
          case "grouped-text":
            return (
              <MultiChoiceGroupedText
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          case "text-box":
            return (
              <MultiChoiceTextBox
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          default:
            return (
              <MultiChoice
                key={id}
                savers={true}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
              
            );
        }
      case "likert_scale":
          return (
          <LikertScale2
            key={id}
            savers={true}
            exercise={exercise}
            exercise_id={id}
            save2={saveresloc}
            inkey={indexkey}
            datasav2={datasav}
          />);
      case "timeline":
        return <Timeline exercise={exercise} />;
      case "image-hover":
        return <ImageHover exercise={exercise} />;
      case "self-writing":
        return (
          <SelfWriting
            key={id}
            exercise={exercise}
            exercise_id={id}
            save2={saveresloc}
            inkey={indexkey}
            datasav2={datasav}
          />
        );
      case "dropdown":
        switch (subtype) {
          case "with-image":
            return (
              <DropDownWithImage
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          case "groups":
            return (
            <DropDownGroups 
            key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />);
          case "with-text":
            return (
              <DropDownWithText
                key={id}
                exercise={exercise}
                exercise_id={id}
                save2={saveresloc}
                inkey={indexkey}
                datasav2={datasav}
              />
            );
          default:
            return "";
        }

      case "life-cycle":
        return (
          <LifeCycleContext.Consumer>
            {({onActivitiesChange}) => {
              return <LifeCycle
                      key={id}
                      exercise={exercise}
                      exercise_id={id}
                      save2={saveresloc}
                      inkey={indexkey}
                      datasav2={datasav}
                      onActivitiesChange={(arr1,arr2) => onActivitiesChange(exercise.id,arr1,arr2)}
                    />
            } 
            }
          </LifeCycleContext.Consumer>
        );
      case "life-cycle-extension":
        return (
        <LifeCycleContext.Consumer>
            { ({getPropertyByLifeCycleID}) => {
                return (
                  <LifeCycleExtension
                  key={id}
                  exercise_id={id}
                  activities={getPropertyByLifeCycleID(exercise.dependsOn,'activities')}
                  impacts={getPropertyByLifeCycleID(exercise.dependsOn,'impacts')}
                />
                )
              }
            }
        </LifeCycleContext.Consumer>);
      case "extra-sdg":
        return (
          <ExtraSDG
            key={id}
            exercise={exercise}
            exercise_id={id}
            save2={saveresloc}
            inkey={indexkey}
            datasav2={datasav}
          />
        );
      /*return <ExtraSDG exercise={exercise}/>;*/
      case "without-one":
        const { dimensions } = exercise;
        return <WithoutOne dimensions={dimensions} alldata={alldata} />;
      default:
        return "";
    }
  }
 
  render() {
    const {
      classes,
      children,
      title,
      exercise,
      id,
      background_color,
      saveresloc,
      indexk,
      datasav,
      alldata,
      type,
      subtype,
      goNextSection,
	  isLastSection,
    showcard,
	isFeedback,
    openSection,
	smoothtrans,
      t
    } = this.props;
    let color = (background_color!==undefined && background_color!=="") ? {backgroundColor:"#"+background_color}:{};
    return (
      <div className={classes.root}>
        <Container className={classes.container}>
          <Card className={classes.cardExercise} style={color}>
            {/* <CardHeader className={classes.cardHeader}>
              
            </CardHeader> */}
            <CardBody className={classes.cardBody} id={"exercisecanvas: " + title} 
            style={(!showcard) ? {padding: "0 30px",margin: "1rem 0 0.2rem 0"}:{}}>
              <span activity_type={type+" "+subtype}></span>

              
							<GridContainer justify="center" nonunset={true}>
								<GridItem xs={10} sm={10} md={10} 
								lg={10} spaceLeft={0}>
                  {!showcard && <GuideCardTitle color={"white"} >
						    	<span style={{color:"white"}}>{(isFeedback ? t('Feedback Activity'):t('Activity'))}: {title}</span></GuideCardTitle>}
                </GridItem>
                <GridItem xs={2} sm={2} md={2} 
								lg={2} spaceLeft={0} style={{cursor:"pointer"}} onClick={openSection}>
									<div className={`${classes.iconDownArrowDiv}`} style={{paddingTop:"7px",textAlign:"right"}}>
										<img
											src={checkmark}
											style={{width:"30px",marginRight:"20px", marginTop: showcard ? "-27px":"-5px",visibility: (typeof  datasav !== "undefined") ? "visible":"hidden"}}
											alt="Completed section"
											title="Completed section"		
										/>
										<img
											src={arrowdown}
											style={showcard ? {transform:"rotate(-180deg)", marginTop:"-22px"}:{}}
											alt="Go to next section"
											title="Go to next section"									
											className={classes.iconDownArrow}
										/>
									</div>
								</GridItem>
							</GridContainer>
              {showcard &&
              <GuideCardTitle color={"white"} >
							<span style={{color:"white"}}>{t('Activity')}: {title}</span></GuideCardTitle>}
              <Collapse in={showcard}
			  addEndListener={() => {
				if (showcard) {
					console.log("Scrolling exercise "+this.props.stepn+" "+this.props.indexk);
					setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
				}
			}} >
              <div style={{marginBottom:"20px"}}></div>
              {children}
              {this._renderExerciseType(
                exercise,
                saveresloc,
                indexk,
                datasav,
                alldata,
                id
              )}
			  	{!isLastSection && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
						<img
							src={arrowdown}
							alt="Go to next section"
							title="Go to next section"
							
							className={classes.iconDownArrow}
						/>
				</div>}
        </Collapse>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}
 
/*function onExerciseSubmit(idsd) {
  document.getElementById("thankudiv" + idsd).style.display = "inline";
  document.getElementById("exercisecanvas" + idsd).style.display = "none";
  document.getElementById("submitexercisebtn" + idsd).style.display = "none";
 
  setTimeout(function () {
    document.getElementById("thankudiv" + idsd).style.display = "none";
    document.getElementById("exercisecanvas" + idsd).style.display = "inline";
    document.getElementById("submitexercisebtn" + idsd).style.display =
      "inline";
  }, 2000);
}*/
 
GuideExercise.defaultProps = {
  children: <p>Here comes the content for this activity</p>,
  title: "Activity",
};
 
export default withTranslation()(withStyles(styles)(GuideExercise));
 


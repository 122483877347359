/*!
* Description: this file contains the component for
*  the top part of the modules (title)
* Components: GuideTopContainer
*/
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { IconButton } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { ourBlue, ourGreen, help_email,isAuthenticated } from 'assets/jss/incept-sustainability-variables.jsx';
import axios from "axios";


import icon1 from 'assets/img/rounddashboard.png';
import icon10 from 'assets/img/idearound.png';
import icon7 from "assets/img/icon7.png";
import icon9 from 'assets/img/questionround.png';

import icon1_1 from 'assets/img/rounddashboard2.png';
import icon10_1 from 'assets/img/Icons-15.png';
import icon9_1 from 'assets/img/roundfilledquestion.png';

import modalPattern from "assets/img/modal_pattern-02-01.png";
import imageExplanation from 'assets/img/explainImage.png';
import tutorial1 from 'assets/img/tutorial1.png';
import tutorial2 from 'assets/img/tutorial2.png';
import tutorial3 from 'assets/img/tutorial3.png';
import tutorial4 from 'assets/img/tutorial4.png';
import tutorial5 from 'assets/img/tutorial5.png';
import tutorial6 from 'assets/img/tutorial6.png';
import tutorial7 from 'assets/img/tutorial7.png';
import tutorial8 from 'assets/img/tutorial8.png';

import topPattern from 'assets/img/contactpage_pattrn.png'

import ActionButton from "components/CustomButtons/ActionButton";
import Link from '@material-ui/core/Link';

//Used for first time login
import {default as CustomModal}  from "components/Modal/Modal.jsx";
import navigationIMG from "assets/img/nav.png";
import stepsIMG from "assets/img/stepsnav.PNG";
import loadinggif from "assets/img/loading.gif";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
//Translations
import { withTranslation } from 'react-i18next';

const tutorialImgs = [
	tutorial1,
	tutorial2,
	tutorial3,
	tutorial4,
	tutorial5,
	tutorial6
];

const styles = (theme) => ({
	definewidth: {
		maxWidth: "1760px",
		position: "relative"
	},
	root: {
		background: "white",
		position:"fixed",
		width:"100%",
		zIndex: "199",
		overflow: "hidden",
		maxWidth: "1760px",
		height: "51.9px",
		"@media only screen and (max-width: 960px)": {
			paddingBottom: "15px",
		}	
		/*height: "45px"*/
	},
	topPattern: {
		position: "absolute",
		width: "305px",
		zIndex: "200",
		right: "0",
		top: "0",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	iconNavBack: {
		height: '35px',
		marginTop: '10px',
		color: ourBlue,
		cursor: "pointer",
		marginLeft: '14px',
		[theme.breakpoints.down("sm")]: {
			marginRight: "20px"
		}
	},
	iconTutorial:{
		height: '40px',
	},
	iconTutorialBlock:{
		display:'inline-block',
		width:'40px',
		marginRight:'15px',
		textAlign:'center'
	},
	iconTextContainer:{
		fontWeight:"bold",
		marginBottom:"10px"
	},
	title: {
		display: 'inline-block',
		color: ourGreen,
		textTransform: "uppercase",
		"& h2": {
			fontWeight: "500",
		},
	},
	modalPattern: {
		transform: "rotate(0.5turn)",
		width: "40%",
		position: "absolute",
		left: "1px",
		bottom: "1px",
		zIndex: "1"
	},
	modalBox: {
		"& .modal-content": {
			borderRadius: "2rem",
		}
	},
	button2: {
		borderRadius: "20px",
		backgroundColor: "rgb(255, 111, 105)",
		color: "white",
		border: "1px",

		cursor: "pointer",
		fontSize: "13px",
		padding: "2px 25px",
		marginLeft: "1rem",
		marginBottom: "1rem",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "1rem",
		},
	},
	textarea1: {
		backgroundColor: "rgba(255,255,255,0.5)",
		position: "relative",
		zIndex: "2",
		marginBottom: "20px",
		borderRadius: "2rem",
		borderColor: "grey",
		resize: "none"
	},
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right"
	},
	/*topPattern: {
		position: "relative",
		width: "335px",
		float: "right",
		"@media only screen and (max-width: 960px)": {
		  display: "none",
		},
	},*/
	errorfield: {
		color:"red",
		textAlign: "center"
	},
	successfield: {
		color:"green",
		textAlign: "center"
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px",
		textAlign: "right"
	},
	rightlabel: {
		textAlign: "right",
		paddingRight: "10px",
		margin: "auto"
	}
});

class GuideTopContainer extends React.Component {
	constructor(props) {
		super(props);
		//Check if has ever logged in at least once, and then set it to true
		const hasEverDisplayedTutorialModal = JSON.parse(localStorage.getItem('hasEverDisplayedTutorialModal')??'false');
		localStorage.setItem('hasEverDisplayedTutorialModal',JSON.stringify(true));

		this.state = {
			showLeaveModal: false,
			showReminderModal: false,
			showTutorialModal: !hasEverDisplayedTutorialModal,
			modalTitle: "",
			modalId: "",
			allideas:"",
			helpcontent:"",
			afterMailMessage: "",
			afterMail: false,
			afterMailError: false,
			dashactive: false,
			ideaactive: false,
			questionactive: false,
			valueDD: 0,
		};

		this.timeoutIdeas = 0;
	}

	showHideModal = (idkey,title="") => {
		if (this.state.showReminderModal){
			clearTimeout(this.timeoutIdeas);
		}
		this.setState({ 
			showReminderModal: !this.state.showReminderModal,
			modalTitle: title,
			modalId: idkey,
			questionactive:false,
			ideaactive: false,
			afterMail: false
		});

		if (idkey==="mod2") {
			this.setState({questionactive:!this.state.questionactive, afterMail: false});
		}

		if (idkey ==="mod1")
			this.setState({ ideaactive: !this.state.ideaactive});
	};

	handleShowTutorialModal = (title) => {
		this.setState({ showTutorialModal: true, showReminderModal: false});
	};

	handleHideTutorialModal = () => {
		this.setState({questionactive:false});
		this.setState({ showTutorialModal: false});
	};

	handleHideLeaveModal = () => {
		this.setState({ showLeaveModal: false});
	};

	handleChange(e,id) {
		if (id==="mod2") {
			this.setState({ helpcontent: e.target.value });
		}
		else {
			const allideas = e.target.value;
			this.setState({ allideas: allideas });
		}
	}

	sendquestion() {
		if (!isAuthenticated()) {
			this.props.isExpired();
			return false;
		}
		document.getElementById("loadinggd2").style.display = "block";
		const formdata = {
			subject: "Question from Module",
			text: this.state.helpcontent,
		};

		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;

		this.setState({ loading: true }, () => {
			axios
				.post(help_email, formdata, { headers: { Authorization: authstr } })
				.then((res) => {
					//console.log(res);
					document.getElementById("loadinggd2").style.display = "none";
					this.setState({ afterMail: true,helpcontent: "" ,afterMailMessage: "Question sent."});
				})
				.catch((err) => {
					if (err.response!==undefined)
						console.log(err.response.data.message);
					else 
						console.log(err);
					this.setState({afterMail: true,afterMailError:true,afterMailMessage: "Error",});
					document.getElementById("loadinggd2").style.display = "none";
				});
		});
	}

	handleChange2(e,id) {
		if (id==="mod1") {
			if (this.state.allideas === "") {
				alert("Text field cannot be empty");
				return;
			}
			const ideaObj = {
				idea: this.state.allideas,
				label: this.state.valueDD
			};
			this.props.saveresloc("Ideas", ideaObj, this.props.indexk + 1);
			if (document.getElementById("thankudiv2")) {
				document.getElementById("thankudiv2").style.display = "block";
				document.getElementById("ideabox2").style.display = "none";

				this.timeoutIdeas = setTimeout(function() {
					if (document.getElementById("thankudiv2")) {
						document.getElementById("thankudiv2").style.display = "none";
						document.getElementById("ideabox2").style.display = "block";
					}
				}, 2000);
				this.setState({ allideas: "" });
			}
		}
		else {
			if (this.state.helpcontent === "") {
				alert("Text field cannot be empty");
				return;
			}
			this.sendquestion();
		}
	}

	onChangeDD(event){
		const answerIndexSelected = parseInt(event.target.value); 
		this.setState({valueDD:answerIndexSelected});
	}

	render() {
		const { classes, scroll,
			saveres,isdashactive, labels, t } = this.props;
		return (
			<div className={classes.definewidth}>
				<div className={classes.root}>
					{/*<img
						src={inceptLogo}
						alt="back to guide overview icon"
						className={classes.iconNavBack}
						style={{ height: "45px", marginTop: "0", cursor: "auto" }}
					/>*/}
					<img
						src={isdashactive ? icon1_1:icon1}
						alt="back to guide overview icon"
						onClick={saveres}
						title="Go to dashboard"
						className={classes.iconNavBack}
					/>
					<img
						title={t("Add new comment or idea")}
						id={"mod1"}
						src={this.state.ideaactive ? icon10_1:icon10}
						alt="new idea icon"
						onClick={(e) => this.showHideModal(e.target.id,e.target.title)}
						className={classes.iconNavBack}
					/>
					<img
						src={this.state.questionactive ? icon9_1:icon9}
						title={t("Help")}
						id={"mod2"}
						alt="report problem icon"
						onClick={(e) => this.showHideModal(e.target.id,e.target.title, {showActionForTutorialModal:true})}
						className={classes.iconNavBack}
					/>
					<div id="progressBarContainer">
						<div id="progressBar" style={{transform: `scale(${scroll}, 1)`, opacity: `${scroll}`}}/>
					</div>
				</div>
				<div>
					<img
						src={topPattern}
						alt="top corner web pattern"
						className={classes.topPattern}
					/>
				</div>
				<Modal
					id="2"
					show={this.state.showReminderModal}
					onHide={this.showHideModal}
					centered
					size="lg"
					className={classes.modalBox}
				>
					<Modal.Body>
						<IconButton onClick={() => this.showHideModal()} className={classes.modalCancelButton}>
							<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
							/>
						</IconButton>
						<div style={{ padding: "2rem" }}>
							{this.state.modalId==="mod2" && <h4>{this.state.modalTitle}</h4>}
							{
								this.state.modalId === "mod2" &&
								<Link component="button" onClick={() => this.handleShowTutorialModal()}>{t("Getting Started?")}</Link>
							}
							<p style={{ fontWeight: "bold", marginBottom:0, marginTop:"1rem" }}>
								{	this.state.modalId === "mod2" ? t("Want to report a problem?")
									: this.state.modalTitle
								}
							</p>
							<div id="ideabox2">
							<Form>
								<Form.Control as="textarea" 
								value={this.state.modalId === "mod2" ? this.state.helpcontent:this.state.allideas}
								onChange={(e) => this.handleChange(e,this.state.modalId)}
								rows="8" className={classes.textarea1} />
								{this.state.modalId !== "mod2" && 
								<div>
									{(labels !== undefined && labels.length>0) && <Grid container>
										<Grid item xs={12} md={8} className={classes.rightlabel}>
											<p style={{marginBottom:"0"}}>{t("Please select the category")+":"}</p>
										</Grid>
										<Grid item xs={12} md={4}>
											<DropDown
												value={this.state.valueDD}
												color="black"
												paddingShort={true}
												onChange={(event) => this.onChangeDD(event)}
											>
												{labels.map(
													(item) => <MenuItem key={item.statement_id} value={item.statement_id}>{item.label}</MenuItem>
												)}
												<MenuItem value={0}>
													<em>Other</em>
												</MenuItem>
											</DropDown>
										</Grid>
									</Grid>}
									<p className={classes.footertext}>
									{t('no personal data',
										{
											defaultValue: 'Please keep your submission anonymous, do not write any personal data in your submission.'})}
									</p>
								</div>
								}
								{this.state.afterMail && <p className={this.state.afterMailError ? classes.errorfield:classes.successfield}>
									{this.state.afterMailMessage}
								</p>}
								<div id="loadinggd2" style={{ display: "none" }}>
									<img
										className="responsive"
										src={loadinggif}
										width="25"
										alt=""
										style={{ marginBottom: "20px" }}
									/>
								</div>
								<ActionButton 
									onClick={(e) => this.handleChange2(e,this.state.modalId)}
									style={{ float: "right" }}
									className={classes.submitButton}>
									{t('Submit')}
								</ActionButton>
							</Form>
							</div>
							<div style={{margin:"30px 0 60px 0", textAlign:"center", display: "none", position: "relative",zIndex:"9999"}}
								id="thankudiv2">
								<h2 style={{color:ourGreen}}>{t('Thank you!')} </h2>
								<h3>{t('Your comment has been received')+"."}</h3>
							</div>
						</div>
						<img
							className={classes.modalPattern}
							src={modalPattern}
							alt="background patterns"
						/>
					</Modal.Body>
				</Modal>
				<CustomModal
					show={this.state.showTutorialModal}
					onHide={() => this.handleHideTutorialModal()}
					noPattern={true}
					customSize={true}
				>
					<h2>{t('Ready to get started?')+" See the images bellow explaining how to go through the module:"}</h2>
					<div>
						<Carousel showArrows={true}>
							<div>
								<img src={tutorial1} />
							</div>
							<div>
								<img src={tutorial2} />
							</div>
							<div>
								<img src={tutorial3} />
							</div>
							<div>
								<img src={tutorial4} />
							</div>
							<div>
								<img src={tutorial5} />
							</div>
							<div>
								<img src={tutorial6} />
							</div>
							<div>
								<img src={tutorial7} />
							</div>
							<div>
								<img src={tutorial8} />
							</div>
						</Carousel>
					</div>
					{/**find this overview in... */}
				</CustomModal>
			</div>
		);
	}
}

GuideTopContainer.defaultProps = {
	title: 'Top title for this guide',
	backNavPath: '/'
};


export default withTranslation()(withStyles(styles)(GuideTopContainer));
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
//import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
// core components
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ourDarkGreen, ourYellow } from 'assets/jss/incept-sustainability-variables.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

//import { RIEInput } from 'riek';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

const ideaBoxStyle = {
	'favorite': {
		color: 'white',
		textAlign: 'center'
	},
	'iconFavorite': {
		fontSize: '25px',
		'&.is-favorite': {
			color: ourYellow
		}
	}
};



const IdeaBox = withStyles(ideaBoxStyle)(
	(props) => {
		const { classes, isFavorite, onClick } = props;
		return (
			<div className={classes.root}>
				<div className={classes.favorite}>
					<IconButton onClick={onClick}>
						<Icon className={classNames(classes.iconFavorite, "fa fa-star", { "is-favorite": isFavorite })} />
					</IconButton>
				</div>
			</div>
		);
	}
);

function CustomTable({ ...props }) {
	const { classes, tableHead, tableData, handleSort, clickfunction, columnToSort,
		sortDirection, editlabelfunction, editlabelfunctionsave,labelsIdeas,t } = props;

		console.log(labelsIdeas);
		const labels_list = [];
	/*const labels_list = labelsIdeas.map((data_content, index) => (
		<option value={data_content.id}>{data_content}</option>
	));*/

	return (
		<div className={classes.tableResponsive}>
			<Table className={classes.table}>
				<TableHead>
					<TableRow className={classes.tableHeadRow}>
						{tableHead.map((prop, key) => {
							if (key === 0)
								return "";
							return (
								<TableCell
									className={classes.tableCellIdeas + " " + classes.tableHeadCell + " " + classes.tableHeadPointer}
									key={key} style={{ fontWeight: "bold", fontSize: "150%", color: ourDarkGreen }}
								>
									<div onClick={() => handleSort(prop)}>
										<span>{prop}</span>
										{columnToSort === key ? (
											sortDirection === "asc" ? (
												<KeyboardArrowUpIcon />
											) : (
												<KeyboardArrowDownIcon />
											)
										) : null}
									</div>
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData.map((prop1, key1) => {
						var idea_id = -1;
						return (

							<TableRow key={key1} className={classes.tableBodyRow}>
								{prop1.map((prop, key) => {
									
									if (key === 0) {
										idea_id = prop;
										return "";
									}
									else if (key === 4) {
										return (

											<TableCell className={classes.tableCellIdeas + " " + classes.tablestats} key={key}>
												<IdeaBox isFavorite={prop} onClick={(e) => clickfunction(e, prop, key1, idea_id)} />
											</TableCell>

										);
									}
									else if (key === 5) {
										return (
											<TableCell className={classes.tableCellIdeas + " " + classes.tablestats} key={key}
												style={{padding:"20px 0"}}>
												<GridItem xs={12} sm={12}>
																									{/*<RIEInput
												className={prop==="Add label" ? classes.tagLabelEmpty:classes.tagLabelClass}
												value={prop}
												change={(text) => editlabelfunction(text, idea_id,key1)}
												propName='text'
												validate={_.isString} 
												/>*/}
												{/*<CustomInput
													labelText={prop!="" ? "":"Add label"}
													classreq={classes.tagLabelClass}
													value={prop}
													formControlProps={{
														fullWidth: true
													}}
													propName='text'
													validate={_.isString} 
													onBlur={(e) => editlabelfunctionsave(e, idea_id)}
													onChange={(e) => editlabelfunction(e, idea_id)}
												/>*/}

														<DropDown
															value={prop}
															color="black"
															paddingShort={true}
															onChange={(event) => editlabelfunction(event,idea_id)}
														>
															{labelsIdeas.map(
																(item) => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
															)}
															<MenuItem value={0}>
																<em>Other</em>
															</MenuItem>
														</DropDown>
												</GridItem>
											</TableCell>
										)
									}
									else {
										return (
											<TableCell className={classes.tableCellIdeas + " " + classes.tablestats} key={key}>
												<span>{prop}</span>
											</TableCell>
										);
									}
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</div>
	);
}


CustomTable.propTypes = {
	classes: PropTypes.object.isRequired,
	tableHead: PropTypes.arrayOf(PropTypes.string),
	tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};

export default withTranslation()(withStyles(tableStyle)(CustomTable));
